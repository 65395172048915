<template>
  <div :class="['sand-jsonTable', { noborder }]" v-if="columnData.length > 0">
    <div class="sand-jsonTable__item" v-for="item in columnData" :key="item.prop + Math.random()">
      <div class="sand-jsonTable__item--key" :style="`width:${keyWidth};`">{{ item.label }}</div>
      <!-- <div class="sand-jsonTable__item--value" :style="`width:calc( 100% - ${keyWidth} - 3px );`">{{tableData[item.prop]}}</div> -->
      <div class="sand-jsonTable__item--value" :style="`width:calc( 100% - ${keyWidth} - 3px );`">
        <!-- <span
          v-html="item.format ? item.format(tableData[item.prop]) : tableData[item.prop] || '-'"
        ></span> -->
        <span>{{
          item.format ? item.format(tableData[item.prop]) : tableData[item.prop] || '-'
        }}</span>
        <copy-icon
          v-if="item.copy"
          :name="item.prop"
          :copyText="tableData[item.prop]"
          style="vertical-align: middle;"
        />
        <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
          <span class="toTI">
            <svg-icon @click="toTi(tableData[item.prop])" v-if="item.toTI" name="sousuoti"></svg-icon>
          </span>
        </el-tooltip>
      </div>
    </div>
  </div>
  <sand-no-data v-else></sand-no-data>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import sandNoData from './sandNoData'
import { useState } from '@/hooks/useVuex'

const { tiToken } = useState('user', ['tiToken'])

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      // keyWidth: '120px',
      // columnData: [
      //   {
      //     label: 'xxx',
      //     prop: 'key'
      //   },
      //   {
      //     label: 'xxx333/n23423432',
      //     prop: 'keyfdasfasdfasddf2'
      //   },
      //   {
      //     label: 'xx333x',
      //     prop: 'key3'
      //   }
      // ],
      // tableData: {
      //   key: 'value',
      //   keyfdasfasdfasddf2: 'valueval\nu evalueevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevalueval uevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevaluevalue',
      //   key3: `
      //   &nb`
      // }
    })
  },
  noborder: {
    type: Boolean
  }
})
function toTi(data) {
  window.open(`https://ti.360.net/#/detailpage/searchresult?query=${data}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
}
const keyWidth = computed(() => {
  if (props.data) {
    return props.data.keyWidth || '120px'
  }
  return '120px'
})
const tableData = computed(() => {
  if (props.data) {
    return props.data.tableData || {}
  }
  return {}
})
const columnData = computed(() => {
  if (props.data) {
    return props.data.columnData || []
  }
  return []
})
</script>
<style lang="scss" scoped>
.sand-jsonTable {
  &__item {
    font-size: 14px;
    width: 100%;
    min-height: 40px;
    display: flex;
    // @include ft_color(color-main-font);
    &:last-child .sand-jsonTable__item--key {
      // border: 1px solid;
      // @include bd_color(color-sand-border);
      border-right: none;
    }
    &:last-child .sand-jsonTable__item--value {
      // border: 1px solid;
      // @include bd_color(color-sand-border);
    }
    &:first-child .sand-jsonTable__item--key {
      border-top-left-radius: 4px;
    }
    &:first-child .sand-jsonTable__item--value {
      border-top-right-radius: 4px;
    }
    &--key {
      // border: 1px solid;
      // @include bd_color(color-sand-border);
      border-right: none;
      border-bottom: none;
      flex-shrink: 0;
      word-wrap: break-word;
      // @include bg_color(color-sand-title-bg);
      color: var(--color-text-3);
      padding: 10px 20px;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-weight: 500;
      line-height: 1.3;
      box-sizing: border-box;
    }
    &--value {
      // @include ft_color(color-main-font);
      // border: 1px solid;
      // @include bd_color(color-sand-border);
      color: var(--color-text-2);
      border-bottom: none;
      flex-shrink: 0;
      flex-grow: 1;
      vertical-align: middle;
      padding: 10px 20px;
      box-sizing: border-box;
      font-weight: 400;
      white-space: pre-wrap;
      word-wrap: break-word;
      line-height: 1.3;
      // display: flex;
    }
  }
}
.sand-jsonTable.noborder  {
  .sand-jsonTable__item  {
    min-height: 32px;
    .sand-jsonTable__item:last-child .sand-jsonTable__item--key, .sand-jsonTable__item:last-child .sand-jsonTable__item--value, .sand-jsonTable__item--key, .sand-jsonTable__item--value  {
      // @include bd_color(color-main-transparent);
    }
    .sand-jsonTable__item--key  {
      // @include bg_color(color-main-transparent);
    }
    .sand-jsonTable__item--key, .sand-jsonTable__item--value  {
      padding: 6px 20px 6px 0;
      font-family: auto;
      word-break: break-all;
      .toTI {
        display: inline-block;
        margin-left: 8px;
        svg {
          font-size: 16px;
          top: 2px;
          position: relative;
          cursor: pointer;
          color: var(--color-text-2);
          &:hover {
            color: #00ab7a;
          }
        }
      }
    }
  }
}
</style>
