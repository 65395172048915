<template>
  <div class="network-behavior-wrap">
      <SandTab ref="tab" :type="type" :data="tabData" class="network-table" @change="handleTabChange"></SandTab>
      <sand-network :tab="curTab" :type="type" :header="columnData[curTab]" :totalCount="networkCount[curTab]"></sand-network>
  </div>
</template>

<script>
// 分析概览 及 网络分析 的 网络分析模块
import SandTab from '@/components/report/sandTab'
import SandNetwork from '@/components/report/sandNetwork'
import { mapGetters } from 'vuex'
import { isZh, t } from 'app/i18n'

export default {
  name: 'StaticInfo',
  components: {
    SandTab,
    SandNetwork
  },
  props: {
    networkCount: {
      type: Object
    },
    taskId: {
      type: String
    },
    type: {
      type: String
    }
  },
  computed: {
    columnData () {
      const overviewColumnData = {
        // 该字段需要与 后端返回的相同才能正确取到值
        'traffic.alerts': [
          {
            prop: 'src_ip',
            label: t('report.network.traffic.alerts.src_ip'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dst_ip',
            label: t('report.network.traffic.alerts.dst_ip'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'category',
            label: t('report.network.traffic.alerts.category'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'severity',
            label: t('report.network.traffic.alerts.severity'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        hosts: [
          {
            prop: 'ip',
            label: t('report.network.hosts.ip'),
            align: 'left',
            col: 4
          },
          {
            prop: 'country',
            label: t('report.network.hosts.country'),
            align: 'left',
            col: 4
          },
          {
            prop: 'city',
            label: t('report.network.hosts.city'),
            align: 'left',
            col: 4
          },
          {
            prop: 'isp',
            label: 'ISP',
            align: 'left',
            col: 4
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        dns: [
          {
            prop: 'area_name',
            label: t('report.network.dns.area_name'),
            align: 'left',
            col: 4
          },
          // {
          //   prop: 'response',
          //   label: '解析记录',
          //   align: 'left',
          //   col: 3
          // },
          {
            prop: 'analyse',
            label: t('report.network.dns.analyse'),
            align: 'left',
            col: 3
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ]
      }
      const othersColumnData = {
        http: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'detect'
          }
        ],
        https: [
          {
            prop: 'uri',
            label: 'URI',
            align: 'left',
            col: 8
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'detect'
          }
        ],
        tcp: [
          {
            prop: 'src',
            label: t('report.network.tcp.src'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'sport',
            label: t('report.network.tcp.sport'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dst',
            label: t('report.network.tcp.dst'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dport',
            label: t('report.network.tcp.dport'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        udp: [
          {
            prop: 'src',
            label: t('report.network.tcp.src'),
            align: 'left'
          },
          {
            prop: 'sport',
            label: t('report.network.tcp.sport'),
            align: 'left'
          },
          {
            prop: 'dst',
            label: t('report.network.tcp.dst'),
            align: 'left'
          },
          {
            prop: 'dport',
            label: t('report.network.tcp.dport'),
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        icmp: [
          {
            prop: 'src',
            label: t('report.network.icmp.src'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dst',
            label: t('report.network.icmp.dst'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'type',
            label: t('report.network.icmp.type'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'data',
            label: t('report.network.icmp.data'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        ftp: [
          {
            prop: 'src',
            label: t('report.network.tcp.src'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'sport',
            label: t('report.network.tcp.sport'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dst',
            label: t('report.network.tcp.dst'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'dport',
            label: t('report.network.tcp.dport'),
            col: 4,
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        smtp: [
          {
            prop: 'dst',
            label: t('report.network.tcp.dst'),
            align: 'left'
          },
          {
            prop: 'operation',
            label: '',
            col: 1,
            align: 'left',
            type: 'ti'
          }
        ],
        irc: [
          {
            prop: 'command',
            label: t('report.network.irc.command'),
            align: 'left'
          },
          {
            prop: 'type',
            label: t('report.network.irc.type'),
            align: 'left'
          },
          {
            prop: 'params',
            label: t('report.network.irc.params'),
            align: 'left'
          }
        ]
      }
      return this.type === 'overview' ? overviewColumnData : { ...overviewColumnData, ...othersColumnData }
    },
    tabData () {
      const keys = Object.keys(this.columnData)
      const cn = {
        'traffic.alerts': '流量检测',
        hosts: '主机',
        dns: '域名'
      }
      const resultData = keys.map(item => {
        let label = `${item.toUpperCase()}(${this.networkCount[item]})`
        for (const key in cn) {
          if (key === item && isZh()) {
            label = `${cn[key]}(${this.networkCount[item]})`
          }
        }
        if (item === 'traffic.alerts' && !isZh()) {
          label = `Traffic detection(${this.networkCount[item]})`
        }
        return {
          label,
          name: item
        }
      })
      return resultData
    },
    ...mapGetters({
      curTab: 'report/curTab'
    })
  },
  data () {
    return {
      tab: 'traffic.alerts'
    }
  },
  created() {
    // if (this.networkCount['traffic.alerts'] === 0) {
    //   this.$store.commit('report/SET_NETWORK_DETAIL', { tab: 'hosts' })
    // }
  },
  watch: {
    '$route'() {
      // this.tab = this.curTab
      // this.$store.commit('report/SET_NETWORK_DETAIL', { tab: props.tab, page: currentPage.value, index })
    }
  },
  methods: {
    handleTabChange (tab) {
      // this.tab = tab
      this.$store.commit('report/SET_NETWORK_DETAIL', { tab: tab })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
