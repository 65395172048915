<template>
  <div class="progress-analyse-wrap">
    <sand-no-data v-if="isEmpty(process_tree) && isEmpty(process_list)"/>
    <div v-else>
      <div class="progress-analyse-pic">
        <div class="progress-analyse-pic__btnWrap">
          <span :class="['btn', {active: progressTab === 'relation'}]"
                @click="progressTab = 'relation'">{{$t('report.behavior.relation.title')}}</span>
          <span :class="['btn', {active: progressTab !== 'relation'}]"
                @click="progressTab = 'tree'">{{$t('report.behavior.tree')}}</span>
        </div>
        <div ref="progress"
             class="progress"
             v-show="progressTab === 'relation'"></div>
        <div class="progress-tree"
             v-show="progressTab !== 'relation'">
          <process-tree :processtree="process_tree"></process-tree>
        </div>
      </div>
      <template v-if="!showFile">
        <div class="progress-analyse-summary-main">
          <div class="detail-name">
            <copy-icon
              style="vertical-align: middle;"
              :right="8"
              name="name"
              :copyText="currentItem.process_name"
            />
            <span>{{currentItem.process_name}}</span>
          </div>
          <div class="progress-analyse-summary">
            <div class="progress-analyse-summary-left">
              <dashboard :combined="currentItem.score"></dashboard>
            </div>
            <div class="progress-analyse-summary-right">
              <div class="summary-content">
                <div v-for="(item, key) in summaryKeyMap.content"
                    :key="key"
                    :class="[`summary-content-${key}`]">
                  <div v-for="(item2, index) in item"
                      :key="index"
                      :class="[`summary-content-${key}-item`]">
                    <span class="content-label">{{item2}}</span>
                    <span class="content-value">{{index === 'first_seen' ? dateFormat(currentItem[index]) : currentItem[index]}}</span>
                  </div>
                </div>
              </div>
              <div class="summary-footer">
                <div v-for="(item, key) in summaryKeyMap.footer"
                    :class="[`summary-footer-${key}`]"
                    :key="key">
                  <span class="footer-label">{{item}}</span>
                  <span class="footer-value">{{currentItem[key] || '-'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Widgest isOpenAll v-if="currentProcessBehavior.childrenData.length" widgestType="outer" :isBig="true">
          <template v-slot:title>{{$t('report.behavior.signature')}}</template>
          <BehaviorSignature :data="currentProcessBehavior" />
        </Widgest>
        <Widgest isOpenAll widgestType="outer" :isBig="true">
          <template v-slot:title>{{$t('report.behavior.events.title')}}</template>
          <div class="progress-analyse-events-list">
            <EventSequence :taskId="taskId"
                           :pid="currentPid"
                           :currentItem="currentItem"
                           :eventsMap="progressanalyse.events_map"
                           :taskOS="taskOS" />
          </div>
        </Widgest>
      </template>
      <behavior-detail v-else :data="dropedFileDetail"></behavior-detail>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
// 报告页 行为分析 tab 页
import legendList from 'utils/lib/treeIconList'
// import Tree from '@/lib/tree2Canvas'
import Tree from 'utils/lib/tree'
import ProcessTree from '@/components/report/processTree'
// 重置按钮
import reset from 'assets/img/reset.svg'
import reset1 from 'assets/img/reset_1.svg'
// 放大按钮
import amplification from 'assets/img/amplification.svg'
import amplification1 from 'assets/img/amplification_1.svg'
// 下载按钮
import downloadIcon from 'assets/img/download.svg'
import downloadActiveIcon from 'assets/img/download-active.svg'
// 收起释放文件
import show from 'assets/img/show.svg'
import showActive from 'assets/img/showActive.svg'

import hide from 'assets/img/hide.svg'
import hideActive from 'assets/img/hideActive.svg'

// 缩小按钮
import shrink from 'assets/img/shrink.svg'
import shrink1 from 'assets/img/shrink_1.svg'

// import droped from 'assets/img/tree/droped.svg'
// import domains from 'assets/img/tree/domains.svg'
// import hosts from 'assets/img/tree/hosts.svg'
// import http from 'assets/img/tree/http.svg'
// import https from 'assets/img/tree/https.svg'
// 开始icon
// import start from 'assets/img/tree/start.svg'

// 其他进程
// import other from 'assets/img/tree/other.svg'
// 启动进程
// import startProgress from 'assets/img/tree/startProgress.svg'
// 释放文件
// import drop from 'assets/img/tree/drop.svg'
import arrowRight from 'assets/img/arrowRight.svg'
import arrowLeft from 'assets/img/arrowLeft.svg'

import sandNoData from '@/components/report/sandNoData'
import BehaviorDetail from './components/behaviorDetail.vue'

import BehaviorSignature from '@/components/report/behaviorSignature'
import EventSequence from '@/components/report/eventSequence'
import dashboard from '@/components/echarts/dashboard'

import { getTaskReportBehaviorSignatures } from 'services/report'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import emitter from '@/app/emitter.js'
import { watchSwitchTheme } from 'app/style'
import { t, isZh } from 'app/i18n'

import imgList from 'utils/lib/imgIcon.js'
const { droped, domains, hosts, http, https, start, other, startProgress, drop } = imgList

const icons = {
  dropped: droped,
  domains,
  hosts,
  http,
  https
}

const toolTipLabelMap = {
  dropped: t('report.behavior.relation.tooltip.dropped'),
  domains: t('report.behavior.relation.tooltip.domains'),
  hosts: t('report.behavior.relation.tooltip.hosts'),
  http: t('report.behavior.relation.tooltip.http'),
  https: t('report.behavior.relation.tooltip.https')
}
const tools = [
  {
    path: downloadIcon,
    name: 'download',
    active: downloadActiveIcon,
    label: t('download.action')
  },
  {
    path: show,
    name: 'hasDrop',
    label: t('report.behavior.relation.tools.simple'),
    show: [
      {
        hasDrop: true,
        name: t('report.behavior.relation.tools.full'),
        _name: t('report.behavior.relation.tools.simple')
      }
    ],
    active: showActive
  },
  {
    path: amplification,
    name: 'amplification',
    active: amplification1,
    label: t('report.behavior.relation.tools.amplification')
  },
  {
    path: shrink,
    name: 'shrink',
    label: t('report.behavior.relation.tools.shrink'),
    active: shrink1
  },
  {
    path: reset,
    name: 'reset',
    label: t('report.behavior.relation.tools.reset'),
    active: reset1
  }
]

export default {
  name: 'StaticInfo',
  components: {
    ProcessTree,
    BehaviorDetail,
    BehaviorSignature,
    EventSequence,
    sandNoData,
    dashboard
  },
  data () {
    return {
      tabData: [],
      currentPid: this.progressanalyse.process_tree.length > 0 ? this.progressanalyse.process_tree[0].pid : '',
      treeData: {
        path: start,
        name: t('report.behavior.relation.start.info'),
        pid: '',
        md5: '',
        time: '',
        score: ''
      },
      progressTab: 'relation',
      showFile: false,
      dropedFileDetail: {},
      node: {},
      tree: null,
      processIcon: '',
      treeLegend: [],
      summaryKeyMap: {
        content: {
          left: {
            pid: t('report.behavior.summary.pid'),
            ppid: t('report.behavior.summary.ppid')
          },
          center: {
            username: t('report.behavior.summary.username'),
            integrity_sid: t('report.behavior.summary.integrity_sid')
          },
          right: {
            first_seen: t('report.behavior.summary.first_seen'),
            process_type: t('report.behavior.summary.process_type')
          }
        },
        footer: {
          process_path: t('report.behavior.summary.process_path'),
          user_sid: t('report.behavior.summary.user_sid'),
          command_line: t('report.behavior.summary.command_line')
        }
      },
      currentProcessBehavior: {
        title: undefined,
        childrenData: []
      },
      progressanalyseLocal: this.progressanalyse
    }
  },
  created() {
    if (this.pid) this.currentPid = this.pid
  },
  mounted () {
    emitter.on('handleTreeNodeClick', (pid) => {
      this.currentPid = pid
    })
    // this.showFile = false
    // this.currentPid = v.data.pid
    // this.currentItem.process_type = v.data.type || '其他进程'
    if (this.progressanalyseLocal.process_tree && this.progressanalyseLocal.process_tree.length) {
      this.setData(this.progressanalyseLocal)
      if (this.pid) {
        this.loop(this.progressanalyseLocal.process_tree, this.pid, (item, index, arr) => {
          item.active = true
        })
      } else {
        this.progressanalyseLocal.process_tree[0].active = true
      }
      this.treeData.children = JSON.parse(JSON.stringify(this.progressanalyseLocal.process_tree))
      this.process()
      this.processIcon = this.treeData.children[0].path
      this.addTree()
      setTimeout(() => {
        const svg1 = this.tree.el.querySelector('svg').cloneNode(true)
        svg1.setAttribute('id', 'export-svg')
        document.body.appendChild(svg1)
      }, 300)
    }
    this.getProcessSignatureByPid()
    this.initResizeObserver()
    watchSwitchTheme(() => {
      this.tree && this.tree.svg && this.tree.svg.remove()
      this.addTree()
      setTimeout(() => {
        const svg1 = this.tree.el.querySelector('svg').cloneNode(true)
        svg1.setAttribute('id', 'export-svg')
        document.body.removeChild(document.body.querySelector('#export-svg'))
        document.body.appendChild(svg1)
      }, 300)
    })
  },
  props: {
    progressanalyse: {
      type: Object
    }
  },
  computed: {
    process_tree () {
      return this.progressanalyseLocal.process_tree
    },
    process_list () {
      return this.progressanalyseLocal.processes
    },
    currentItem () {
      return this.process_list.find(item => item.pid === this.currentPid) || {}
    },
    taskId () {
      return this.$route.params.taskId
    },
    ...mapGetters({
      pid: 'report/pid'
    }),
    scoreType () {
      return this.currentItem.score > 7.4 ? { label: t('report.overview.chart.is_threaten.malice'), value: 'red' } : (this.currentItem.score > 4.9 ? { label: t('report.overview.chart.is_threaten.suspicious'), value: 'orange' } : { label: t('report.overview.chart.is_threaten.secure'), value: 'green' })
    },
    taskOS () {
      return this.progressanalyseLocal.info.machine.os
    }
  },
  watch: {
    currentPid (v) {
      v && this.getProcessSignatureByPid()
    },
    progressTab: {
      handler (v) {
        this.$store.commit('report/SET_CURRENT_PID', 0)
        this.showFile = false
        this.currentPid = this.progressanalyseLocal.process_tree && this.progressanalyseLocal.process_tree.length > 0 ? this.progressanalyseLocal.process_tree[0].pid : ''
        if (v === 'relation') {
          this.tree.group.selectAll('rect').attr('stroke', 'noen')
          this.tree.group.select('._root').attr('stroke', '#F6B313').attr('stroke-width', '3')
          // this.processIcon = this.tree.group.selectAll('._rootImage').attr('href')
        }
      },
      deep: true
    },
    pid(v, o) {
      this.showFile = false
      const firstPid = this.progressanalyseLocal.process_tree && this.progressanalyseLocal.process_tree.length > 0 ? this.progressanalyseLocal.process_tree[0].pid : ''
      this.currentPid = v || firstPid
      this.loop(this.progressanalyseLocal.process_tree, o || firstPid, (item, index, arr) => {
        item.active = false
      })
      this.loop(this.progressanalyseLocal.process_tree, v || firstPid, (item, index, arr) => {
        item.active = true
      })
      this.treeData.children = JSON.parse(JSON.stringify(this.progressanalyseLocal.process_tree))
      this.process()
      this.tree && this.tree.svg && this.tree.svg.remove()
      this.addTree()
    }
  },
  methods: {
    loop (data, pid, callback) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].pid === pid) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          this.loop(data[i].children, pid, callback)
        }
      }
    },
    initResizeObserver() {
      if (this.isEmpty(this.process_tree) && this.isEmpty(this.process_list)) return
      var objResizeObserver = new ResizeObserver(() => {
        setTimeout(() => {
          this.tree && this.tree.svg && this.tree.svg.remove()
          this.addTree()
        }, 500)
      })
      // 观察文本域元素
      objResizeObserver.observe(this.$refs.progress)
    },
    dateFormat (sec) {
      if (!sec) return '-'
      const milliseconds = Math.floor(sec) * 1000
      return dayjs(milliseconds).format('YYYY-MM-DD HH:mm:ss')
    },
    async getProcessSignatureByPid () {
      if (this.taskOS === 'android' || !this.currentPid) return
      await getTaskReportBehaviorSignatures(this.taskId, this.currentPid)
        .then(res => {
          this.currentProcessBehavior = {
            title: undefined,
            childrenData: res.signatures.map(item2 => {
              return {
                title: `${isZh() ? item2.descripzhcn : item2.description} (${t('report.analyse.indicator.marks', [item2.marks.length])})`,
                desc: isZh() ? item2.descripzhcn : item2.description,
                type: item2.severity === 1 ? 'low' : (item2.severity === 2 ? 'middle' : 'high'),
                severity: item2.severity,
                childrenData: {
                  marks: item2.marks,
                  attackid: item2.attackid
                }
              }
            })
          }
        })
    },
    process () {
      const _me = this
      _me.treeLegend = [{
        name: t(`report.behavior.relation.tree_icon.startProgress`),
        path: startProgress
      }, {
        name: t(`report.behavior.relation.tree_icon.dotted`),
        path: drop
      }]
      recursive(this.treeData)
      function recursive (obj) {
        // const len = _me.node.dropped.length; let i = 0
        obj.name = (obj.process_name ? obj.process_name : obj.name) || '-'
        const { process_path } = obj
        let _path = ''
        _path = obj.path ? obj.path : other
        let name = ''
        // eslint-disable-next-line camelcase
        if (process_path) {
          for (let k = 0; k < legendList.length; k++) {
            if (process_path.match(legendList[k].reg)) {
              _path = legendList[k].path
              name = legendList[k].name
              break
            } else {
              name = t('report.behavior.relation.tree_icon.other')
            }
          }
        }
        const legendItem = _me.treeLegend.find(e => e.path === _path)
        if (!legendItem) {
          _me.treeLegend.push(legendList.find(e => e.path === _path))
        }
        obj.path = _path
        obj.type = name
        if (obj.children && obj.children.length) {
          obj.children.forEach(item => {
            recursive(item)
          })
        }
        const key_map = {
          domains: 'domain',
          https: 'uri',
          http: 'uri',
          hosts: 'ip',
          dropped: 'name'
        }
        for (const k in _me.node) {
          const len = _me.node[k].length; let i = 0
          while (i < len) {
            const o = _me.node[k][i]
            i++
            if (!o.pids) continue
            if (o.pids.includes(obj.pid)) {
              o.drop = true
              o._type = k
              const legendItem = _me.treeLegend.find((e) => (e.path === icons[k]))
              if (!legendItem) {
                _me.treeLegend.push(legendList.find(e => e.path === icons[k]))
              }
              o.label = toolTipLabelMap[k]
              o.name = o[key_map[k]]
              o.path = icons[k]
              obj.children.push(o)
            }
          }
        }
      }
    },
    addTree (data = this.treeData) {
      this.tree = new Tree({
        el: this.$refs.progress,
        data,
        tools,
        legendList: this.treeLegend,
        legendArrow: [arrowLeft, arrowRight],
        nodeEvent: (v) => {
          this.processIcon = v.data.path
          if (v.data.drop) {
            this.dropedFileDetail = v.data
            this.showFile = true
            this.dashboard = null
            this.currentPid = 0
          } else {
            this.showFile = false
            this.currentPid = v.data.pid
            this.currentItem.process_type = v.data.type || t('report.behavior.relation.tree_icon.other')
          }
        },
        toolsEvent: (v) => {
          this.treeEvent(v)
        }
      })
    },
    inverseRecursion () {
      this._treeData = cloneDeep(this.treeData)
      unDrop(this._treeData)
      function unDrop (obj) {
        const children = obj.children
        if (children && children.length) {
          for (let i = 0; i < children.length; i++) {
            if (children[i].children) {
              unDrop(children[i])
            } else {
              children.splice(i, 1)
              i--
            }
          }
        }
      }
      return this._treeData
    },
    setData (v) {
      const { target, info, dropped = [], domains = [], hosts = [], https = [], http = [], process_tree } = v
      const { md5 } = target.file ? target.file : {}
      const { started, score } = info
      const pid = this.pid ? this.pid : process_tree.length ? process_tree[0].pid : 0
      this.treeData = {
        path: start,
        name: t('report.behavior.relation.start.info'),
        pid: '',
        md5: md5 || '',
        time: started || '',
        score: score || ''
      }
      this.node = {
        dropped,
        domains,
        hosts,
        https,
        http
      }
      this.currentPid = pid || 0
      if (this.currentPid) {
        const { processPath = '' } = process_tree[0]
        let name = ''
        for (let k = 0; k < legendList.length; k++) {
          if (processPath.match(legendList[k].reg)) {
            name = legendList[k].name
            break
          } else {
            name = t('report.behavior.relation.tree_icon.other')
          }
        }
        this.currentItem.process_type = name
      }
    },
    isEmpty (target) {
      const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
      switch (type) {
        case 'object':
        case 'array':
        case 'string':
          return Object.keys(target).length === 0
        case 'undefined':
        case 'null':
          return true
        default:
          return false
      }
    },
    /**
     * 树形图右下角按钮事件回调
     */
    treeEvent (d) {
      switch (d.name) {
        // 重置缩放比例
        case 'reset':
          this.tree.svg
            .transition()
            .duration(500)
            .call(this.tree.zoom.transform, d3.zoomIdentity)
          break
        // 放大2倍
        case 'download':
          var serializer = new XMLSerializer()
          var svg1 = document.body.querySelector('#export-svg')
          if (!svg1) {
            svg1 = this.tree.el.querySelector('#export-svg')
          }
          var toExport = svg1.cloneNode(true)
          var bb = svg1.getBBox()
          toExport.setAttribute('viewBox', bb.x + ' ' + bb.y + ' ' + bb.width + ' ' + bb.height)
          toExport.setAttribute('width', bb.width)
          toExport.setAttribute('height', bb.height)
          var source = '<?xml version="1.0" encoding="UTF-8"?>\r\n' + serializer.serializeToString(toExport)
          var image = new Image()
          image.crossOrigin = 'Anonymous'
          image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)
          var canvas = document.createElement('canvas')
          canvas.width = bb.width + 40
          canvas.height = bb.height + 40
          var context = canvas.getContext('2d')
          context.fillStyle = this.$isDark() ? '#1a2024' : '#fff' // #fff设置保存后的PNG 是白色的
          context.fillRect(0, 0, 100000, 100000)
          image.onload = () => {
            context.drawImage(image, 20, 20, bb.width, bb.height)
            var a = document.createElement('a')
            a.download = isZh() ? `行为分析图-${this.taskId}.png` : `behavior-analysis-chart-${this.taskId}.png`
            a.href = canvas.toDataURL('image/png')
            a.click()
          }
          break
        // 放大2倍
        case 'amplification':
          this.tree.svg.transition().duration(1000).call(this.tree.zoom.scaleBy, 2)
          break
        // 缩小2倍
        case 'shrink':
          this.tree.svg.transition().duration(1000).call(this.tree.zoom.scaleBy, 0.5)
          break
        // 隐藏/显示释放文件
        case 'hasDrop':
          var data = null
          // 重置文件/进程详情展示标记
          this.showFile = false
          if (!d.show.hasDrop) {
            // this.tree.data = this.inverseRecursion()
            data = this.inverseRecursion()
            d.show.hasDrop = true
            d.label = t('report.behavior.relation.tools.full')
            d.path = hide
            d.active = hideActive
          } else {
            data = this.treeData
            d.show.hasDrop = false
            this.tree.data = this.treeData
            d.label = t('report.behavior.relation.tools.simple')
            d.path = show
            d.active = showActive
          }
          if (this.tree.data.children && this.tree.data.children.length) {
            // 重置当前ctive标记
            this.tree.data.children[0].active = true
            // 重置current状态
            this.currentPid = this.tree.data.children[0].pid
            this.currentItem.process_type = this.tree.data.children[0].type || t('report.behavior.relation.tree_icon.other')
          }
          // this.tree.render()
          this.tree && this.tree.svg && this.tree.svg.remove()
          this.addTree(data)
          break
        default:
          break
      }
    }
  },
  beforeUnmount () {
    // eslint-disable-next-line no-unused-expressions
    this.tree ? this.tree.distoryed() : null
  }
}
</script>
<style>
.skin_image {
  fill: red;
}
</style>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.progress-analyse-wrap  {
  padding: 20px 0 0 0;
  .progress-analyse-pic  {
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;
    background-color: var(--color-bg-3);
    margin-bottom: 20px;
    border: 1px solid var(--color-border-2);
    border-radius: 2px;
    box-sizing: border-box;
    &__btnWrap  {
      position: absolute;
      top: 16px;
      left: 20px;
      color: var(--color-text-2);
      display: inline-block;
      overflow: hidden;
      z-index: 1;
      .btn  {
        display: inline-block;
        line-height: 30px;
        text-align: center;
        width: 90px;
        height: 30px;
        box-sizing: border-box;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid var(--color-border-1);
        position: relative;
        background-color: var(--detect-config-bg);
        &.active  {
          color: #00ab7a;
          border: 1px solid #00ab7a;
          background-color: #009e71;
          color: #fff;
          z-index: 1;
        }
        &:first-child {
          border-radius: 2px 0 0 2px;
        }
        &:last-child {
          left: -1px;
          border-radius: 0 2px 2px 0;
        }
      }
    }
    .progress-tree  {
      margin-top: 50px;
      height: calc(100% - 50px);
      overflow: auto;
      @include scroll_bar();
    }
  }
  .progress-analyse-summary-main {
    border: 1px solid var(--color-border-2);
    margin-bottom: 30px;
    .detail-name {
      height:48px;
      display: flex;
      align-items: center;
      box-shadow: 0 1px 0 0 var(--color-border-2);
      padding: 0 10px;
      color: var(--color-text-1);
      .item-icon {
        padding-right: 10px;
        color:var(--color-text-3);
        width: 18px;
        height: 18px;
      }
      span {
        font-size: 14px;
        color: var(--color-text-1);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .progress-analyse-summary  {
    display: flex;
    font-size: 14px;
    color: var(--color-text-2);
    &-left  {
      width: 200px;
      flex-shrink: 0;
      border-right: 1px solid var(--color-border-2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 20px;
      box-sizing: border-box;
      &-chart  {
        position: relative;
        top: 20px;
      }
      &-desc  {
        .green  {
          color: #3EAE89;
        }
        .orange  {
          color: #E69F32;
        }
        .red  {
          color: #CF605B;
        }
      }
    }
    &-right  {
      flex: 1;
      overflow: hidden;
      .summary-content  {
        padding: 20px;
        display: flex;
        &-left, &-center, &-right  {
          flex: 1;
          flex-shrink: 0;
          &-item  {
            .content-label  {
              display: inline-block;
              width: 90px;
              color: var(--color-text-3);
            }
            &:last-child  {
              margin-top: 10px;
            }
          }
        }
        &>div:not(:first-child)  {
          border-left: 1px solid var(--color-border-2);
          padding-left: 20px;
        }
      }
      .summary-footer  {
        padding: 0 20px 0px;
        & > [class^='summary-footer-']  {
          display: flex;
          &:not(:last-child)  {
            margin-bottom: 10px;
          }
          .footer-label  {
            display: inline-block;
            width: 90px;
            line-height: 20px;
            color: var(--color-text-3);
            flex-shrink: 0;
          }
          .footer-value  {
            line-height: 20px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .progress-analyse-events  {
    margin-top: 30px;
    .progress-analyse-events-header  {
      // @include ft_color(color-main-font);
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      font-weight: 500;
      margin-right: 30px;
      border-left: 4px solid;
      // @include bd_color(color-main-highlight);
      padding-left: 6px;
      margin-bottom: 10px;
    }
    .progress-analyse-events-list  {
      padding: 10px 20px;
    }
  }
}
</style>
