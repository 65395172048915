<template>
  <div class="dir-wrap">
    <div :class="['file-name', {isFile: !list.children}]" @click="handleClick(list.children ? 'dir' : 'file')">
      <span v-if="!list.children" class="file-icon">
        <img src="@/assets/img/svg/detail.svg" alt="" />
      </span>
      <span v-else class="dir-icon">
        <img v-if="showChildren" src="@/assets/img/svg/minus.svg" alt="" />
        <img v-else src="@/assets/img/svg/plus.svg" alt="" />
      </span>
      <span class="name">{{list.name}}</span>
    </div>
    <template v-if="!list.children && showDetail">
      <div class="file-detail-wrap">
        <div v-for="(item2, index) in keyMap" :key="index" class="file-detail-item">
          <span class="label">{{item2}}: </span>
          <span class="value" v-if="index === 'compress_size' || index === 'file_size'">{{list.detail[index]}} {{$t('suffix.byte')}}</span>
          <span v-else class="value">{{index === 'filename' ? list.name : list.detail[index]}}</span>
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
    <DirComponent v-if="list.children && showChildren" :list="item2" v-for="item2 in list.children" :key="item2.name" />
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { t } from 'app/i18n'

defineProps({
  list: {
    type: Object
  }
})

const keyMap = {
  filename: t('report.static.zip_list.filename'),
  compress_size: t('report.static.zip_list.compress_size'),
  file_size: t('report.static.zip_list.file_size'),
  date_time: t('report.static.zip_list.date_time'),
  CRC32: 'CRC32'
}

const showDetail = ref(false)
const showChildren = ref(false)

const handleClick = (type) => {
  if (type === 'file') {
    showDetail.value = !showDetail.value
  } else {
    showChildren.value = !showChildren.value
  }
}
</script>

<style lang="scss" scoped>
[lang='en'] {
  .dir-wrap .file-detail-wrap .file-detail-item .label {
    width: 130px;
  }
}
  .dir-wrap {
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    user-select: none;
    color: var(--color-text-2);
    .file-name {
      margin: 10px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.isFile {
        color: #00ab7a;
      }
      .file-icon {
        display: inline-block;
        width: 11px;
        height: 14px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .dir-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .file-detail-wrap {
      background: var(--color-bg-3);
      border-left: 2px solid var(--color-border-2);
      padding: 12px 28px;
      .file-detail-item {
        padding: 5px 0;
        .label {
          display: inline-block;
          width: 100px;
          color: var(--color-text-3);
        }
        .value {
          color: var(--color-text-2);
        }
      }
    }
  }
</style>
