<template>
  <Widgest widgestType="outer" isOpenAll :isBig="true" v-if="sigsLen > 0 || !onlyIncludeStatic">
    <template #title>MITRE ATT&CK™ {{ $t('other.views.reportPdf.pointDetection') }}：<span :style="{color: $isDark() ? '#ccceda' : '#525966'}">{{ $t('other.views.reportPdf.exist') }} <font color="#00ab7a">{{ sigsLen }}</font>{{ $t('other.views.reportPdf.attckPoints') }}</span></template>
    <!-- <div class="mitreAttck-wrap">
      <el-checkbox v-model="showAll" class="check"
        >隐藏未命中的技术点</el-checkbox
      >
    </div> -->
    <table class="mitre-table">
      <tr class="mitre-table-header" v-if="sigsLen > 0 || !showAll">
        <th v-for="(v, index) in mitre" :key="index" class="mitre-th">{{ v.name }}</th>
      </tr>
      <tr class="mitre-table-row" v-for="(i, rowIndex) in mitreMaxLen" :key="rowIndex">
        <td
          :class="[
            'mitre-table-col',
            {
              match: item.techiques.length > rowIndex && sigs[item.techiques[rowIndex].id]
            }
          ]"
          v-for="(item, colIndex) in showAttckMitre"
          :key="colIndex"
        >
          <span
            v-if="item.techiques.length > rowIndex && sigs[item.techiques[rowIndex].id]"
            @click="attckClick(item.techiques[rowIndex])"
          >
            {{ item.techiques.length > rowIndex ? item.techiques[rowIndex].name : '' }}
            <div class="tagWrapper">
              <template v-for="(c, x) in tagCalc(item.techiques[rowIndex].id)">
                <span v-if="c !== 0" :key="x" :class="`flag-${x}`">
                  {{ c }}
                </span>
              </template>
            </div>
          </span>
          <span v-else>
            {{ item.techiques.length > rowIndex ? item.techiques[rowIndex].name : '' }}
          </span>
        </td>
      </tr>
    </table>
    <el-dialog
      v-model="mitreShow"
      width="1200px"
      custom-class="attck-dialog-class"
      @close="showDetail = false"
      :close-on-click-modal="false"
    >
      <template #title>
        <div class="title-wrap">
          <span class="title">{{ $t('other.views.reportPdf.mitreattPoints') }}</span>
          <!-- <el-checkbox v-if="!showDetail" v-model="showAll" class="check"
            >显示全部技术点</el-checkbox
          > -->
        </div>
      </template>
      <div
        v-if="!showDetail"
        class="attckTable-wrap animate__animated animate__fadeInLeft animate__faster"
      >
        <!-- <div class="mitre-wrap" style="display:flex;">
          <div
            v-for="(v, index) in mitre"
            :key="index"
            class="mitre-col">
            <div class="mitre-arrow">{{v.name}}</div>
            <template v-for="(t, i) in v.techiques">
              <div
                v-if="showAll || (showAll === false && sigs[t.id])"
                :key="i"
                :class="['cell', {match: sigs[t.id] !== undefined}, convertBorder(tagCalc(t.id))]"
                @click="attckClick(t)"
              >
                <div v-if="sigs[t.id]" class="cell-hit">
                  <span class="cell-hit-name" :title="t.id">
                    {{ t.name }}
                  </span>
                  <span class="cell-hit-footer">
                    <span v-if="c !== 0"
                      v-for="(c, x) in tagCalc(t.id)"
                      :key="x"
                      :class="`dot-${x}`"
                    >
                      {{c}}
                    </span>
                  </span>
                </div>
                <div v-else style="text-align: center;">
                  {{t.name}}
                </div>
              </div>
            </template>
          </div>
        </div> -->
      </div>
      <div v-else class="attckDetail-wrap">
        <div class="detail-mitre">
          <div class="row">
            <div>ATT&CK ID</div>
            <div>{{ attck.id }}</div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.name') }}</div>
            <div>{{ attck.name }}</div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.tactic') }}</div>
            <div>{{ attck.tactic }}</div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.permission') }}</div>
            <div>
              {{
                attck.x_mitre_permissions_required
                  ? attck.x_mitre_permissions_required.join(',')
                  : ''
              }}
            </div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.illustrate') }}</div>
            <div v-html="attck.description" class="description-wrap"></div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.source') }}</div>
            <div>
              <a :href="attck.url" rel="noreferrer" target="_blank">{{ attck.url }}</a>
            </div>
          </div>
          <div class="row">
            <div>{{ $t('other.views.reportPdf.index') }}</div>
            <div>
              <ul>
                <li
                  v-for="item in sigs[attck.id]"
                  :key="item.desc"
                  :style="{
                    color:
                      item.severity === 1 ? '#537EF3' : item.severity === 2 ? '#E69F32' : '#F06D67'
                  }"
                >
                  {{ item.desc }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </Widgest>
</template>

<script>
// 分析概览中 MITRE ATT&CK 模块
import mitre from 'utils/lib/AttckData'
export default {
  name: 'MitreAttck',
  props: {
    behaviorfeature: {
      type: Object
    },
    onlyIncludeStatic: {
      type: Boolean
    }
  },
  data: () => ({
    mitreShow: false,
    showDetail: false,
    showAll: true,
    mitre
  }),
  computed: {
    signatures() {
      return this.behaviorfeature.signatures
    },
    sigs() {
      const ret = {}
      if (this.signatures) {
        this.signatures.forEach((ele) => {
          // 目前有空格id
          if (ele.attackid && ele.attackid !== ' ') {
            ele.attackid.split(',').forEach((id) => {
              if (ret[id] === undefined) {
                ret[id] = []
              }
              ret[id].push({
                id: id,
                desc: ele.descripzhcn,
                severity: ele.severity
              })
            })
          }
        })
      }
      return ret
    },
    sigsLen() {
      return Object.keys(this.sigs).length
    },
    mitreMaxLen() {
      return this.showAttckMitre
        .map((item) => item.techiques.length)
        .reduce((prev, cur) => Math.max(prev, cur), 0)
    },
    showAttckMitre() {
      return !this.showAll ? this.mitre : this.attckMitre
    },
    attckMitre() {
      let list = []
      let list2 = []
      for (const i in this.sigs) {
        list = list.concat(this.sigs[i])
      }
      list2 = this.mitre.map((item) => {
        return {
          ...item,
          techiques: item.techiques.filter((item2) => list.find((item3) => item3.id === item2.id))
        }
      })
      return list2
    }
  },
  methods: {
    handleOpenAttck() {
      this.mitreShow = true
    },
    attckClick(t) {
      if (!this.sigs[t.id]) return
      this.attck = t
      this.mitreShow = true
      this.showDetail = true
    },
    tagCalc(id) {
      var ret = {
        blue: 0,
        warning: 0,
        error: 0
      }
      if (this.sigs && this.sigs[id]) {
        this.sigs[id].forEach(function(ele) {
          if (ele.severity === 1) {
            ret.blue++
          } else if (ele.severity === 2) {
            ret.warning++
          } else if (ele.severity >= 3) {
            ret.error++
          }
        })
      }
      return ret
    },
    convertBorder(obj) {
      if (obj.error) {
        return 'red'
      } else if (obj.warning) {
        return 'orange'
      } else if (obj.blue) {
        return 'blue'
      } else {
        return ''
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.mitreAttck-wrap  {
  color: var(--color-text-2);
  font-size: 14px;
  padding: 0 0 15px;
}
.mitre-table  {
  border-collapse: collapse;
  // display:block;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  @include scroll_bar();
  .mitre-table-header  {
    .mitre-th  {
      height: 40px;
      font-size: 12px;
      // color: #E0E4EB;
      color: var(--color-text-2);
      // background: #2c3142;
      background: var(--color-bg-3);
      border: 1px solid var(--color-border-2);
      font-weight: 400;
    }
  }
  .mitre-table-row  {
    .mitre-table-col  {
      width: calc(100% / 12);
      height: 66px;
      border: 1px solid var(--color-border-2);
      color: var(--color-text-3);
      font-size: 12px;
      vertical-align: top;
      padding: 4px 7px;
      box-sizing: border-box;
      line-height: 16px;
      position: relative;
      &.match  {
        color: var(--color-text-1);
        cursor: pointer;
      }
      .tagWrapper {
        position: absolute;
        bottom: 3px;
        right: 7px;
      }
      .flag-blue, .flag-warning, .flag-error  {
        display: inline-block;
        width: 14px;
        height: 14px;
        font-size: 10px;
        color: var(--color-text-1);
        text-align: center;
        line-height: 14px;
        border-radius: 2px;
        &+[class^="flag-"]  {
          margin-left: 4px;
        }
      }
      .flag-blue  {
        background: #00ab7a;
      }
      .flag-warning  {
        background: #e69f32;
      }
      .flag-error  {
        background: #ff5967;
      }
    }
  }
}
:deep(.attck-dialog-class)  {
  overflow: hidden;
  min-width: 1200px;
  background: var(--detect-config-bg);
  .el-dialog__header  {
    .title-wrap  {
      display: flex;
      justify-content: space-between;
      margin-right: 70px;
      .title  {
        font-size: 16px;
        color: var(--color-text-1);
        font-weight: 500;
      }
      .check  {
        .el-checkbox__label  {
          // @include ft_color(color-center-label);
        }
        .el-checkbox__inner  {
          background: transparent;
        }
      }
    }
    .el-dialog__headerbtn  {
      .el-dialog__close  {
        font-size: 20px;
        color: #868EC2;
        // &:hover  {
        //   filter: brightness(3);
        // }
      }
    }
  }
  .el-dialog__body  {
    padding: 0 24px 0 0;
    // max-height: 70vh;
    .attckTable-wrap  {
      display: flex;
      flex-direction: column;
      .el-checkbox  {
        .el-checkbox__input  {
          .el-checkbox__inner  {
            // @include bg_color(color-button-disable);
          }
        }
        .el-checkbox__label  {
          // @include ft_color(color-center-value);
        }
      }
    }
    .attckDetail-wrap  {
      span  {
        cursor: pointer;
      }
      &__back  {
        line-height: 20px;
        // @include ft_color(color-main-highlight);
        .el-icon-back  {
          vertical-align: middle;
          font-size: 20px;
        }
      }
      .detail-mitre  {
        max-height: 70vh;
        padding: 20px 0 16px 0;
        .row  {
          display: flex;
          margin-bottom: 10px;
          div:first-child  {
            width: 116px;
            margin-right: 50px;
            font-weight: 700;
            font-size: 14px;
            color: var(--color-text-1);
            text-align: right;
          }
          div:last-child  {
            flex: 1;
            font-size: 14px;
            color: var(--color-text-2);
            a:link, a:visited  {
              color: #537EF3;
            }
          }
          .description-wrap  {
            height: 252px;
            padding: 4px 20px;
            overflow-y: scroll;
            background: var(--color-bg-3);
            border: 1px solid var(--color-border-2);
            border-radius: 2px;
            p  {
              line-height: 1.5;
            }
          }
          ul  {
            padding-inline-start: 20px;
            margin: 0;
            li  {
              list-style-type: disc;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
</style>
