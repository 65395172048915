<template>
  <div class="imports-table-wrap" ref="body">
    <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
    <div v-if="importTable.length > 0" v-for="(item, index) in importTable"
      :key="`${item.dll}-${index}`"
      :class="['import-item', {active: currentDll === `${item.dll}-${index}`}]"
    >
      <div class="import-table-header">
        <span class="plus-minus"
          @click="handleClick(item, index)"
        >
          <img :src="getIcon(item, index)" alt="">
        </span>
        <span class="dll-title">{{item.dll}}</span>
      </div>
      <div class="import-table-content" v-if="currentDll === `${item.dll}-${index}`">
        <div v-for="item2 in item.imports" :key="item2.name" class="import-table-item">
          <span class="import-table-item-address">{{item2.address}}</span>
          <span class="import-table-item-name" @click="handleAddress(item2.name)">{{item2.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue'

defineProps({
  importTable: {
    type: Array,
    default () {
      return []
    }
  }
})
const emit = defineEmits(['changeHeight'])
const currentDll = ref('')

const getIcon = (item, index) => {
  const type = currentDll.value === `${item.dll}-${index}` ? 'minus' : 'plus'
  return require(`@/assets/img/svg/${type}.svg`)
}
const handleAddress = (name) => {
  window.open(`https://docs.microsoft.com/en-us/search/?terms=${name}`, '_blank')
  return false
}
const handleClick = (item, index) => {
  currentDll.value = currentDll.value === `${item.dll}-${index}` ? '' : `${item.dll}-${index}`
  emit('changeHeight')
}
</script>

<style lang="scss" scoped>
  .imports-table-wrap {
    position: relative;
    .import-item {
      &.active {
        .import-table-header {
          .dll-title {
            color: var(--color-text-1);
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .import-table-header {
        display: flex;
        align-items: center;
        .plus-minus {
          display: inline-block;
          width: 18px;
          height: 18px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .dll-title {
          margin-left: 18px;
          color: var(--color-text-2);
          font-size: 16px;
          line-height: 22px;
        }
      }
      .import-table-content {
        background: var(--color-bg-3);
        border-left: 2px solid var(--color-border-2);
        margin-left: 10px;
        margin-top: 10px;
        padding: 8px 28px;
        line-height: 20px;
        .import-table-item {
          height: 26px;
          line-height: 26px;
          font-size: 14px;
          &-address {
            color: var(--color-text-3);
            margin-right: 16px;
          }
          &-name {
            color: var(--color-text-2);
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
