<template>
  <div :class="['sand-fileContent', { noborder }]" v-if="data.length">
    <div class="sand-fileContent-item" v-for="(item, index) in data" :key="index">
      <div class="seq">{{ item.seq }}:</div>
      <div class="hex" v-html="fmtHex(item.hex)"></div>
      <div class="asc">{{ item.asi }}</div>
    </div>
  </div>
  <sand-no-data v-else></sand-no-data>
</template>
<script setup>
import sandNoData from './sandNoData'
import { defineProps } from 'vue'

defineProps({
  data: {
    type: Array,
    default: () => []
  },
  noborder: {
    type: Boolean
  }
})

const fmtHex = (hex) => {
  let res = ''
  for (let i = 0; i < hex.length; i++) {
    if (i !== 0 && i % 16 === 0) {
      res += '&nbsp;&nbsp;&nbsp;'
    } else if (i !== 0 && i % 2 === 0) {
      res += ' '
    }
    res += hex[i]
  }
  return res
}
</script>
<style lang="scss" scoped>
.sand-fileContent {
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  padding: 20px;
  background: var(--color-bg-3);
  border: 1px solid var(--color-border-2);
  border-radius: 2px;
  // color: var( --color-unkown-text);
  color: var( --color-unkown-text);

  .sand-fileContent-item {
    display: flex;
    font-family: CONSOLA;
    font-size: 14px;

    .seq {
      width: 90px;
      font-family: monospace, monospace;
    }

    .hex {
      width: 440px;
      font-family: monospace, monospace;
    }

    .asc {
      white-space: pre;
      font-family: monospace, monospace;
    }
  }
}
</style>
