import { t } from 'app/i18n'
import imgList from './imgIcon'

const $t = (v) => {
  return t(`report.behavior.relation.tree_icon.${v}`)
}

const files = require.context('assets/img/tree', false, /\.svg/)
const legendList = []
const map = {
  start: {
    name: $t('start'),
    exeName: ['start.exe'],
    reg: /start.exe/i
  },
  zip: {
    name: $t('zip'),
    exeName: ['rar.exe', 'zip.exe', '7z.exe'],
    reg: /rar.exe|zip.exe|7z.exe/i
  },
  word: {
    name: 'WORD',
    exeName: ['WINWORD.exe'],
    reg: /WINWORD.exe/i
  },
  system: {
    name: $t('system'),
    exeName: ['C:\\Windows\\*.exe'],
    reg: /C:\\Windows\\.*.exe/i
  },
  script: {
    name: $t('script'),
    exeName: ['wscript.exe'],
    reg: /wscript.exe/i
  },
  resources: {
    name: $t('resources'),
    exeName: ['explorer.exe'],
    reg: /explorer.exe/i
  },
  registry: {
    name: $t('registry'),
    exeName: ['regedit.exe', 'reg.exe'],
    reg: /regedit.exe|reg.exe/i
  },
  ppt: {
    name: 'POWERPOINT',
    exeName: ['POWERPNT.exe'],
    reg: /POWERPNT.exe/i
  },
  pointBoard: {
    name: $t('pointBoard'),
    exeName: ['mspaint.exe'],
    reg: /mspaint.exe/i
  },
  pdf: {
    name: 'ADOBE PDF READER',
    exeName: ['acrord32.exe'],
    reg: /acrord32.exe/i
  },
  outlook: {
    name: 'OUTLOOK',
    exeName: ['OUTLOOK.exe'],
    reg: /OUTLOOK.exe/i
  },
  other: {
    name: $t('other'),
    exeName: ['null'],
    reg: /null/i
  },
  note: {
    name: $t('note'),
    exeName: ['notepad.exe'],
    reg: /notepad.ex/i
  },
  ie: {
    name: 'IE',
    exeName: ['iexplore.exe'],
    reg: /iexplore.exe/i
  },
  excel: {
    name: 'EXCEL',
    exeName: ['EXCEL.exe'],
    reg: /EXCEL.exe/i
  },
  dynamic: {
    name: $t('dynamic'),
    exeName: ['rundll32.exe'],
    reg: /rundll32.exe/i
  },
  droped: {
    name: $t('droped'),
    exeName: ['droped'],
    reg: /droped/i
  },
  drop: {
    name: $t('drop'),
    exeName: ['drop'],
    reg: /drop/i
  },
  domains: {
    name: $t('domains'),
    exeName: ['domains'],
    reg: /domains/i
  },
  hosts: {
    name: $t('hosts'),
    exeName: ['hosts'],
    reg: /hosts/i
  },
  https: {
    name: $t('https'),
    exeName: ['https'],
    reg: /https/i
  },
  http: {
    name: $t('http'),
    exeName: ['http'],
    reg: /http/i
  },
  calculator: {
    name: $t('calculator'),
    exeName: ['calc.exe'],
    reg: /calc.exe/i
  },
  command: {
    name: $t('command'),
    exeName: ['cmd.exe'],
    reg: /cmd.exe/i
  },
  powershell: {
    name: 'POWERSHELL',
    exeName: ['powershell.exe'],
    reg: /powershell.exe/i
  },
  chrome: {
    name: 'CHROME',
    exeName: ['chrome.exe'],
    reg: /chrome.exe/i
  },
  firefox: {
    name: 'FIREFOX',
    exeName: ['firefox.exe'],
    reg: /firefox.exe/i
  },
  wps: {
    name: 'WPS',
    exeName: ['wps.exe'],
    reg: /wps.exe/i
  },
  startProgress: {
    name: $t('startProgress'),
    exeName: ['startProgress.exe'],
    reg: /startProgress.exe/i
  }
}

files.keys().forEach(key => {
  const str = key.replace(/(\.\/|\.svg)/g, '')
  const { reg } = map[str]
  legendList.push({
    name: map[str].name,
    path: imgList[str],
    reg
  })
})
/**
 *
 * 处理图例顺序
 * 依次为开始,启动进程,释放进程 => 其他
 *
 */
const arr = [$t('droped'), $t('drop'), $t('startProgress'), $t('start')]
arr.forEach(item => {
  const index = legendList.findIndex(e => e.name === item)
  const start = legendList.find(e => e.name === item)
  legendList.splice(index, 1)
  legendList.unshift(start)
})
export default legendList
