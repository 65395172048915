<template>
  <div class="wrapper">
    <el-dialog
      v-model="_show"
      :title="isConfrim ? '确认' : '误报'"
      width="560px"
      @close="emits('update:show', false)"
    >
      <div v-if="!isConfrim" class="tips">
        <svg-icon name="info"></svg-icon>
        <span>标记误报的任务大约24小时后从规则命中任务列表中移除，确认是否标记误报？</span>
      </div>
      <div class="content">
        <div class="explain">{{isConfrim ? '确认说明' : '误报说明'}} <font color="#FC3D40">*</font></div>
        <el-input :placeholder="isConfrim ? '请输入确认说明' : '请输入误报说明'" type="textarea" :disabled="readonly" show-word-limit rows="8" maxlength="200" v-model.trim="descrtption" />
      </div>
      <div class="images">
        <el-upload
          v-if="!readonly"
          v-model:file-list="fileList"
          ref="uploadRef"
          action=""
          :auto-upload="false"
          list-type="picture-card"
          :before-upload="handleBeforeUpload"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :on-remove="handleRemove"
          multiple
          limit="5"
        >
          <el-icon><Plus /></el-icon>
          <template #file="{ file }">
            <div class="_item">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <span
                  v-if="!readonly"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>
        <div class="images-list" v-else>
          <div class="item" v-for="(item, index) in imageList" :key="index" @click="handlePictureCardPreview({ url: `/api/v1/hunting/task/image/${item}` })">
            <img class="el-upload-list__item-thumbnail" :src="`/api/v1/hunting/task/image/${item}`" alt="" />
          </div>
        </div>
      </div>
      <span class="images-limit" v-if="!readonly">最多5张，单张不超过2MB</span>
      <template #footer v-if="!readonly">
        <div class="footer">
          <span class="dialog-footer">
            <el-button @click="cancel">{{ $t('action.cancel') }}</el-button>
            <el-button type="primary" @click="next">{{ $t('action.confirm') }}</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
    <el-dialog  v-model="dialogVisible" custom-class="image-preview">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted } from 'vue'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { confirmThreat, confirmStatic, getStaticImgUrl, getThreatImgUrl, downloadImage } from 'services/hunting'
import { message } from '@/hooks/useUI'

const props = defineProps({
  show: {
    default: false,
    type: Boolean
  },
  pageType: {
    default: 'threat',
    type: String
  },
  type: {
    default: 1,
    type: Number
  },
  task_id: {
    default: 0,
    type: Number
  },
  rule_id: {
    default: 0,
    type: Number
  },
  readonly: {
    default: false,
    type: Boolean
  }
})
const uploadRef = ref()
const dialogVisible = ref(false)
const dialogImageUrl = ref('')
const imageList = ref([])

const isConfrim = computed(() => props.type === 1)
const fileList = ref(
  [
  ]
)

onMounted(() => {
  if (props.readonly) {
    if (props.pageType === 'threat') {
      getThreatImgUrl(props.rule_id + '_' + props.task_id).then(res => {
        console.log(res.task.remark)
        descrtption.value = res.task.remark
        imageList.value = res.task.images
        downloadImage(res.task.images[0]).then(res => console.log(res))
      })
    } else {
      getStaticImgUrl(props.rule_id + '_' + props.task_id).then(res => {
        console.log(res)
        descrtption.value = res.task.remark
        imageList.value = res.task.images
      })
    }
  }
})
const handleRemove = (file) => {
  const index = fileList.value.indexOf(file)
  fileList.value.splice(index, 1)
}
const handleChange = (file, fileList) => {
  console.log(file)
  const maxSize = 2 * 1024 * 1024
  fileList.forEach(file => {
    if (file.size > maxSize) {
      message('单个文件大小不能超过 2MB!', 'error')
      const index = fileList.indexOf(file)
      fileList.splice(index, 1)
    }
  })
}
const handleBeforeUpload = file => {
  const formData = new FormData()
  if (file) formData.append('images', file)
  formData.append('status', props.type)
  formData.append('remark', descrtption.value)
  if (props.pageType === 'threat') {
    confirmThreat(props.rule_id, props.task_id, formData).then(res => {
      if (res?.flag) {
        message('操作成功', 'success')
        emits('freshList')
        _show.value = false
      } else {
        message(res.message, 'error')
      }
    })
  } else {
    confirmStatic(props.rule_id, props.task_id, formData).then(res => {
      if (res?.flag) {
        message('操作成功', 'success')
        emits('freshList')
        _show.value = false
      } else {
        message(res.message, 'error')
      }
    })
  }
  return false
}
const handleExceed = (files, fileList) => {
  message(`最多上传5张图片`, 'error')
}
const handlePictureCardPreview = uploadFile => {
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}

const descrtption = ref('')
const emits = defineEmits(['update:show', 'freshList'])
const _show = ref(props.show)
function cancel() {
  _show.value = false
}
function next() {
  if (!descrtption.value) {
    message(`请输入${isConfrim.value ? '确认' : '误报'}说明`, 'error')
    return
  }
  if (!fileList.value.length) {
    handleBeforeUpload()
  } else {
    uploadRef.value.submit()
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  :deep(.el-dialog__body) {
    padding: 0 0 24px;
    .tips {
      background-color:var(--color-warning-tip);
      font-size: 14px;
      color: var(--color-text-1);
      line-height: 40px;
      padding-left: 24px;
      display: flex;
      align-items: center;
      svg {
        color: var(--color-warning-text);
        font-size: 20px;
        margin-right: 3px;
      }
    }
    .content {
      padding: 16px 24px 0px;
      .explain {
        color: var(--color-text-1);
        margin-bottom: 6px;
      }
    }
    .images {
      padding: 16px 24px 0;
      .images-list {
        --el-upload-list-picture-card-size: 64px;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        .item {
          overflow: hidden;
          background-color: var(--el-fill-color-blank);
          border: 1px solid var(--el-border-color);
          border-radius: 6px;
          box-sizing: border-box;
          width: var(--el-upload-list-picture-card-size);
          height: var(--el-upload-list-picture-card-size);
          margin: 0 8px 8px 0;
          padding: 0;
          display: inline-flex;
          cursor: pointer;
          &>img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .images-limit {
      color: var(--color-text-3);
      font-size: 12px;
      padding-left: 24px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      --el-upload-list-picture-card-size: 64px;
    }
    .el-upload--picture-card {
      --el-upload-picture-card-size: 64px;
    }
  }
  :deep(.el-dialog__footer) {
    .footer {
      display: flex;
      flex-direction: column;
      justify-content:space-between;
      .change {
        font-size: 14px;
        color: var(--color-priamry);
        cursor: pointer;
      }
    }
  }
  :deep(.image-preview) {
    .el-dialog__header {
      padding-top: 30px;
    }
  }
}
</style>
