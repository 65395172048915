// import { nextTick } from 'vue'

export function resetTransition() {
  setTimeout(() => {
    const doms = document.querySelectorAll('.el-collapse-item__wrap')
    Array.from(doms).map(v => {
      v.style.transition = 'all .3s'
    })
  }, 1000)
}
