<template>
  <div class="static-info-wrap">
    <div class="static-wrap">
      <Widgest widgestType="outer" isOpenAll>
        <template #title>{{taskInfo.category !== 'url' ? $t('report.static.info.file_title') : $t('report.static.info.url_title')}}</template>
        <div>
          <SandJsonTable
            :data="infoData"
            :noborder="true"
          />
        </div>
      </Widgest>
      <Widgest isOpenAll :styleText="{padding: '10px 0px 20px'}" v-if="taskInfo.category !== 'url'" widgestType="outer">
        <template #title>{{$t('report.static.content.title')}}</template>
         <ButtonTab
          :buttonList="buttonList"
          @change="handleTypeChange"
          class="button-wrap"
          :current="type"
        />
        <div v-if="type === 'static'">
          <Authorization
            :gid="gid"
            authModule="file_content"
            :hasPayment="fileContent.has_permission"
          >
            <template v-slot:payment>
              <SandFileContent :data="fileContent.data" />
            </template>
          </Authorization>
        </div>
         <div v-else class="strings-wrap" ref="stringsEle">
          <Authorization :gid="gid" authModule="strings" :hasPayment="staticinfo.strings.has_permission">
            <template v-slot:payment>
              <div class="code-pre" v-if="!isEmpty(staticinfo?.strings?.data)">
                <pre>{{ staticinfo?.strings?.data.join('\n') }}</pre>
              </div>
              <sand-no-data v-else/>
            </template>
          </Authorization>
        </div>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(permission.tableData)">
        <template v-slot:title>{{$t('report.static.apk.title')}}</template>
        <sand-table class="apk-table" :data="permission"></sand-table>
      </Widgest>
      <!-- <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(email_body?.data)">
        <template v-slot:payment>
          <div class="code-pre" v-if="!isEmpty(email_body?.data?.body)">
            <pre>{{ email_body?.data?.body }}</pre>
          </div>
          <sand-no-data v-else/>
        </template>
      </Widgest> -->
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(email_body?.data)">
        <template v-slot:title>{{$t('report.static.email.title')}}</template>
        <SandJsonTable :data="handleTableData(emailKeyMap, email_body?.data)" :noborder="true" />
        <div v-if="!isEmpty(attachments.tableData)" class="email-info-wrap">
          <div class="email-label">{{$t('report.static.email.attachment')}}</div>
          <div class="email-table">
            <sand-table :data="attachments"></sand-table>
          </div>
        </div>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.lnk)">
        <template v-slot:title>{{$t('report.static.lnk.title')}}</template>
        <SandJsonTable
          :data="handleTableData(lnkKeyMap, detail.lnk)"
          :noborder="true"
        />
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(email_body?.data?.body)">
        <template v-slot:title>{{$t('report.static.email_body')}}</template>
        <Authorization :gid="gid" authModule="email_body" :hasPayment="detail.email.has_permission">
          <template v-slot:payment>
            <SandText :extend="emailExtend">
              <pre class="email-body" ref="emailBody">{{ email_body?.data.body }}</pre>
            </SandText>
          </template>
        </Authorization>
      </Widgest>
      <Widgest
        isOpenAll
        widgestType="outer"
        v-if="peinfo &&
          !Object.keys(peinfo)
            .map((item) => peinfo[item])
            .every((item) => !item)
        "
      >
        <template v-slot:title>{{$t('report.static.pe_info.title')}}</template>
        <SandJsonTable :data="handleTableData(peinfoKeyMap, peinfo)" :noborder="true" />
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(pe_versioninfo)">
        <template v-slot:title>{{$t('report.static.version')}}</template>
        <table class="versionInfo-table" v-if="pe_versioninfo.some((item2) => item2.value)">
          <tr v-for="(item, index) in pe_versioninfo" :key="`${item.value}${index}`">
            <th>{{ $isZh() ? (versionKeyMap[item.name] || item.name) : item.name }}</th>
            <td>{{ item.value || '-' }}</td>
          </tr>
        </table>
      </Widgest>
      <!-- <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail?.peid_signatures)">
        <template v-slot:title>PEid签名</template>
        <sand-table :data="importsTableConvert(detail.peid_signatures)"></sand-table>
      </Widgest> -->
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(signature?.tableData)">
        <template v-slot:title>{{$t('report.static.signature.title')}}</template>
        <sand-table :data="signature"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(pe_sections?.tableData)">
        <template v-slot:title>{{$t('report.static.pe_sections.title')}}</template>
        <sand-table :data="pe_sections"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(pe_resources?.tableData)">
        <template v-slot:title>{{$t('report.static.pe_resources.title')}}</template>
        <sand-table :data="pe_resources"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(pe_imports)">
        <template v-slot:title>{{$t('report.static.pe_imports')}}</template>
        <SandText :extend="importExtend">
          <ImportTable :importTable="pe_imports" @changeHeight="changeHeight" ref="importBody" />
        </SandText>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(pe_exports?.tableData)">
        <template v-slot:title>{{$t('report.static.pe_exports.title')}}</template>
        <sand-table :data="pe_exports"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(archive_info)">
        <template v-slot:title>{{$t('report.static.zip_list.title')}}</template>
        <ZipList :archive_info="archive_info" :filename="staticinfo.target.name" />
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(office_summary)">
        <template v-slot:title>{{$t('report.static.office_summary.title')}}</template>
        <SandJsonTable
          :data="handleTableData(officeSummaryKeyMap, office_summary, '135px')"
          :noborder="true"
        />
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(vba_info?.collapseData)">
        <template v-slot:title>{{$t('report.static.vba_info.title')}}</template>
        <Authorization :gid="gid" authModule="vba_info" :hasPayment="detail?.office?.vba.has_permission">
          <template v-slot:payment>
            <SandTableCollapse2 :data="vba_info" class="vba-table">
              <template v-slot="{ childrenData }">
                <pre
                  v-if="childrenData.deobf_code"
                  class="vba-table-pre"
                  style="padding: 20px; margin: 0"
                  >{{ childrenData.deobf_code }}</pre
                >
              </template>
            </SandTableCollapse2>
          </template>
        </Authorization>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail?.office?.eps?.data)">
        <template v-slot:title>{{$t('report.static.eps_info.title')}}</template>
        <Authorization :gid="gid" authModule="eps_info" :hasPayment="detail?.office?.eps.has_permission">
          <template v-slot:payment>
            <SandText :extend="epsExtend" :height="267">
              <SandTableCollapse2 :data="eps_info" class="eps-table">
                <template v-slot="{ childrenData }">
                <div class="eps-table-content">
                  <SandJsonTable
                    :data="handleTableData(epsKeyMap, childrenData)"
                    :noborder="true"
                  />
                </div>
                </template>
              </SandTableCollapse2>
            </SandText>
          </template>
        </Authorization>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(ole_info?.data)">
        <template v-slot:title>{{$t('report.static.ole_info.title')}}</template>
        <Authorization :gid="gid" authModule="ole_info" :hasPayment="ole_info.has_permission">
          <template v-slot:payment>
            <SandText :extend="epsExtend" :height="267">
              <SandTableCollapse2 :data="ole_header" class="ole-table">
                <template v-slot="{ childrenData }">
                <div class="ole-table-content">
                  <SandJsonTable
                    :data="handleTableData(oleKeyMap, childrenData)"
                    :noborder="true"
                  />
                </div>
                </template>
              </SandTableCollapse2>
            </SandText>
          </template>
        </Authorization>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(swf_info?.data)">
        <template v-slot:title>{{$t('report.static.swf_info.title')}}</template>
        <Authorization :gid="gid" authModule="swf_info" :hasPayment="swf_info.has_permission">
          <template v-slot:payment>
            <SandText :extend="swfExtend" :height="285">
              <SandTableCollapse2 :data="swf_header" class="swf-table">
                <template v-slot="{ childrenData }">
                <div class="swf-table-content">
                  <SandJsonTable
                    :data="handleTableData(oleKeyMap, childrenData)"
                    :noborder="true"
                  />
                </div>
                </template>
              </SandTableCollapse2>
            </SandText>
          </template>
        </Authorization>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.elf?.file_header)">
        <template v-slot:title>{{$t('report.static.elf_info.title')}}</template>
        <SandJsonTable
          :data="handleTableData(elfHeaderKeyMap, detail.elf?.file_header)"
          :noborder="true"
        />
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.elf?.section_headers)">
        <template v-slot:title>{{$t('report.static.elf_sections.title')}}</template>
        <sand-table :data="elf_section"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.elf?.relocations)">
        <template v-slot:title>{{$t('report.static.elf_relocations.title')}}</template>
        <plus-collapse :tableData="detail.elf?.relocations" >
          <template v-slot="{ childrenData }">
            <sand-table :data="elf_relocation(childrenData)"></sand-table>
          </template>
        </plus-collapse>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.elf?.program_headers)">
        <template v-slot:title>{{$t('report.static.elf_program.title')}}</template>
        <sand-table :data="elf_program"></sand-table>
      </Widgest>
      <Widgest isOpenAll widgestType="outer" v-if="!isEmpty(detail.elf?.symbol_tables)">
        <template v-slot:title>{{$t('report.static.elf_symbol.title')}}</template>
        <sand-table :data="elf_symbol"></sand-table>
      </Widgest>
    </div>
  </div>
</template>

<script>
/* eslint-disable indent */
// 报告页 静态分析 tab 页
import sandNoData from '@/components/report/sandNoData'
import SandJsonTable from '@/components/report/sandJsonTable'
import ButtonTab from '@/components/common/ButtonTab'
import ImportTable from '@/components/report/importTable'
import ZipList from '@/components/report/zipList'
import SandTableCollapse2 from '@/components/report/sandTableCollapse2'
import SandText from '@/components/report/sandText'
import sandTable from '@/components/report/sandTable'
import SandFileContent from '@/components/report/sandFileContent'
import plusCollapse from '@/components/report/plusCollapse'
import { mapGetters } from 'vuex'
import { t } from 'app/i18n'

export default {
  name: 'StaticInfo',
  components: {
    ImportTable,
    sandNoData,
    SandJsonTable,
    ButtonTab,
    SandTableCollapse2,
    SandText,
    sandTable,
    ZipList,
    SandFileContent,
    plusCollapse
  },
  props: {
    staticinfo: {
      type: Object
    },
    taskInfo: {
      type: Object
    }
  },
  data() {
    return {
      peinfoKeyMap: [
        { prop: 'pe_timestamp', label: t('report.static.pe_info.pe_timestamp') },
        { prop: 'pe_imphash', label: t('report.static.pe_info.pe_imphash') },
        { prop: 'pdb_path', label: t('report.static.pe_info.pdb_path') },
        { prop: 'pe_shells', label: t('report.static.pe_info.pe_shells'), format: (value) => value.join(',') }
      ],
      versionKeyMap: {
        LegalCopyright: '版权',
        InternalName: '内部名称',
        FileVersion: '文件版本',
        CompanyName: '公司名称',
        ProductName: '产品名称',
        ProductVersion: '产品版本',
        FileDescription: '文件说明',
        OriginalFilename: '原始文件名',
        LegalTrademarks: '注册商标',
        Comments: '附加信息',
        PrivateBuild: 'PrivateBuild',
        SpecialBuild: 'SpecialBuild',
        AssemblyVersion: '程序集版本',
        LanguageId: '语言',
        Language: '语言',
        'Product Date': '产品日期',
        'Assembly Version': '程序集版本',
        'Assembly File Version': '程序集文件版本',
        'Assembly Informational Version': '程序集信息版本'
      },
      emailKeyMap: [
        { prop: 'from', label: t('report.static.email.from') },
        { prop: 'to', label: t('report.static.email.to') },
        { prop: 'cc', label: t('report.static.email.cc') },
        { prop: 'subject', label: t('report.static.email.subject') },
        { prop: 'date', label: t('report.static.email.date') }
      ],
      epsKeyMap: [
        { prop: 'eps_name', label: t('report.static.eps_info.eps_name') },
        { prop: 'md5', label: 'MD5' },
        { prop: 'sha1', label: 'SHA1' },
        { prop: 'sha256', label: 'SHA256' },
        { prop: 'sha512', label: 'SHA512' }
      ],
      fileKeyMap: [
        { prop: 'name', label: t('report.static.info.file_label.name'), copy: true },
        { prop: 'size', label: t('report.static.info.file_label.size') },
        { prop: 'qex_type', label: t('report.static.info.file_label.qex_type') },
        { prop: 'type', label: t('report.static.info.file_label.type') },
        { prop: 'md5', label: 'MD5', copy: true },
        { prop: 'sha1', label: 'SHA1', copy: true },
        { prop: 'sha256', label: 'SHA256', copy: true },
        { prop: 'crc32', label: 'CRC32' },
        { prop: 'ssdeep', label: 'SSDeep' }
      ],
      urlKeyMap: [
        { prop: 'name', label: 'URL', copy: true },
        { prop: 'md5', label: 'MD5', copy: true },
        { prop: 'sha1', label: 'SHA1', copy: true },
        { prop: 'sha256', label: 'SHA256', copy: true }
      ],
      oleKeyMap: [
        { prop: 'name', label: t('report.static.ole_info.name') },
        { prop: 'md5', label: 'MD5' },
        { prop: 'sha1', label: 'SHA1' },
        { prop: 'sha256', label: 'SHA256' },
        { prop: 'sha512', label: 'SHA512' }
      ],
      officeSummaryKeyMap: [
        { prop: 'title', label: t('report.static.office_summary._title') },
        { prop: 'subject', label: t('report.static.office_summary.subject') },
        { prop: 'keywords', label: t('report.static.office_summary.keywords') },
        { prop: 'category', label: t('report.static.office_summary.category') },
        { prop: 'comments', label: t('report.static.office_summary.comments') },
        { prop: 'author', label: t('report.static.office_summary.author') },
        { prop: 'last_modified_by', label: t('report.static.office_summary.last_modified_by') },
        { prop: 'revision', label: t('report.static.office_summary.revision') },
        { prop: 'version', label: t('report.static.office_summary.version') },
        { prop: 'created', label: t('report.static.office_summary.created') },
        { prop: 'modified', label: t('report.static.office_summary.modified') },
        { prop: 'content_status', label: t('report.static.office_summary.content_status') },
        { prop: 'language', label: t('report.static.office_summary.language') }
      ],
      lnkKeyMap: [
        { prop: 'basepath', label: t('report.static.lnk.basepath') },
        { prop: 'relapath', label: t('report.static.lnk.relapath') },
        { prop: 'cmdline', label: t('report.static.lnk.cmdline') },
        { prop: 'icon', label: t('report.static.lnk.icon') },
        { prop: 'description', label: t('report.static.lnk.description') }
      ],
      elfHeaderKeyMap: [
        { prop: 'class', label: 'CLASS' },
        { prop: 'data', label: t('report.static.elf_info.data') },
        { prop: 'ei_version', label: t('report.static.elf_info.ei_version') },
        { prop: 'os_abi', label: 'OS ABI' },
        { prop: 'type', label: t('report.static.elf_info.type') },
        { prop: 'machine', label: t('report.static.elf_info.machine') },
        { prop: 'version', label: t('report.static.elf_info.version') },
        { prop: 'number_of_program_headers', label: t('report.static.elf_info.number_of_program_headers') },
        { prop: 'number_of_section_headers', label: t('report.static.elf_info.number_of_section_headers') },
        { prop: 'entry_point_address', label: t('report.static.elf_info.entry_point_address') }
      ],
      type: 'static',
      buttonList: [
        {
          label: t('report.static.content.tab_data'),
          value: 'static'
        },
        {
          label: t('report.static.content.tab_string'),
          value: 'string'
        }
      ],
      isFixed: false,
      emailExtend: false,
      importExtend: false,
      epsExtend: false,
      swfExtend: false
    }
  },
  computed: {
    ...mapGetters(['gid']),
    // $t() {
    //   return (v) => {
    //     return t(`report.static.${v}`)
    //   }
    // },
    detail() {
      return this.staticinfo.static
    },
    infoData() {
      const map = this.taskInfo.category === 'url' ? this.urlKeyMap : this.fileKeyMap
      return this.handleTableData(map, {
        ...this.target,
        name: this.taskInfo ? this.taskInfo.filename || this.taskInfo.url : this.target.file.name
      })
    },
    strings() {
      if (this.isEmpty(this.staticinfo.strings)) {
        return { hasData: false, data: [] }
      } else {
        if ('hasData' in this.staticinfo.strings) {
          return { hasData: this.staticinfo.strings.hasData, data: this.staticinfo.strings.data }
        } else {
          return { hasData: !this.isEmpty(this.staticinfo.strings), data: this.staticinfo.strings }
        }
      }
    },
    target() {
      return { ...this.staticinfo.target, size: `${this.staticinfo.target.size} ${t('suffix.byte')}` }
    },
    fileContent() {
      return this.staticinfo.target.hex_to_array
    },
    permission() {
      return {
        columnData: [
          {
            prop: 'permission',
            label: t('report.static.apk.label'),
            align: 'left'
          }
        ],
        tableData:
          this.detail.apk && this.detail.apk?.permissions?.map((item) => ({ permission: item })),
        tableProps: {
          displayLines: 5,
          showHeader: false
        }
      }
    },
    peinfo() {
      return this.detail.peinfo
    },
    archive_info() {
      return this.detail.archive_info
    },
    pe_versioninfo() {
      if (!this.detail.pe_versioninfo) {
        return []
      }
      return this.detail.pe_versioninfo
    },
    pe_sections() {
      return {
        columnData: [
          {
            prop: 'name',
            label: t('report.static.pe_sections.name'),
            align: 'left'
          },
          {
            prop: 'virtual_address',
            label: t('report.static.pe_sections.virtual_address'),
            align: 'left'
          },
          {
            prop: 'virtual_size',
            label: t('report.static.pe_sections.virtual_size'),
            align: 'left'
          },
          {
            prop: 'size_of_data',
            label: t('report.static.pe_sections.size_of_data'),
            align: 'left'
          },
          {
            prop: 'entropy',
            label: t('report.static.pe_sections.entropy'),
            align: 'left'
          }
        ],
        tableData: this.detail.pe_sections,
        tableProps: {
          displayLines: 5
        }
      }
    },
    pe_resources() {
      return {
        columnData: [
          {
            prop: 'name',
            label: t('report.static.pe_resources.name'),
            align: 'left'
          },
          {
            prop: 'offset',
            label: t('report.static.pe_resources.offset'),
            align: 'left'
          },
          {
            prop: 'size',
            label: t('report.static.pe_resources.size'),
            align: 'left'
          },
          {
            prop: 'language',
            label: t('report.static.pe_resources.language'),
            align: 'left'
          },
          {
            prop: 'sublanguage',
            label: t('report.static.pe_resources.sublanguage'),
            align: 'left'
          },
          {
            prop: 'filetype',
            label: t('report.static.pe_resources.filetype'),
            align: 'left'
          }
        ],
        tableData: this.detail.pe_resources,
        tableProps: {
          displayLines: 5
        }
      }
    },
    pe_imports() {
      return this.detail.pe_imports
    },
    pe_exports() {
      return {
        columnData: [
          {
            prop: 'ordinal',
            label: t('report.static.pe_exports.ordinal'),
            align: 'left'
          },
          {
            prop: 'address',
            label: t('report.static.pe_exports.address'),
            align: 'left'
          },
          {
            prop: 'name',
            label: t('report.static.pe_exports.name'),
            align: 'left'
          }
        ],
        tableData: this.detail.pe_exports,
        tableProps: {
          displayLines: 5
        }
      }
    },
    elf_section() {
      return {
        columnData: [
          {
            prop: 'name',
            label: t('report.static.elf_sections.name'),
            align: 'left'
          },
          {
            prop: 'addr',
            label: t('report.static.elf_sections.addr'),
            align: 'left'
          },
          {
            prop: 'size',
            label: t('report.static.elf_sections.size'),
            align: 'left'
          },
          {
            prop: 'type',
            label: t('report.static.elf_sections.type'),
            align: 'left'
          }
        ],
        tableData: this.detail.elf?.section_headers,
        tableProps: {
          displayLines: 5
        }
      }
    },
    elf_program() {
      return {
        columnData: [
          {
            prop: 'flags',
            label: t('report.static.elf_program.flags'),
            align: 'left'
          },
          {
            prop: 'addr',
            label: t('report.static.elf_program.addr'),
            align: 'left'
          },
          {
            prop: 'size',
            label: t('report.static.elf_program.size'),
            align: 'left'
          },
          {
            prop: 'type',
            label: t('report.static.elf_program.type'),
            align: 'left'
          }
        ],
        tableData: this.detail.elf?.program_headers,
        tableProps: {
          displayLines: 5
        }
      }
    },
    elf_symbol() {
      return {
        columnData: [
          {
            prop: 'ndx_name',
            label: t('report.static.elf_symbol.ndx_name'),
            align: 'left'
          },
          {
            prop: 'value',
            label: t('report.static.elf_symbol.value'),
            align: 'left'
          },
          {
            prop: 'bind',
            label: t('report.static.elf_symbol.bind'),
            align: 'left'
          },
          {
            prop: 'type',
            label: t('report.static.elf_symbol.type'),
            align: 'left'
          }
        ],
        tableData: this.detail.elf?.symbol_tables,
        tableProps: {
          displayLines: 5
        }
      }
    },
    elf_relocation(tableData) {
      return (tableData) => {
        return {
          columnData: [
            {
              prop: 'name',
              label: t('report.static.elf_relocations.name'),
              align: 'left'
            },
            {
              prop: 'offset',
              label: t('report.static.elf_relocations.offset'),
              align: 'left'
            },
            {
              prop: 'value',
              label: t('report.static.elf_relocations.value'),
              align: 'left'
            },
            {
              prop: 'info',
              label: t('report.static.elf_relocations.info'),
              align: 'left'
            },
            {
              prop: 'type',
              label: t('report.static.elf_relocations.type'),
              align: 'left'
            }
          ],
          tableData,
          tableProps: {
            displayLines: 3
          }
        }
      }
    },
    signature() {
      return {
        columnData: [
          {
            prop: 'common_name',
            label: t('report.static.signature.common_name'),
            align: 'left'
          },
          {
            prop: 'serial_number',
            label: t('report.static.signature.serial_number'),
            align: 'left'
          },
          {
            prop: 'sha1',
            label: t('report.static.signature.sha1'),
            align: 'left'
          },
          {
            prop: 'md5',
            label: t('report.static.signature.md5'),
            align: 'left'
          }
        ],
        tableData: this.detail.signature,
        tableProps: {
          displayLines: 5
        }
      }
    },
    email() {
      return this.detail.email
    },
    email_body() {
      return this?.email
    },
    vba_info() {
      const vba = this?.detail?.office?.vba?.data
      let collapseData = vba?.macros ? vba.macros.map((item) => ({ ...item, childrenData: item })) : []
      if (this.isEmpty(vba)) {
        collapseData = []
      }
      return {
        collapseData,
        columnData: [
          {
            prop: 'filename',
            label: t('report.static.vba_info.filename'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'stream',
            label: t('report.static.vba_info.stream'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          }
        ]
      }
    },
    eps_info() {
      const eps = this?.detail?.office?.eps?.data
      const collapseData = eps.length ? eps.map((item) => ({ ...item, childrenData: item })) : []
      return {
        collapseData,
        columnData: [
          {
            prop: 'eps_name',
            label: t('report.static.eps_info.eps_name'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'md5',
            label: 'MD5',
            width: undefined,
            align: 'left',
            headAlign: 'left'
          }
        ]
      }
    },
    ole_info() {
      return this?.detail?.office?.embed_ole
    },
    ole_header() {
      const ole = this?.detail?.office?.embed_ole?.data
      const collapseData = ole.length ? ole.map((item) => ({ ...item, childrenData: item })) : []
      return {
        collapseData,
        columnData: [
          {
            prop: 'name',
            label: t('report.static.ole_info.name'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'md5',
            label: 'MD5',
            width: undefined,
            align: 'left',
            headAlign: 'left'
          }
        ]
      }
    },
    swf_header() {
      const swf = this?.detail?.office?.embed_swf?.data
      const collapseData = swf.length ? swf.map((item) => ({ ...item, childrenData: item })) : []
      return {
        collapseData,
        columnData: [
          {
            prop: 'name',
            label: t('report.static.swf_info.name'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'md5',
            label: 'MD5',
            width: undefined,
            align: 'left',
            headAlign: 'left'
          }
        ]
      }
    },
    swf_info() {
      return this?.detail?.office?.embed_swf
    },
    office_summary() {
      const officeSummary = this?.detail?.office?.metadata || {}
      if (Object.values(officeSummary).every(item => !item)) {
        return {}
      } else {
        return officeSummary
      }
    },
    attachments() {
      return this.isEmpty(this.email_body?.data)
        ? {
            columnData: [],
            tableData: []
          }
        : {
            columnData: [
              {
                prop: 'md5',
                label: 'MD5',
                align: 'left',
                width: '380'
              },
              {
                prop: 'filename',
                label: t('report.static.email.attachment_name'),
                align: 'left'
              }
            ],
            tableData: !this.isEmpty(this.email_body?.data) && this.email_body?.data.attachments,
            tableProps: {
              displayLines: 5
            }
          }
    }
  },
  methods: {
    formatChildrenData(list) {
      const columnData = Object.keys(list[0]).map((item) => ({
        prop: item,
        label: item
      }))
      return {
        columnData,
        tableData: list,
        tableProps: {
          isHorizontal: true
        }
      }
    },
    isEmpty(target) {
      const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
      switch (type) {
        case 'object':
          return Object.keys(target).length === 0
        case 'array':
          return target.length === 0
        case 'string':
          return target === ''
        case 'undefined':
        case 'null':
          return true
        default:
          return false
      }
    },
    handleTableData(keyMap, list, keyWidth = '120px') {
      const columnData = keyMap.map((item) => {
        return {
          prop: item.prop,
          label: item.label || item.prop,
          copy: item.copy,
          format: item.format ? item.format : undefined
        }
      })
      const tableData = list
      return { columnData, tableData, keyWidth }
    },
    handleTypeChange(type) {
      this.type = type
    },
    importsTableConvert(list) {
      if (typeof list[0] === 'string') {
        return {
          columnData: [
            {
              prop: 'sig',
              label: '签名',
              align: 'left'
            }
          ],
          tableData: list.map((item) => ({ sig: item })),
          tableProps: {
            displayLines: 5,
            showHeader: false
          }
        }
      } else {
        return {
          columnData: [
            {
              prop: 'address_name',
              label: '地址_名称',
              align: 'left'
            }
          ],
          tableData: list.map((item) => ({
            address_name: `<span>${item.address}</span><a class="import_link" target='_blank' href='https://docs.microsoft.com/en-us/search/?terms=${item.name}'>${item.name}</a>`
          })),
          tableProps: {
            displayLines: 5,
            showHeader: false
          }
        }
      }
    },
    changeHeight() {
      this.$nextTick(() => {
        this.importExtend = this.$refs.importBody.$el.getBoundingClientRect().height > 160
      })
    }
  },
  mounted() {
    // todo
    // for (let i = 0; i < 1; i++) {
    //   this.detail.office.embed_ole.data = [...this.detail.office.embed_ole.data, ...this.detail.office.embed_ole.data]
    // }

    if (this?.detail?.email?.has_permission && !this.isEmpty(this?.email_body?.data?.body)) {
      this.$nextTick(() => {
        this.emailExtend = this.$refs.emailBody.getBoundingClientRect().height > 160
      })
    }
    if (this?.detail?.office?.eps?.has_permission && !this.isEmpty(this?.detail?.office?.eps?.data)) {
      this.$nextTick(() => {
        const epsBody = document.querySelector('.eps-table')
        this.epsExtend = epsBody.getBoundingClientRect().height > 250
      })
    }
    if (this?.detail?.office?.embed_swf?.has_permission && !this.isEmpty(this?.swf_info?.data)) {
      this.$nextTick(() => {
        const swfBody = document.querySelector('.swf-table')
        this.swfExtend = swfBody.getBoundingClientRect().height > 280
      })
    }
    if (!this.isEmpty(this.pe_imports)) {
      this.$nextTick(() => {
        this.importExtend = this.$refs.importBody.$el.getBoundingClientRect().height > 160
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.static-info-wrap {
  .button-wrap {
    margin-bottom: 16px;
  }

  .static-wrap {
    .peinfo-row {
      .label {
        display: inline-block;
        width: 100px;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }

      &+.peinfo-row {
        margin-top: 12px;
      }
    }

    .email-info-wrap {
      display: flex;
      padding: 10px 22px 10px 0;

      .email-label {
        color: var(--color-text-3);
        font-size: 14px;
        width: 100px;
        flex-shrink: 0;
      }

      .email-table {
        flex: 1;
      }
    }

    .versionInfo-table {
      border-collapse: collapse;
      color: var(--color-text-2);
      font-size: 14px;
      width: 100%;

      tr {
        height: 40px;
        th {
          width: 130px;
          box-sizing: border-box;
          font-weight: 400;
          text-align: left;
          color: var(--color-text-3);
        }

        th, td {
          // padding: 0 20px;
          line-height: 40px;
          word-break: break-all;
        }
      }
    }

    .import-wrap {
      :deep(.import_link) {
        margin-left: 8px;
        text-decoration: none;
      }

      :deep(.import_link:link), :deep(.import_link:visited) {
        color: var(--color-priamry);
      }
    }

    .vba-table {
      .vba-table-pre {
        padding: 20px;
        margin: 0;
        color: var(--color-text-2);
        font-size: 14px;
      }
    }

    .eps-table {
      margin-top: 20px;
      &-content {
        padding: 16px 0;
        border-bottom: 1px solid var(--color-border-1);
      }
    }

    .email-body, .swf-wrap, .dde-wrap {
      color: var(--color-text-2);
      font-size: 14px;
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  .strings-wrap {
    width: 100%;

    .code-pre {
      overflow-y: auto;
      padding: 0 16px;
      height: 436px;
      background: var(--color-bg-3);
      border: 1px solid var(--color-border-2);
      border-radius: 2px;
      color: var( --color-unkown-text);
      // color: var( --color-unkown-text);
      pre {
        line-height: 20px;
        white-space: pre-line;
        word-break: break-all;
        font-size: 14px;
        // font-family: 'CONSOLA';
      }
    }
  }
}
</style>
