<template>
  <li>
    <div>
      <span v-if="isFolder" @click="toggle" class="prefix"><img :src="getIcon(open)" alt=""></span>
      <a v-if="process.pid === -1">{{ process.process_name }}(failed)</a>
      <a
        v-else
        :class="['progress-item', {active: currentPid === process.pid}]"
        @click.prevent="changeTab(process.pid)"
      >
        <img :src="recursive(process)" alt="">
        <span>{{process.process_name || '-'}}</span>
        <span>|</span>
        <span>{{ process.pid }}</span>
      </a>
    </div>
    <div class="command" :class="{vline: vlineShow}">
      <code>{{ process.command_line || '-' }}</code>
    </div>
    <ul v-if="isFolder" v-show="open">
        <tree_process v-for="child in process.children" :key="child.pid" :process="child"></tree_process>
    </ul>
  </li>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from 'vue'
import emitter from '@/app/emitter.js'
import legendList from 'utils/lib/treeIconList'
import other from 'assets/img/tree/other.svg'

const props = defineProps({
  process: {
    default: () => {
      return {}
    }
  }
})

const open = ref(true)
const currentPid = ref('')

const isFolder = computed(() => {
  return props.process.children && props.process.children.length
})
const vlineShow = computed(() => {
  return open.value && isFolder.value
})

onMounted(() => {
  emitter.on('handleTreeNodeClick', (pid) => {
    currentPid.value = props.process.pid === pid ? pid : ''
  })
})

const changeTab = (pid) => {
  emitter.emit('handleTreeNodeClick', pid)
}
const toggle = () => {
  if (isFolder.value) {
    open.value = !open.value
  }
}
const getIcon = (open) => {
  const type = open ? 'minus' : 'plus'
  return require(`@/assets/img/svg/${type}.svg`)
}
const recursive = (obj) => {
  const { process_path } = obj
  let _path = ''
  _path = obj.path ? obj.path : other
  // eslint-disable-next-line camelcase
  if (process_path) {
    for (let k = 0; k < legendList.length; k++) {
      if (process_path.match(legendList[k].reg)) {
        _path = legendList[k].path
        break
      }
    }
  }
  return _path
}
</script>

<style lang="scss" scoped>
.command {
  margin-left: 10px;
  padding-left: 10px;
}
.vline {
  background: url("~assets/img/vline.svg") repeat-y;
}
.prefix {
  cursor: pointer;
  color: #888888;
  img {
    position: relative;
    margin-right: 4px;
    top: 2px;
  }
}
.progress-item {
  cursor: pointer;
  text-decoration: none;
  padding: 2px;
  img {
    margin: 0 4px;
    width: 14px;
    height: 14px;
    position: relative;
    top: 2px;
    filter: var(--svg-filter);
  }
  span:nth-child(3) {
    margin: 0 8px;
    color: var(--color-text-2);
  }
  span:nth-child(4) {
    color: var(--color-text-2);
  }
}
.progress-item.active {
  padding: 2px;
  color: var(--color-text-1);
  border: 1px solid #fdd465;
  background: var(--color-bg-3);
}
code {
  // color: #2ECC71;
  color: var(--color-text-2);
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.7rem;
  margin: 20px;
  word-break: break-all;
}
</style>
