<template>
  <Loading v-if="loading" :loading="loading" />
  <div class="report-graph-chart" v-else>
    <Authorization
      :gid="gid"
      authModule="file_content"
      :hasPayment="has_permission"
      :showPoint="false"
      :needGid="3"
      fullScreen
    >
      <template v-slot:payment>
        <div id="container" class="container" v-if="!isEmpty(edges) && has_permission"></div>
        <sand-no-data isFullscreen v-else></sand-no-data>
      </template>
    </Authorization>
    <!-- <div class="loading" v-if="loading">关联分析查询中...</div> -->
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import sandNoData from '@/components/report/sandNoData'
import G6 from '@antv/g6'
import './base-node'
import tooltip from './tooltip'
// import menu from './menu'
// import topo from './topo.json'
import { getTaskReportGraph, getTaskReportGraphExpand } from 'services/report'
import { isDark, watchSwitchTheme } from 'app/style'

const route = useRoute()
const store = useStore()
const taskId = route.params.taskId
const gid = store.getters.gid

const loading = ref(false)

let nodes = []
const edges = ref([])
const has_permission = ref(true)

let graph

const menu = new G6.Menu({
  offsetX: 6,
  offsetY: 10,
  itemTypes: ['node'],
  shouldBegin(e) {
    const { data } = e.item.getModel()
    return data && data.taskid && data.is_public === 1
  },
  getContent(e, graph) {
    const outDiv = document.createElement('div')
    outDiv.innerHTML = `<div class="graph-menu">
    <div class="graph-menu-item" data-action="expand">关联分析</div>
    <div class="graph-menu-item" data-action="report">查看报告</div>
  </div>`
    return outDiv
  },
  handleMenuClick(target, item, graph) {
    const action = target.getAttribute('data-action')
    const { data } = item.getModel()
    if (action === 'expand') {
      expandChartData(data.taskid)
    } else if (action === 'report') {
      window.open(`/report/${data.taskid}`, '_blank')
    }
  }
})

const initChart = async () => {
  await initChartData()
  loading.value = false
  if (edges.value.length && has_permission.value) {
    nextTick(() => renderChart())
  }
}

const initChartData = async () => {
  try {
    loading.value = true
    const data = await getTaskReportGraph(taskId)
    // const data = topo.data
    nodes = data.nodes
    edges.value = data.edges
    has_permission.value = data.has_permission
    // edges.value = []
  } finally {
    loading.value = false
  }
}

const expandChartData = async (id) => {
  try {
    loading.value = true
    const data = await getTaskReportGraphExpand(id)
    nodes = [...nodes, ...data.nodes]
    edges.value = [...edges.value, ...data.edges]
    nextTick(() => renderChart())
  } finally {
    loading.value = false
  }
}

const renderChart = () => {
  const container = document.getElementById('container')
  if (!container) return
  const width = container.scrollWidth
  const height = container.scrollHeight || 600
  graph = new G6.Graph({
    container: 'container',
    width,
    height,
    plugins: [tooltip, menu],
    layout: {
      type: 'radial',
      unitRadius: 200,
      preventOverlap: true,
      nodeSize: 36,
      maxPreventOverlapIteration: 8000,
      focusNode: 0
    },
    defaultNode: {
      type: 'base-node',
      labelCfg: {
        position: 'bottom'
      }
    },
    defaultEdge: {
      type: 'line',
      color: isDark() ? '#5A5E6D' : '#8a9099',
      style: {
        endArrow: true
      }
    },
    modes: {
      default: ['drag-canvas', 'drag-node']
    }
  })
  graph.data({
    nodes: nodes.map(({ id, data }) => {
      return { id: '' + id, data }
    }),
    edges: edges.value.map(({ source, target }) => {
      return { source: '' + source, target: '' + target }
    })
  })
  graph.render()
}

function isEmpty(target) {
  const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
  switch (type) {
    case 'object':
      return Object.keys(target).length === 0
    case 'array':
      return target.length === 0
    case 'string':
      return target === ''
    case 'undefined':
    case 'null':
      return true
    default:
      return false
  }
}

onMounted(() => {
  initChart()
  watchSwitchTheme(() => {
    initChart()
  })
})
</script>

<style lang="scss" scoped>
.report-graph-chart {
  position: relative;
  height: calc(100% - 60px);
  .loading {
    position: absolute;
    width: 100%;
    top: 20px;
    text-align: center;
  }
  .auth-container__file_content {
    height: 100%;
  }
  .container {
    height: 100%;
  }
}
</style>
