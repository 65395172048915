<template>
  <div class="behavior-events-wrap">
    <template v-if="events.length > 0">
      <div class="behavior-event-item" v-for="item in events" :key="`${item}${Math.random()}`">
        <div class="behavior-event-left">
          <div class="event-name-wrap">
            <span class="event-name">{{ getEventName(item.event.event) }}</span>
          </div>
          <div class="event-time-wrap">
            <span class="event-time">+{{ item.event.sec }}s</span>
          </div>
        </div>
        <div class="behavior-event-right">
          <div class="behavior-event-right_mainkey" v-if="item.event.mainkey">
            {{ item.event.mainkey }}
          </div>
          <div
            class="behavior-event-right_event"
            v-for="(arg, key) in item.event.arguments"
            :key="key"
          >
            <div class="behavior-event-right_event-label">{{ getArguments(key) }}</div>
            <template v-if="getFlagsType(item.event.flags[key]) === 'array'">
              <div class="behavior-event-right_event-value behavior-event-right_event-marginTop">
                <span
                  v-for="flag in getValue(item.event.flags[key], item.event.arguments[key])"
                  :key="flag"
                  class="value-item"
                  :title="item.event.arguments[key]"
                  >{{ flag }}</span
                >
              </div>
            </template>
            <template v-else>
              <div class="behavior-event-right_event-value">
                <template
                  v-if="
                    getFlagsType(getValue(item.event.flags[key], item.event.arguments[key])) ===
                      'array' ||
                      getFlagsType(getValue(item.event.flags[key], item.event.arguments[key])) ===
                        'object'
                  "
                >
                  <ListExtend :list="getValue(item.event.flags[key], item.event.arguments[key])" />
                </template>
                <template v-else>
                  <div>{{ getValue(item.event.flags[key], item.event.arguments[key]) }}</div>
                </template>
              </div>
            </template>
          </div>
          <div class="behavior-event-right_return">
            <div class="behavior-event-right_return-left">
              <span class="behavior-event-right_return-left-label">{{$t('report.analyse.behavior.return_value')}}</span>
              <span class="behavior-event-right_return-left-value">{{
                item.event.return_value
              }}</span>
            </div>
            <div class="behavior-event-right_return-right">
              <span>{{ item.event.status === 1 ? $t('success') : $t('failed') }}</span>
              <span v-if="item.event.repeated" class="behavior-event-right_return-right-repeat"
                >{{$t('report.analyse.behavior.repeat', [item.event.repeated])}}</span
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// 行为判定中 行为为 event 类型组件
import eventDesc from '@/assets/markdown/event_description.json'
import ListExtend from '@/components/common/ListExtend'
import { isZh } from 'app/i18n'
export default {
  name: 'BehaviorEvents',
  components: {
    ListExtend
  },
  props: {
    events: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    eventList() {
      return eventDesc.event
    },
    argumentsList() {
      return eventDesc.argument
    }
  },
  methods: {
    getFlagsType(obj) {
      const type = Object.prototype.toString
        .call(obj)
        .slice(8, -1)
        .toLowerCase()
      return type
    },
    getValue(item, item2) {
      return item || item2
    },
    getArguments(index) {
      return (this.argumentsList[index] && (isZh() ? this.argumentsList[index].zhcn : this.argumentsList[index].enus)) || index
    },
    getEventName(event) {
      const eventName = this.eventList[event]
      return isZh() ? (eventName?.zhcn || event) : (eventName?.enus || event)
    }
  }
}
</script>

<style lang="scss" scoped>
.behavior-events-wrap  {
  // @include ft_color(color-main-font);
  color: var(--color-text-2);
  font-size: 14px;
  .behavior-event-item  {
    display: flex;
    &+.behavior-event-item  {
      border-top: 1px dashed var(--color-border-1);
      margin-top: 30px;
      padding-top: 20px;
    }
    .behavior-event-left  {
      box-sizing: border-box;
      padding-right: 30px;
      flex-shrink: 0;
      width: 220px;
      .event-name-wrap  {
        margin-bottom: 10px;
        .event-name  {
          background: var(--color-bg-3);
          color: var(--color-text-1);
          border-radius: 2px;
          line-height: 20px;
          padding: 5px 16px;
          display: inline-block;
        }
      }
      .event-time-wrap  {
        .event-time  {
        }
      }
    }
    .behavior-event-right  {
      flex: 1;
      .behavior-event-right_mainkey  {
        margin-top: 4px;
        margin-bottom: 16px;
        word-break: break-all;
      }
      .behavior-event-right_event  {
        padding-bottom: 10px;
      }
      .behavior-event-right_event  {
        width: 100%;
        border-left: 1px solid var(--color-border-2);
        display: flex;
        padding-left: 20px;
        box-sizing: border-box;
        &-label  {
          width: 115px;
          flex-shrink: 0;
        }
        &-marginTop {
          margin-top: -6px;
        }
        &-value  {
          flex: 1;
          word-break: break-all;
          // padding-left: 10px;
          .value-item  {
            line-height: 16px;
            display: inline-block;
            background: var(--color-bg-5);
            padding: 3px 10px;
            border-radius: 2px;
            font-size: 12px;
            margin-top: 8px;
            &:not(:last-child)  {
              margin-right: 8px;
            }
          }
        }
      }
      .behavior-event-right_return  {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-left: 1px solid var(--color-border-2);
        padding-left: 20px;
        box-sizing: border-box;
        .behavior-event-right_return-left  {
          &-label  {
            display: inline-block;
            width: 125px;
            flex-shrink: 0;
          }
        }
        .behavior-event-right_return-right  {
          &-repeat  {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

[data-theme='light'] {
  .behavior-event-right_event-value {
    .value-item {
      background: #F2F3F5 !important;
      color: #242933 !important;
    }
  }
}
</style>
