<template>
  <div ref="tabList" :class="{
    'tabList': true,
    'tabList--visible': isVisible,
  }">
    <div class="tabList-box" ref="tabListBox">
      <div class="tabList-item" ref="tabListItem" :class="{
        'tabList-item--high': item.severity >= 3,
        'tabList-item--middle': item.severity === 2,
        'tabList-item--low': item.severity <= 1,
        'tabList-item--link': isLink
      }" v-for="(item, index) in tagListData" :key="index" @click="linkTo(item)">
        {{$isZh() ? (item.tagzhcn || item.name) : item.tagenus}}
      </div>
      <div v-show="showMore" style="display: inline-block">
        <upgrade-dialog v-model:show="isShowUpgrade" type="B" :showPoint="isLogin" :showNormal="!isLogin">
          <version-table></version-table>
        </upgrade-dialog>
        <div @click="showUpgrade" class="tabList-item tabList-item--more">
          {{showMore}}+
          <svg-icon name="fufei"></svg-icon>
        </div>
      </div>
    </div>
    <div :class="{
      'tabList-open': true,
      'tabList-open--active': isVisible}"
      @click="changOverflow"
      v-if="showTriangle" >
      <img class="tabList-img" src="@/assets/img/svg/triangle3.svg">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import versionTable from './versionTable'
import upgradeDialog from './upgradeDialog.vue'
export default {
  components: {
    versionTable,
    upgradeDialog
  },
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Number,
      default: 0
    },
    data: {
      type: Array
    }
  },
  data () {
    return {
      showTriangle: false,
      isVisible: false,
      isShowUpgrade: false
    }
  },
  computed: {
    ...mapGetters([
      'isLogin'
    ]),
    tagListData () {
      if (!this.data) {
        return []
      }
      if (typeof this.data[0] === 'string') {
        return this.data.map(item => ({
          tagzhcn: item,
          severity: 1
        }))
      }
      return this.data
    }
  },
  watch: {
    data () {
      this.setOverflow()
    }
  },
  mounted () {
    this.setOverflow()
  },
  methods: {
    setOverflow () {
      if (this.tagListData.length > 0) {
        this.getListHeight()
        // this.itemHeight = this.$refs.tabListItem[0].offsetHeight
        this.itemHeight = 20
        this.showTriangle = (this.$refs.tabListBox.offsetHeight > (this.itemHeight + 1) * 1.5)
        this.$refs.tabList.style.height = `${this.itemHeight + 2}px`
      }
    },
    changOverflow () {
      this.isVisible = !this.isVisible
      this.getListHeight()
    },
    getListHeight () {
      if (this.isVisible) {
        this.$refs.tabList.style.height = `${this.$refs.tabListBox.offsetHeight - 2}px`
      } else {
        this.$refs.tabList.style.height = `${this.itemHeight + 2}px`
      }
    },
    linkTo (data) {
      if (this.isLink) {
        this.$emitter.emit('onTagListClick', data)
      }
    },
    showUpgrade () {
      this.isShowUpgrade = true
    }
  }
}
</script>
<style lang="scss" scoped>

[data-theme=light]{
  .tabList-img {
     &:hover  {
      filter: brightness(.5);
    }
  }
  .tabList-item--more {
     &:hover  {
      filter: brightness(.5);
    }
  }
  .tabList {
    &-item {
      color: #389e0d;
      &--high {
        color: #cf1322;
        background: #fff1f0;
        border-color: #ffa39e;
      }
      &--middle {
        color: #d48806;
        background: #fffbe6;
        border-color: #ffd666;
      }
      &--low {
        color: #389e0d;
        background: #eff8ee;
        border-color: #bce0b4;
      }
      &--more {
        height: 20px;
        background: linear-gradient(28deg, #f3d8aa 0%, #eabb6d 100%);
        border: 1px solid transparent;
        color: #5e3b17 !important;
        cursor: pointer;
        &:hover  {
          filter: brightness(1.1);
        }
      }
    }
  }
}

.tabList {
  overflow: hidden;
  padding-right: 22px;
  box-sizing: border-box;
  position: relative;
  transition: 0.1s linear;
  font-size: 0;
  &-open {
    width: 22px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    transition: transform 0.3s;
    &--active {
      transform: rotate(180deg);
    }
  }
  &-img {
    padding: 5px;
    &:hover  {
      filter: brightness(--svg-filter2);
    }
  }
  &-box {
    width: 100%;
  }
  &-item {
    display: inline-block;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 2px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: #3eae89;
    box-sizing: border-box;
    border: 1px solid;
    line-height: 12px;
    &--high {
      color: #ff6a63;
      background: #ff76701a;
    }
    &--middle {
      color: #d9b12f;
      background: #c2aa6f1a;
    }
    &--low {
      color: #3eae89;
      background: #3eae891a;
    }
    &--link {
      cursor: pointer;
    }
    &--more {
      height: 20px;
      border: 1px solid #EBCE9C;
      color: #EBCE9C !important;
      cursor: pointer;
      &:hover  {
        filter: brightness(1.4);
      }
    }
  }
}
</style>
