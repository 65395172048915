<template>
  <div class="sand-text">
    <div :class="['sand-text__body', {isOpen: isOpen}, {extend: extend && !isOpen}]" :style="{maxHeight:(extend && !isOpen) ? height + 'px' : ''}" ref="body">
      <slot></slot>
    </div>
    <div
      class="sand-text__footer"
      ref="footer"
      v-if="extend"
      :class="{
        'sand-newTable__footer--fixed': footerFixed && isOpen,
        'sand-newTable__footer--open': isOpen
        }"
      @click="switchDisplayStatus"
    >
      <svg-icon class="sand-newTable__footer--triangle" name="zhankai"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SandText',
  props: {
    text: {
      type: String
    },
    extend: {
      type: Boolean
    },
    height: {
      type: Number,
      default: 160
    }
  },
  data () {
    return {
      isOpen: false,
      footerFixed: false
    }
  },
  computed: {
    reportState () {
      return this.$store.getters.getReportState
    }
  },
  watch: {

  },
  methods: {
    switchDisplayStatus () {
      this.isOpen = !this.isOpen
      this.$nextTick(this.handleScroll)
    },
    handleScroll () {
      const dom = document.querySelector('.contentWrapper')
      const bol = dom.scrollTop + dom.clientHeight === dom.scrollHeight
      if (bol) {
        this.$nextTick(() => {
          dom.scrollTop = dom.scrollTop - 1
        })
      }
      const body = this.$refs.body
      const footer = this.$refs.footer
      // 视口高度
      const clientHeight = document.documentElement.clientHeight
      // body到视口区域的top
      if (!footer || !body || !this.isOpen) {
        return
      }
      const bodyCLientTop = body.getBoundingClientRect().top
      const bodyCLientLeft = body.getBoundingClientRect().left
      // 视口的底部一定要在内容上面
      // 内容上边到视口的距离小于视口  &&  内容的底部到视口的距离大于视口
      // 内容都要加上底部的高度
      if (clientHeight >= bodyCLientTop + footer.offsetHeight && clientHeight <= bodyCLientTop + body.offsetHeight + footer.offsetHeight) {
        footer.style.bottom = '25px'
        footer.style.left = `${bodyCLientLeft}px`
        this.footerFixed = true
        footer.style.width = `${body.offsetWidth + 2}px`
      } else if (clientHeight >= bodyCLientTop && clientHeight < bodyCLientTop + footer.offsetHeight) {
        footer.style.bottom = `${clientHeight - bodyCLientTop - footer.offsetHeight}px`
        footer.style.left = `${bodyCLientLeft}px`
        this.footerFixed = true
        footer.style.width = `${body.offsetWidth + 2}px`
      } else {
        this.footerFixed = false
        footer.style.width = '100%'
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.handleScroll, true)
    if (this.reportState) {
      this.isOpen = true
    }
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
.sand-text  {
  .sand-text__body  {
    overflow: hidden;
    &.extend  {
      max-height: 160px;
    }
    &.isOpen  {
      height: unset;
    }
  }
  .sand-text__footer  {
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px dashed var(--color-text-3);
    color: var(--color-text-3);
    cursor: pointer;
    svg {
      font-size: 20px;
    }
    & > span  {
      display: inline-block;
      vertical-align: middle;
    }
    &:hover  {
      // @include ft_color(color-main-white, false);
      // @include bg_color(color-collapse-header-bg-hover, false);
      & .sand-newTable__footer--triangle  {
        // filter: brightness(--svg-filter2);
      }
    }
    &--fixed  {
      position: fixed;
      bottom: 0;
    }
    &--triangle  {
      margin-left: 8px;
      transition: transform 0.3s;
      vertical-align: middle;
    }
    &--open  {
      & .sand-newTable__footer--triangle  {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
