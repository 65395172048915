<template>
  <div class="sand-tableCollapse" v-if="collapseData.length !== 0 && columnData.length !== 0">
    <div class="sand-tableCollapse__tableHeader">
      <div
        class="sand-tableCollapse__tableHeader--item"
        v-for="item in columnData"
        :key="item.prop"
        :style="`
          width: ${item.width ? getWidth(item.width) : '0px'};
          flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
          text-align: ${item.headAlign ? item.headAlign : item.align ? item.align : 'left'};
        `"
      >
        <span class="sand-tableCollapse__tableHeader--text">{{ item.label }}</span>
      </div>
    </div>
    <el-collapse v-model="collapseVal" accordion>
      <el-collapse-item
        :class="{ 'sand-tableCollapse__stripe': tableProps.stripe }"
        v-for="(collapse, index) in collapseData"
        :key="index"
        :name="index"
      >
        <template #title>
          <div class="sand-tableCollapse__header">
            <div
              class="sand-tableCollapse__header--item"
              v-for="item in columnData"
              :key="item.prop"
              :style="`
                width: ${item.width ? getWidth(item.width) : '0px'};
                flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
                text-align: ${item.align ? item.align : 'left'};
              `"
            >
              <div @click.stop="toTi(collapse)" v-if="item.type === 'ti'">
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
                  <svg-icon name="sousuoti"></svg-icon>
                </el-tooltip>
              </div>
              <div v-if="item.type === 'detect'" @click.stop="redect(collapse)">
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('dialog.detect.label')" placement="top">
                  <svg-icon name="tijiaojiance"></svg-icon>
                </el-tooltip>
              </div>
              <div
                v-if="item.type === 'download'"
                :class="[
                  'sand-tableCollapse__header--download',
                  { disable: !collapse.download_url || !(gid >= 2 || (scoreInfo && scoreInfo[computed_type] > 0)) }
                ]"
                @click.stop="downloadsample(collapse)"
              >
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('download.action')" placement="top">
                  <svg-icon name="xiazai1"></svg-icon>
                </el-tooltip>
              </div>
              <span
                v-else
                class="sand-tableCollapse__header--text"
                v-html="collapse[item.prop]"
              ></span>
            </div>
          </div>
        </template>
        <slot :childrenData="collapse.childrenData"></slot>
      </el-collapse-item>
    </el-collapse>
    <UpgradeDialog
      v-model:show="isShowUpgrade"
      :type="upgradeType"
      :showPoint="isLogin"
      :showNormal="!isLogin"
    />
  </div>
  <sand-no-data :isFullscreen="!isPdfPage" v-else></sand-no-data>
</template>
<script>
import sandNoData from './sandNoData'
import { downloadSample } from 'services/report'
import { download } from 'utils/tools'
import { mapGetters, mapState } from 'vuex'
import UpgradeDialog from '@/components/common/upgradeDialog'
import { t } from 'app/i18n'

export default {
  components: {
    UpgradeDialog,
    sandNoData
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        // collapseData: [{
        //   date: '微软软件',
        //   ddd: 'dddddd',
        //   childrenData: {}
        // }, {
        //   date: '微软软件',
        //   ddd: 'dddddd',
        //   childrenData: {}
        // }],
        // columnData: [{
        //   prop: 'date',
        //   label: 'date',
        //   width: '100px',
        //   col: undefined,
        //   align: 'center'
        // }, {
        //   prop: 'ddd',
        //   label: 'ddd',
        //   width: undefined,
        //   col: undefined,
        //   align: 'center'
        // }, {
        //   prop: 'aaa',
        //   label: 'aaa',
        //   width: undefined,
        //   col: undefined,
        //   align: 'center',
        //   type: 'download'
        // }],
        // tableProps: {
        //   stripe: true
        // }
      })
    },
    isPdfPage: {
      type: Boolean,
      default: false
    },
    upgradeType: {
      type: String,
      default: 'C2'
    }
  },
  data() {
    return {
      collapseVal: [],
      isShowUpgrade: false
    }
  },
  computed: {
    ...mapGetters(['gid', 'isLogin']),
    ...mapState({
      scoreInfo: (state) => state.user.scoreInfo,
      tiToken: (state) => state.user.tiToken
    }),
    reportState() {
      return this.$store.getters.getReportState
    },
    collapseData() {
      if (this.data) {
        return this.data.collapseData || []
      }
      return []
    },
    columnData() {
      if (this.data) {
        return this.data.columnData || []
      }
      return []
    },
    tableProps() {
      if (this.data) {
        return this.data.tableProps || {}
      }
      return {}
    },
    computed_type() {
      // return this.upgradeType === 'C4' ? 'memdumps_down_last' : 'release_down_last'
      return 'download_last'
    }
  },
  watch: {
    reportState() {
      this.setReportStatic()
    }
  },
  mounted() {
    this.setReportStatic()
  },
  methods: {
    redect(collapse) {
      this.$emit('detect', collapse)
    },
    toTi(data) {
      window.open(`https://ti.360.net/#/detailpage/searchresult?query=${data.md5}&rand=${Math.random()}&sign=${this.tiToken}&from=ata`)
      // window.open(`https://10.146.250.137/#/detailpage/searchresult?query=${data.md5}&rand=${Math.random()}&sign=${this.tiToken}&from=ata`)
    },
    setReportStatic() {
      if (this.reportState) {
        this.open()
      }
    },
    open() {
      this.collapseVal = []
      this.collapseData.forEach((_, index) => {
        this.collapseVal.push(index)
      })
    },
    close() {
      this.collapseVal = []
    },
    async downloadsample(file) {
      if (!file.download_url) return
      if (this.gid >= 2 || (this.scoreInfo && this.scoreInfo[this.computed_type] > 0)) {
        await downloadSample(file.download_url.replace('/api/v1', ''))
          .then((res) => {
            const filename = res.headers['content-disposition'].match(/filename=(.+)/)[1]
            download({
              content: res.data,
              type: 'application/octet-stream;',
              filename
            })
            this.$store.dispatch('user/getScoreInfo')
          })
          .catch((err) => {
            if (err.message !== 'Request failed with status code 429') {
              this.$store.commit('app/showMessage', {
                type: 'error',
                message: t('download.not_found'),
                duration: 1000
              })
            } else {
              if (this.gid === 1) {
                this.isShowUpgrade = true
              }
            }
          })
      } else {
        this.isShowUpgrade = true
      }
    },
    getWidth(data) {
      if (data.indexOf('px') > 0) {
        return data
      }
      return data + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>
.sand-tableCollapse {
  /* 折叠面板项的每一项 */
  & :deep(.el-collapse-item) {
    // border 1px solid;
    // bd_color(color-collapse-border)
    border-bottom: 0;

    &:last-child {
      // border 1px solid;
      // bd_color(color-collapse-border)
    }

    &.sand-tableCollapse__stripe:nth-child(even) .el-collapse-item__header {
      // bg_color(color-table-collapse-even);

      &:hover {
        // bg_color(color-table-collapse-hover);
      }
    }
  }

  /* 边框都不需要 */
  & :deep(.el-collapse), & :deep(.el-collapse-item__header), & :deep(.el-collapse-item__wrap) {
    border: 0;
    background: transparent;
  }

  /* 右边的小箭头 */
  & :deep(.el-collapse-item__arrow.is-active) {
    // transform: rotate(180deg);
  }

  & :deep(.el-collapse-item__arrow) {
    height: 13px;
    margin-right: 20px;
    color: var(--color-text-3);
    &.is-active {
      color: var(--color-text-1);
    }
  }

  & :deep(.el-icon-arrow-right:before) {
    content: url('./../../assets/img/svg/triangle.svg');
  }

  /* 折叠面板项 */
  & :deep(.el-collapse-item__header) {
    height: 47px;
    font-weight: normal;
    // bg_color(color-table-collapse-odd);
    color: var(--color-text-2);
    &.is-active {
      .sand-tableCollapse__header .sand-tableCollapse__header--item {
        .sand-tableCollapse__header--text, svg {
          color: var(--color-text-1);
        }
      }
    }

    &:hover {
      // bg_color(color-table-collapse-hover);

      & .el-icon-arrow-right:before {
        filter: brightness(--svg-filter2);
      }
    }
  }

  /* 折叠面板项 */
  & .sand-tableCollapse__header {
    width: 100%;
    display: flex;
    padding: 0 15px;
    height: 40px;

    &--item {
      flex-shrink: 0;
      // word-break:break-all;
      // word-wrap:break-word;
      align-items: center;
      display: flex;
      // padding-right: 15px;
    }

    &--text {
      width: 100%;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--color-text-2);
    }
    svg {
      font-size: 16px;
      color: var(--color-text-2);
      &:hover {
        color: var(--color-text-1);
      }
    }

    &--download {
      height: 22px;
      // width: 92px;
      background: transparent;
      // border 1px solid;
      // bd_color(color-sand-border)
      // border-radius: 4px;
      line-height: 22px;
      text-align: center;
      font-size: 14px;
      color: var(--color-text-2);
      font-weight: bold;
      svg {
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }

      &.disable {
        // filter: grayscale(1);
        color: var(--color-text-3);
        cursor: not-allowed;
      }
    }
  }

  /* 表头 */
  & .sand-tableCollapse__tableHeader {
    display: flex;
    height: 48px;
    line-height: 48px;
    color: var(--color-text-2);
    background-color: var(--color-bg-3);
    padding: 0 67px 0 15px;
    // border-bottom: 1px solid var(--color-border-2);
    &--item {
      flex-shrink: 0;
      word-break: break-all;
      word-wrap: break-word;
      display: flex;
      align-items: center;
    }

    &--text {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
