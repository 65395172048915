<template>
  <div>
    <!-- 远程操控 -->
    <Vnc :key="keyCom" v-if="progress >= 60 && isShowVnc" ref="vncComponent" :taskId="props.taskId" :status="vncStatus" @changeVncStatus="changeVncStatus" />
    <transition leave-active-class="animate__fadeOutUp">
      <div v-show="show" class="wrapper animate__animated">
        <div class="stage" v-for="(item, index) in step" :key="index">
          <div class="node">
            <svg-icon name="dengdai" v-if="item.value > progress && !(item.byPass && onlyIncludeStatic)" color="#8994a6"></svg-icon>
            <svg-icon v-if="(item.value < progress) && !(item.byPass && onlyIncludeStatic)" name="yiwancheng1" color="#00ab7a"></svg-icon>
            <svg-icon v-if="item.byPass && onlyIncludeStatic" color="#8994a6" name="tiaoguo"></svg-icon>
            <div class="active" v-if="item.value === progress">
              <div class="circle">
                <div class="dot"></div>
              </div>
            </div>
            <p :style="{color:textStyle(item.value)}">{{item.label}}</p>
            <div class="btns-wrap" v-if="index === 3 && taskInfo.os === 'windows' && isOwner && !isProduct" :class="[statusKv[status].length >= 7 && 'long']">
              <el-button :type="progress >= 60 ? 'primary' : 'plain'" @click="showVnc" :disabled="progress !== 60 || status === 'close' || status === 'stop'">{{ statusKv[status] }}</el-button>
            </div>
          </div>
          <span v-if="index !== 5" class="line" :class="{'done':item.value < progress}"></span>
        </div>
      </div>
    </transition>
    <upgrade-dialog
      v-model:show="isShowUpgrade"
      :showPoint="false"
      :showNormal="false"
      showTeam
      type="A"
    ></upgrade-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, defineProps, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { getColor } from 'app/style'
import { t } from 'app/i18n'
import Vnc from './Vnc'
import UpgradeDialog from '@/components/common/upgradeDialog'
import { useState } from '@/hooks/useVuex'

const statusKv = {
  prepare: t('other.vnc.prepare'),
  runing: t('other.vnc.runing'),
  close: t('other.vnc.closed'),
  stop: t('other.vnc.end'),
  connected: 'VNC链接中'
}

const store = useStore()
const status = ref('prepare')
const isShowVnc = ref(false)
const vncStatus = ref('runing')
const gid = store.getters.gid
const isShowUpgrade = ref(false)
const vncComponent = ref()
const keyCom = ref(0)
const props = defineProps({
  progress: {
    type: Number,
    default: 0
  },
  onlyIncludeStatic: {
    type: Boolean
  },
  taskId: {
    type: Number,
    default: 0
  },
  progressStatus: {
    type: String,
    default: ''
  },
  taskInfo: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
// watch(() => props.taskId, () => {
//   isShowVnc.value = false
// })
const isProduct = window.location.host === 'ata.360.net'

const { userInfo } = useState('user', ['userInfo'])

const { progress, onlyIncludeStatic, taskInfo, taskId } = toRefs(props)
const show = ref(true)

const isOwner = computed(() => {
  return taskInfo.value.owner_id === userInfo.value.user_id
})

onMounted(() => {
})

watchEffect(() => {
  if (progress.value === 100) {
    setTimeout(() => {
      show.value = false
    }, 1000)
  } else if (progress.value >= 70) {
    vncComponent.value && vncComponent.value.disconnectVnc()
    vncStatus.value = 'stop'
    status.value = 'stop'
  } else {
    show.value = true
  }
})

function textStyle(value) {
  return progress.value < value ? getColor('--color-text-3') : progress.value === value ? getColor('--color-text-1') : getColor('--color-text-2')
}

function changeVncStatus(sta) {
  if (sta === 'reconnect') {
    reconnect()
  } else if (sta === 'cancel') {
    status.value = 'prepare'
    vncStatus.value = 'cancel'
  } else if (sta === 'disconnect') {
    status.value = 'close'
    vncStatus.value = 'cancel'
  } else if (sta === 'fail') {
    vncStatus.value = 'fail'
    status.value = 'close'
  } else if (sta === 'runing') {
    vncStatus.value = 'runing'
    status.value = 'connected'
  } else if (sta === 'end') {
    vncStatus.value = 'stop'
    status.value = 'stop'
  }
}

function showVnc() {
  if (gid > 2) {
    if (status.value === 'prepare') {
      isShowVnc.value = true
      status.value = 'runing'
      vncStatus.value = 'connecting'
      setTimeout(() => {
        vncComponent.value && vncComponent.value.connectVnc(taskId.value)
      }, 500)
    }
  } else {
    isShowUpgrade.value = true
  }
}

const reconnect = () => {
  keyCom.value += 1
  setTimeout(() => {
    status.value = 'runing'
    vncStatus.value = 'connecting'
    vncComponent.value && vncComponent.value.connectVnc(taskId.value)
  }, 1000)
}

const step = [
  {
    value: 20,
    label: t('taskstatus.pending')
  },
  {
    value: 30,
    label: t('taskstatus.scanning')
  },
  {
    value: 40,
    label: t('taskstatus.dynamic'),
    byPass: true
  },
  {
    value: 60,
    label: t('taskstatus.running'),
    byPass: true
  },
  {
    value: 70,
    label: t('taskstatus.analyse'),
    byPass: true
  },
  {
    value: 80,
    label: t('taskstatus.reported')
  }
]
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  padding: 30px 90px;
  height: 110px;
}
.btns-wrap {
  text-align: center;
  margin-top: 50px;
  position: absolute;
  width: 60px;
  left: -24px;
  :deep(.el-button) {
    font-size: 12px;
    padding: 0;
    text-align: center;
    width: 80px;
  }
  &.long {
    left: -35px;
    :deep(.el-button) {
      width: 100px;
    }
  }
}
.stage {
  display: flex;
  // align-items: center;
  .node {
    position: relative;
    .active {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background: #00ab7a4d;
      display: flex;
      align-items: center;
      justify-content: center;
      .circle {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: #00ab7a;
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
          width: 3px;
          height: 3px;
          background: #fff;
          border-radius: 100%;
          position: relative;
          animation: dot 1s infinite linear alternate;
          animation-delay: .5s;
          &:after, &:before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            right: 6px;
            animation: dot 1s infinite alternate;
            animation-delay: 0s;
          }
          &:before {
            left: 6px;
            animation: dot 1s infinite alternate;
            animation-delay: 1s;
          }
        }
      }
    }
    svg {
      font-size: 34px;
    }
    p {
      position: absolute;
      width: 60px;
      left: -12px;
      color: var(--color-text-3);
    }
  }
  .line {
    display: inline-block;
    width: 97px;
    height: 1px;
    margin-top: 17px;
    background: #8994a6;
  }
  .done {
    background: #00ab7a;
  }
  @keyframes dot{
    from {opacity:1;}
    to {opacity:0.4;}
  }
}
</style>
