import * as d3 from 'd3'
import * as util from '@/utils/util'
import { isDark } from 'app/style'
import { t } from 'app/i18n'

class Tree {
  // eslint-disable-next-line space-before-function-paren
  constructor(opt) {
    this.el = opt.el
    this.data = opt.data
    this.svg = null
    this.root = null
    this.width = this.el.clientWidth
    this.height = this.el.clientHeight
    this.group = null
    this.link = null
    this.node = null
    this.timer = null
    this.x0 = Infinity
    this.x1 = -this.x0
    this.nodeWidth = 26
    this.padding = 16
    this.textWidth = 80
    this.tools = opt.tools
    this.legend = opt.legendList
    this.nodeEvent = null
    this.toolsEvent = null
    this.paddingLeft = 20
    this.rectHeight = 40
    this.rectWidth = 140
    this.legendLength = 2
    this.arrowActive = null
    // 合并参数
    util.extend(true, this, opt)
    this.render()
  }

  tree (data) {
    this.root = d3.hierarchy(data)
    this.root.dx = this.rectHeight + 2 + 5// 2为上下边框
    this.root.dy = 260
    return d3.tree().nodeSize([this.root.dx, this.root.dy])(this.root)
  }

  init () {
    this.zoom = d3.zoom().on('zoom', this.zoomed.bind(this))
    if (this.svg) d3.select(this.el).selectAll('svg').remove()
    this.svg = d3
      .select(this.el)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)

    this.svg.style('cursor', 'pointer')
      .html(
        `<defs> <marker id="arrow" markerWidth="10" markerHeight="10" refx="0" refy="3" orient="auto" markerUnits="strokeWidth"> <path d="M0,0 L0,6 L9,3 z" fill="${isDark() ? '#DFE2EB' : '#8a9099'}" /> </marker> </defs>`
      )
    this.root = this.tree(this.data)
    this.root.each(d => {
      if (d.x > this.x1) this.x1 = d.x
      if (d.x < this.x0) this.x0 = d.x
    })
    this.group = this.svg
      .append('g')
      .attr('font-size', '10')
      .attr('class', 'group')
      .attr(
        'transform',
        `translate(${this.root.dy / 3},${this.height / 2})`
      )
    this.svg.call(this.zoom).on('dblclick.zoom', null).on('wheel.zoom', null)
    this.arrowActive = this.legendArrow[0]
  }

  addLegend () {
    d3.select(this.el).select('.legend').remove()

    const legend = JSON.parse(JSON.stringify(this.legend))
    legend.length = this.legendLength

    const container = d3.select(this.el)
      .append('div')
      .attr('class', 'legend')

    container.append('div')
      .attr('class', 'legendList')
      .selectAll('p')
      .data(legend)
      .enter()
      .append('p')
      .html(
        d =>
          `<img style='margin-right:10px' src=${d.path} /> <span>${d.name}</span>`
      )
    container.append('img')
      .attr('class', 'arrow')
      .attr('src', this.arrowActive)
      .on('click', (d) => {
        if (this.legendLength === 2) {
          this.arrowActive = this.legendArrow[1]
          this.legendLength = this.legend.length
          this.addLegend()
        } else {
          this.arrowActive = this.legendArrow[0]
          this.legendLength = 2
          this.addLegend()
        }
      })
  }

  addScaleBtn () {
    const _that = this
    d3.select(this.el)
      .append('div')
      .attr('class', 'scaleBtn')
      .style('bottom', '16px')
      .selectAll('.icon_scale')
      .data(this.tools)
      .enter()
      .append('img')
      .style('margin-right', '10px')
      .attr('class', 'icon_scale')
      .attr('src', (d, i) => this.tools[i].path)
      .on('click', function (d) {
        _that.toolsEvent(d)
      })
      .on('mouseenter', function (d) {
        d3.selectAll('.icon_scale').attr('src', e => e.path)
        d3.select(this).attr('src', d.active)
        _that.showTip()
        _that.tooltip.style('padding', '5px 15px').style('border-radius', '0').append('div').html(`${d.label}`)
        _that.setTipPosition()
      })
      .on('mouseleave', function (d) {
        d3.selectAll('.icon_scale').attr('src', e => e.path)
        _that.mouseOut()
      })
  }

  addLinks () {
    this.group
      .append('g')
      .attr('fill', 'none')
      .attr('stroke', (isDark() ? '#DFE2EB' : '#8a9099'))
      .attr('stroke-opacity', 0.4)
      .attr('stroke-width', 1)
      .selectAll('path')
      .data(this.root.links())
      .join('path')
      .attr('stroke-dasharray', d => (d.target.data.drop ? 2 : 0))
      .attr(
        'd',
        d3
          .linkHorizontal()
          .source(d => {
            const y = d.source.parent
              ? d.source.y + this.rectWidth - 10
              : d.source.y + 60
            return [y, d.source.x]
          })
          .target(d => {
            return [d.target.y - this.paddingLeft, d.target.x]
          })
      )
      .attr('marker-end', 'url(#arrow)')
  }

  addNodes () {
    const _that = this
    const node = this.group
      .append('g')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-width', 2)
      .selectAll('g')
      .data(this.root.descendants())
      .join('g')
      .attr('transform', d => `translate(${d.y},${d.x})`)

    node.append('rect')
      .attr('fill', d => d.data.drop ? (isDark() ? '#3f4251' : '#e8eaed') : '#009066')
      .attr('stroke', d => d.data.active ? '#ffd666' : (isDark() ? '#2D3658' : '#e8eaed'))
      .attr('stroke-width', d => d.data.active ? '2' : '1')
      .attr('class', d => d.data.active ? `${d.data.name} _root layerRect` : `${d.data.name} layerRect`)
      .attr('x', -this.paddingLeft + 15)// +10是因为箭头的宽度为10
      .attr('y', -this.rectHeight / 2)
      .attr('width', d => {
        if (!d.parent) return
        return this.rectWidth
      })
      .attr('height', this.rectHeight)
      .attr('rx', 2)
      .attr('ry', 2)
    const map = {
      md5: t('report.behavior.relation.start.sample'),
      time: t('report.behavior.relation.start.time'),
      score: t('report.behavior.relation.start.score')
    }
    node
      .append('image')
      .attr('xlink:href', (d, i) => d.data.path)
      .attr('x', 10)
      .attr('y', -14)
      .attr('class', d => d.data.active ? `${d.data.name}image _rootImage` : `${d.data.name}image`)
      .attr('width', this.nodeWidth)
      .attr('filter', d => (d.data.drop || d.data.name === t('report.behavior.relation.start.info')) && !isDark() && 'brightness(0.3)')
      .attr('height', 26)
      .on('mouseenter', d => (d.parent ? null : this.mouseEnter(d, map)))
      .on('mouseout', this.mouseOut.bind(this))

    node
      .append('text')
      .attr('dy', '0.31em')
      .attr('x', d => !d.parent ? 0 : this.nodeWidth + this.paddingLeft)
      .attr('y', d => d.parent ? 0 : 26)
      // .attr('fill', '#EBECF3')
      .attr('fill', d => (d.data.drop || d.data.name === t('report.behavior.relation.start.info')) ? (isDark() ? '#EBECF3' : '#242933') : '#ffffff')
      .attr('text-anchor', 'start')
      .attr('font-size', '14')
      .text(d => {
        const name = d.parent ? d.data.name : d.data.children ? t('report.behavior.relation.start.label') : ''
        if (!name) return ''
        return name.length > 8 ? `${name.substring(0, 3)}...${name.substring(name.length - 3, name.length)}` : name
      })
      .raise()

    node.append('rect')
      .attr('fill-opacity', '0')
      .attr('x', -this.paddingLeft + 15)// +10是因为箭头的宽度为10
      .attr('y', -this.rectHeight / 2)
      .attr('width', d => {
        if (!d.parent) return
        return this.rectWidth
      })
      .attr('height', this.rectHeight)
      .attr('rx', 2)
      .attr('ry', 2)
      .on('click', function (d) {
        if (!d.parent) return
        nodeClick(d, _that, this)
      })
      .on('mouseenter', function (d) {
        d3.select(this).attr('stroke', '#ffd666').attr('stroke-width', 2)
        if (d.data.type || d.data._type) _that.showTip()
        if (d.data.drop) {
          _that.tooltip.style('padding', '11px 16px').style('border-radius', '0').append('div').html(`<span class='one-line'>${d.data.label}</span><span class='value'>${d.data.name}</span>`)
        } else {
          _that.tooltip.style('padding', '11px 16px').style('border-radius', '0').append('div').html(`<span class='label'>${t('report.behavior.relation.tooltip.process_id')}</span><span class='value'>${d.data.pid}</span><br /><span class='label'>${t('report.behavior.relation.tooltip.process_name')}</span><span class='value'>${d.data.process_name}</span><br /><span class='label'>${t('report.behavior.relation.tooltip.process_type')}</span><span class='value'>${d.data.type}</span>`)
        }
        _that.setTipPosition()
      })
      .on('mouseleave', function (d) {
        d3.select(this).attr('stroke', '#ffd666').attr('stroke-width', 0)
        _that.mouseOut()
      })

    function nodeClick (d, _that, that) {
      _that.svg.selectAll('.layerRect').attr('stroke', isDark() ? '#2D3658' : '#e8eaed').attr('stroke-width', 1).attr('fill', e => {
        e.data.active = false
        return e.data.drop ? (isDark() ? '#3f4251' : '#e8eaed') : '#009066'
      })
      d3.select(that.parentNode).select('.layerRect').attr('stroke', '#ffd666').attr('stroke-width', 2)
      return _that.nodeEvent(d)
    }
  }

  render () {
    this.init()
    this.addScaleBtn()
    this.addTooltip()
    this.addLinks()
    this.addNodes()
    this.addLegend()
  }

  mouseOut () {
    this.tooltip
      .transition()
      .duration(200)
      .style('display', 'none')
      .style('left', 0)
      .style('top', 0)
    this.tooltip.selectAll('*').remove()
  }

  mouseEnter (d, map) {
    const { data } = d
    this.showTip()
    this.tooltip.style('border-radius', '4px').append('p').html(data.name).style('margin', 0).style('font-weight', 'bold')

    let str = ''
    const keys = Object.keys(map)
    const div = this.tooltip.append('div').attr('class', 'info_container')
    keys.forEach(item => {
      str += `<span class='left'>${map[item]}</span><span class='right'>${data[item]}</span>`
      div.append('p').html(str)
      str = ''
    })
    this.setTipPosition()
  }

  showTip () {
    this.tooltip
      .transition()
      .duration(200)
      .style('display', 'block')
  }

  setTipPosition () {
    this.tooltip
      .style('left', d3.event.pageX + 10 + 'px')
      .style('top', d3.event.pageY + 5 + 'px')
  }

  zoomed () {
    const { transform } = d3.event
    this.group.attr(
      'transform',
      `translate(${this.root.dy / 3 + transform.x},${this.height / 2 +
      transform.y}) scale(${transform.k})`
    )
  }

  addTooltip () {
    [...document.querySelectorAll('.treeTooltip')].map(v => {
      v.remove()
    })
    d3.select('.treeTooltip').selectAll('*').remove()
    if (!this.tooltip) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'treeTooltip')
    }
  }

  distoryed () {
    this.svg || this.svg.remove()
    d3.selectAll('.treeTooltip').remove()
  }
}

export default Tree
