<template>
  <div class="ziplist-wrap">
    <div class="ziplist-header" @click="showChildren = !showChildren">
      <span class="dir-icon">
        <img v-if="showChildren" src="@/assets/img/svg/minus.svg" alt="" />
        <img v-else src="@/assets/img/svg/plus.svg" alt="" />
      </span>
      <span class="name">{{filename}}</span>
    </div>
    <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
    <DirComponent v-if="showChildren && resultData.length > 0" :list="item" v-for="item in resultData" :key="item.name"/>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue'
import DirComponent from './dirComponent'

const props = defineProps({
  archive_info: {
    type: Object,
    default () {
      return {}
    }
  },
  filename: {
    type: String
  }
})

const resultData = computed(() => {
  const result = []
  if (!props.archive_info.filelist || props.archive_info.filelist.length === 0) return []
  props.archive_info.filelist.forEach(item => {
    const arr = item.filename.split('/')
    createObj(result, arr, item)
  })
  return result
})

const showChildren = ref(true)

const createObj = (arr, dirlist, item) => {
  if (dirlist.length === 0) return
  if (!arr) return
  const dir = dirlist.shift()
  if (!arr.find(item => (item.name === dir))) {
    if (dir === '') return
    arr.push({ name: dir, detail: item, children: dirlist.length > 0 ? [] : null })
    // arr.sort(this.arrsort)
  }
  createObj(arr.find(item => item.name === dir).children, dirlist, item)
}
// const arrsort = (a, b) => {
//   if (!a.children) a.children = []
//   if (!b.children) b.children = []
//   return b.children.length - a.children.length
// }
</script>

<style lang="scss" scoped>
  .ziplist-wrap {
    .ziplist-header {
      color: var(--color-text-2);
      font-size: 14px;
      line-height: 20px;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      .dir-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
