const extend = (function () {
  var copyIsArray
  var toString = Object.prototype.toString
  var hasOwn = Object.prototype.hasOwnProperty
  var class2type = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Object]': 'object'
  }
  var type = function (obj) {
    return obj == null
      ? String(obj)
      : class2type[toString.call(obj)] || 'object'
  }
  var isWindow = function (obj) {
    return obj && typeof obj === 'object' && 'setInterval' in obj
  }
  var isArray =
    Array.isArray ||
    function (obj) {
      return type(obj) === 'array'
    }
  var isPlainObject = function (obj) {
    if (!obj || type(obj) !== 'object' || obj.nodeType || isWindow(obj)) {
      return false
    }

    if (
      obj.constructor &&
      !hasOwn.call(obj, 'constructor') &&
      !hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')
    ) {
      return false
    }

    var key
    for (key in obj) {
    }

    return key === undefined || hasOwn.call(obj, key)
  }
  var extend = function (deep, target, options) {
    for (var name in options) {
      var src = target[name]
      var copy = options[name]

      if (target === copy) {
        continue
      }

      if (
        deep &&
        copy &&
        (isPlainObject(copy) || (copyIsArray = isArray(copy)))
      ) {
        var clone = null
        if (copyIsArray) {
          copyIsArray = false
          clone = src && isArray(src) ? src : []
        } else {
          clone = src && isPlainObject(src) ? src : {}
        }

        target[name] = extend(deep, clone, copy)
      } else if (copy !== undefined) {
        target[name] = copy
      }
    }

    return target
  }

  return extend
})()

function uuid () {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '_'

  var uuid = s.join('')
  return uuid
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 是否保留最后一次
 * - `1` 停止后抛弃最后一次
 * - `2` 停止后执行最后一次
 */
function throttle (func, wait = 150, type = 2) {
  let previous = 0
  let timeout
  return function () {
    if (type === 1) {
      const now = Date.now()
      if (now - previous > wait) {
        func()
        previous = now
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null
          func()
        }, wait)
      }
    }
  }
}

/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate 是否立即执行
 * - `true` 表立即执行
 * - `false` 表非立即执行
 */
function debounce (func, wait = 150, immediate = false) {
  let timeout
  return function () {
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      var callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      if (callNow) func()
    } else {
      timeout = setTimeout(function () {
        func()
      }, wait)
    }
  }
}

export { extend, uuid, throttle, debounce }
