<template>
  <div class="version">
    <div class="version-header version-header version-tr">
      <div class="version-tr__title"></div>
      <div
        class="version-header__value version-tr__value"
        v-for="item in columnData"
        :key="item.prop"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="version-body version-tr" v-for="tr in tableData" :key="tr.label">
      <div class="version-tr__title">{{ tr.label }}</div>
      <div class="version-body__value version-tr__value" v-for="td in columnData" :key="td.prop">
        <el-icon v-show="tr[td.prop]" size="14px" color="#25b782"><Check /></el-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Check } from '@element-plus/icons-vue'
import { t } from 'app/i18n'

const columnData = [
  {
    prop: 'visitor',
    label: t('other.components.report.visitor')
  },
  {
    prop: 'free',
    // label: '免费版'
    label: t('price.free')
  },
  {
    prop: 'professional',
    // label: '专业版'
    label: t('price.speciality')
  },
  {
    prop: 'enterprise',
    // label: '专业增强版'
    label: t('price.professional')
  }
]
const tableData = [
  {
    // label: '普通标签',
    label: t('price.common_tag'),
    visitor: true,
    free: true,
    professional: true,
    enterprise: true
  },
  {
    // label: '恶意软件家族群',
    label: t('other.components.report.malwareGroup'),
    visitor: true,
    free: true,
    professional: true,
    enterprise: true
  },
  {
    // label: '普通漏洞利用',
    label: t('other.components.report.commonExploits'),
    visitor: false,
    free: false,
    professional: true,
    enterprise: true
  },
  {
    // label: '勒索病毒行为',
    label: t('other.components.report.ransomware'),
    visitor: false,
    free: false,
    professional: true,
    enterprise: true
  },
  {
    // label: '检测逃避行为',
    label: t('other.components.report.evasion'),
    visitor: false,
    free: false,
    professional: true,
    enterprise: true
  },
  {
    // label: '已知漏洞',
    label: t('other.components.report.known_vul'),
    visitor: false,
    free: false,
    professional: false,
    enterprise: true
  },
  {
    // label: '关键漏洞利用',
    label: t('other.components.report.key_vul'),
    visitor: false,
    free: false,
    professional: false,
    enterprise: true
  },
  {
    // label: 'APT 组织',
    label: t('other.components.report.apt'),
    visitor: false,
    free: false,
    professional: false,
    enterprise: true
  }
]
</script>
<style lang="scss" scoped>
[lang='en'] {
  .version-tr__title {
    width: 230px;
  }
  .version-tr__value {
    width: 160px;
  }
}
.version  {
  // width: 414px;
  font-size: 14px;
  color: var(--color-text-2);
  border-radius: 4px;
  &-header  {
    background: var(--color-bg-3);
    color: var(--color-text-1);
    border-bottom: 1px solid var(--color-border-2);
    &__value  {
      border-left: 1px solid transparent;
    }
  }
  &-body  {
    &__value  {
      // border-left: 1px dashed #394573;
    }
  }
  &-tr  {
    line-height: 30px;
    display: flex;
    border-bottom: 1px solid var(--color-border-2);
    &__title  {
      width: 130px;
      padding-left: 16px;
      box-sizing: border-box;
    }
    &__value  {
      width: 70px;
      text-align: center;
      & > img  {
        margin-bottom: -3px;
      }
    }
  }
}
</style>
