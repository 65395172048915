<template>
  <div>
    <Loading v-if="loading" :loading="loading" />
    <div v-else>
      <!-- 释放内存 -->
      <ReleaseMemory id="releasefile" v-if="releasefile" :releasefile="releasefile" />
      <div class="pagination-wrap" v-if="releasefile.dropped.length && releasefile.has_permission">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
          :page-size="pageSize"
          customTotal
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskReportMemory } from 'services/report'
import Pagination from '@/components/common/Pagination'
import ReleaseMemory from '../ReleaseMemory'
export default {
  name: 'release',
  components: {
    ReleaseMemory,
    Pagination
  },
  data() {
    return {
      releasefile: {},
      loading: true,
      currentPage: 1,
      totalSize: 0,
      pageSize: 50
    }
  },
  computed: {
    taskId() {
      return this.$route.params.taskId
    }
  },
  methods: {
    async init() {
      await getTaskReportMemory(this.taskId, {
        total: true
      }).then((res) => {
        this.totalSize = res.total
      })
      this.getList()
    },
    async getList() {
      await getTaskReportMemory(this.taskId, {
        page: this.currentPage,
        size: this.pageSize
      }).then((res) => {
        this.releasefile.dropped = res.memdumps
        this.releasefile.has_permission = res.has_permission
      })
      this.loading = false
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.getList()
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.getList()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.pagination-wrap {
  :deep(.el-pagination__sizes) {
    display: none;
  }
  :deep(.btn-prev) {
    display: none;
  }
  :deep(.el-pager) {
    display: none;
  }
  :deep(.btn-next) {
    display: none;
  }
}
</style>
