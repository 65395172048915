<template>
  <el-tabs v-if="data.length > 0" class="sand-elTab" :class="{'show_full--report':completed}" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :name="item.name" v-for="item in data" :key="item.name">
      <template #label>
        <span @mouseenter="hoverName = item.name" @mouseleave="hoverName = ''">
          <!-- <img v-if="item.img"
            :src="(activeName === item.name || hoverName === item.name) ? item.activeImg : item.img"> -->
          <span>{{$t(`report.report_tabs.${item.name}`)}}</span>
        </span>
      </template>
      <slot :name="item.name"></slot>
    </el-tab-pane>
    <el-tab-pane v-if="completed">
      <template #label>
        <span @click.stop="toReport" class="fullReport">{{$t('report.report_tabs.full_report')}}</span>
      </template>
    </el-tab-pane>
  </el-tabs>
  <sand-no-data v-else></sand-no-data>
</template>
<script>
import sandNoData from './sandNoData'
export default {
  components: {
    sandNoData
  },
  props: {
    data: {
      type: Array,
      default: () => [
      ]
    },
    taskid: {
      type: Number
    },
    completed: {
      type: Boolean
    }
  },
  data () {
    console.log(this.data)
    return {
      activeName: this.data.map(item => item.name).indexOf(this.$route.params.type) > -1 ? this.$route.params.type : (this.data[0] && this.data[0].name),
      hoverName: ''
    }
  },
  watch: {
    '$route'() {
      console.log(this.$route.params.type)
      this.activeName = this.$route.params.type || 'analyse'
    }
  },
  mounted () {
    this.$store.commit('CHANGE_REPORT_TAB', {
      tab: this.activeName
    })
  },
  methods: {
    handleClick (tab) {
      this.$store.commit('CHANGE_REPORT_TAB', {
        tab: tab.props.name
      })
      this.$emit('currentTab', tab.props.name)
    },
    toReport() {
      if (this.$route.name === 'taskstatus') {
        window.open(`/report/${this.taskid}`, '_blank')
      } else {
        this.$router.push({
          name: 'report', params: { taskId: this.taskid, type: 'analyse' }
          // name: 'refresh',
          // query: { taskId: this.taskid, type: 'analyse' }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1680px) {
  .sand-elTab{
   & :deep(.el-tabs__header) {
    .el-tabs__item  {
      width: 10% !important;
      font-size: 14px !important;
    }
   }
  }
}
.sand-elTab  {
  & :deep(.el-tabs__header) {
    margin: 0;
    & .el-tabs__active-bar  {
      height: 0px;
    }
    & .el-tabs__nav  {
      width: 100%;
      background: var(--color-bg-2);
    }
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    & .el-tabs__item  {
      width: 128px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      padding: 0;
      font-weight: 400;
      &:hover  {
        color: #00cc92;
      }
      &:focus.is-active.is-focus:not(:active)  {
        outline: none;
        box-shadow: none;
      }
      &.is-active  {
        // font-weight: 500;
        color: #00cc92;
        background-color: var(--detect-config-bg);
      }
      & > span  {
        display: block;
        & > span  {
          vertical-align: middle;
        }
        & > img  {
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }
  & :deep(.el-tabs__content)  {
    overflow: visible;
  }
}
.show_full--report {
   &:deep(.el-tabs__header) {
    & .el-tabs__item  {
      &:last-child {
        position: absolute;
        right: 0;
        font-size: 14px;
        width: 100px !important;
        color: #00cc92;
      }
    }
   }
}

[data-theme='light'] {
  .sand-elTab {
    & :deep(.el-tabs__header) {
      & .el-tabs__item {
        &.is-active {
          color: #00ab7a;
        }
        &:hover {
          color: #00ab7a;
        }
      }
    }
  }
}
</style>
