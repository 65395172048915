<template>
  <div class="event-sequence-wrap">
    <SandTab :data="tabData" :defaultName="defaultName" @change="handleTabChange">
      <div class="behavior-event-filter">
        <div>
          <SearchInput
            @keydown="getMainkey"
            class="filter-input"
            ref="searchInput"
            :key="eventTab"
          />
          <span style="padding-left: 10px;">
            <el-switch
              v-model="isDetail"
              :active-text="$t('report.behavior.events.detail')"
              :inactive-text="$t('report.behavior.events.simple')"
              active-color="#00ab7a"
              inactive-color="#00ab7a"
            >
            </el-switch>
          </span>
        </div>
        <div>
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="totalSize"
            :current_page="currentPage"
            :jumper="true"
            :hide_pageSize="true"
          />
        </div>
      </div>
      <template v-if="!events || events.length === 0">
        <div class="behavior-event-wrap">
          <sandNoData :text="$t('no_result_found')" />
        </div>
      </template>
      <template v-else>
        <div class="behavior-event-wrap" ref="eventWrap">
          <div
            class="behavior-event-item"
            v-for="item in events"
            :key="`${item}${Math.random()}`"
            :class="{'simple':!isDetail}"
          >
            <div class="behavior-event-left">
              <div class="event-name-wrap">
                <span class="event-name">{{
                  $isZh() ? ((eventList[item.event] && eventList[item.event].zhcn) || item.event) : ((eventList[item.event] && eventList[item.event].enus) || item.event)
                }}</span>
              </div>
              <div class="event-time-wrap" v-if="isDetail">
                <span class="event-time">+{{ item.sec }}s</span>
              </div>
            </div>
            <div class="behavior-event-right">
              <div class="behavior-event-right_mainkey" v-if="item.mainkey">
                {{ item.mainkey }}
              </div>
              <template v-if="isDetail">
                <div
                  class="behavior-event-right_event"
                  v-for="(arg, key) in item.arguments"
                  :key="key"
                >
                  <div class="behavior-event-right_event-label">{{ getArguments(key) }}</div>
                  <template
                    v-if="getFlagsType(item.flags[key]) === 'array' && item.flags[key].length > 0"
                  >
                    <div class="behavior-event-right_event-value behavior-event-right_event-marginTop">
                      <span
                        v-for="flag in getValue(item.flags[key], item.arguments[key])"
                        :key="flag"
                        class="value-item"
                        :title="item.arguments[key]"
                        >{{ flag }}</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div class="behavior-event-right_event-value">
                      <template
                        v-if="
                          getFlagsType(getValue(item.flags[key], item.arguments[key])) ===
                            'array' ||
                          getFlagsType(getValue(item.flags[key], item.arguments[key])) === 'object'
                        "
                      >
                        <ListExtend :list="getValue(item.flags[key], item.arguments[key])" />
                      </template>
                      <template v-else>
                        <div>{{ getValue(item.flags[key], item.arguments[key]) }}</div>
                      </template>
                    </div>
                  </template>
                </div>
                <div class="behavior-event-right_return">
                  <div class="behavior-event-right_return-left">
                    <span class="behavior-event-right_return-left-label">{{$t('report.analyse.behavior.return_value')}}</span>
                    <span class="behavior-event-right_return-left-value">{{
                      item.return_value
                    }}</span>
                  </div>
                  <div class="behavior-event-right_return-right">
                    <span>{{ item.status === 1 ? $t('success') : $t('failed') }}</span>
                    <span v-if="item.repeated" class="behavior-event-right_return-right-repeat"
                      >{{$t('report.analyse.behavior.repeat', [item.repeated])}}</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </SandTab>
  </div>
</template>

<script>
import SandNoData from '@/components/report/sandNoData'
import eventDesc from '@/assets/markdown/event_description.json'
import SandTab from '@/components/report/sandTab'
import Pagination from '@/components/common/Pagination'
import ListExtend from '@/components/common/ListExtend'
import { getTaskReportBehaviorEventsByPid } from 'services/report'
import { debounce } from 'lodash'
import { isZh } from 'app/i18n'
export default {
  name: 'EventSequence',
  props: {
    taskId: [Number, String],
    pid: [Number, String],
    currentItem: Object,
    eventsMap: Object,
    taskOS: {
      type: String,
      default: 'windows'
    }
  },
  components: {
    SandTab,
    SandNoData,
    ListExtend,
    Pagination
  },
  computed: {
    tabData() {
      const hasCategory = this.category.filter((categoryItem) =>
        this.currentItem.events_info.find((eventsItem) => eventsItem.key === categoryItem.name)
      )
      return hasCategory.map((item) => {
        return {
          label: isZh() ? item.zhcn : item.enus,
          name: item.name
        }
      })
    },
    eventData() {
      return {
        columnData: [
          {
            prop: 'pid',
            label: 'PID',
            align: 'left',
            width: '100px'
          },
          {
            prop: 'apicount',
            label: 'COUNT',
            align: 'left',
            width: '100px'
          },
          {
            prop: 'method',
            label: 'FUNCTION',
            align: 'left'
          },
          {
            prop: 'parameters',
            label: 'PARAMS',
            align: 'left'
          },
          {
            prop: 'return',
            label: 'RETURN',
            align: 'left'
          },
          {
            prop: 'context',
            label: 'CONTEXT',
            align: 'left'
          }
        ],
        tableData: this.events.map((item) => ({
          ...item,
          parameters: this.objectToStr(item.parameters),
          context: this.objectToStr(item.context),
          return: this.objectToStr(item.return)
        })),
        tableProps: {
          displayLines: 5
        }
      }
    }
  },
  data() {
    return {
      eventTab: this.taskOS === 'android' ? this.currentItem?.events_info[0]?.key : 'file',
      defaultName: this.taskOS === 'android' ? this.currentItem?.events_info[0]?.key : 'file',
      argumentsList: eventDesc.argument,
      eventList: eventDesc.event,
      category: eventDesc.category,
      events: [],
      mainkey: '',
      totalSize: 0,
      currentPage: 1,
      isDetail: true
    }
  },
  watch: {
    eventTab(v) {
      this.$refs.searchInput && this.$refs.searchInput.resetInput()
      this.getEventById()
    },
    pid() {
      this.currentPage = 1
      if (this.tabData.length) {
        if (this.eventTab === this.tabData[0].name) {
          this.getEventById()
        } else {
          this.handleTabChange(this.tabData[0].name)
        }
      }
    },
    mainkey() {
      this.getEventById()
    }
  },
  methods: {
    isEmpty(target) {
      const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
      switch (type) {
        case 'object':
        case 'array':
        case 'string':
          return Object.keys(target).length === 0
        case 'undefined':
        case 'null':
          return true
        default:
          return false
      }
    },
    handleTabChange(tab) {
      if (typeof tab === 'string') {
        this.currentPage = 1
        this.eventTab = tab
      }
    },
    handleSizeChange(size) {},
    handleCurrentChange(page) {
      this.currentPage = page
      this.getEventById()
    },
    async getEventById() {
      await getTaskReportBehaviorEventsByPid({
        taskid: this.taskId,
        pid: this.pid,
        eventType: this.eventTab,
        mainkey: this.mainkey,
        page: this.currentPage
      }).then((res) => {
        this.events = res.events && res.events[this.eventTab]
        this.totalSize = res.count
      })
      if (this.$refs.eventWrap) {
        document.querySelector('.behavior-event-wrap').scrollTo({ top: 0 })
      }
    },
    getMainkey(mainkey) {
      this.currentPage = 1
      this.mainkey = mainkey
    },
    getFlagsType(obj) {
      const type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
      return type
    },
    getValue(item, item2) {
      return this.isEmpty(item) ? (this.isEmpty(item2) ? '-' : item2) : item
    },
    getArguments(index) {
      return isZh() ? ((this.argumentsList[index] && this.argumentsList[index].zhcn) || index) : (this.argumentsList[index] && this.argumentsList[index].enus ? this.argumentsList[index].enus : index)
    },
    objectToStr(obj) {
      const type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
      if (type === 'object') {
        return (
          (obj &&
            Object.entries(obj)
              .map((item) => `<b>${item[0]}:</b> ${this.objectToStr(item[1])}`)
              .join('<br/>')) ||
          '-'
        )
      } else if (type === 'array') {
        if (obj.length) {
          return obj.join('<br />')
        } else {
          return '-'
        }
      } else {
        return obj || '-'
      }
    }
  },
  mounted() {
    this.getMainkey = debounce(this.getMainkey, 300)
    this.eventTab = this.tabData[0]?.name || 'file'
    this.getEventById()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.event-sequence-wrap {
  color: var(--color-text-2);
  font-size: 14px;
  margin-bottom: 8px;

  .behavior-event-filter {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .filter-input {
      width: 200px;
    }
  }
  .behavior-event-wrap {
    padding-top: 10px;
    border-top: 1px solid var(--color-border-2);
    max-height: 600px;
    @include scroll_bar();
    overflow: auto;
    // &:hover {
    //   overflow: auto;
    // }
    .simple {
      margin-top: 0px !important;
      padding-top: 0px !important;
      border: 0 !important;
    }

    .behavior-event-item {
      display: flex;

      &+.behavior-event-item {
        border-top: 1px dashed #3f4251;
        margin-top: 30px;
        padding-top: 20px;
      }
      .behavior-event-left {
        box-sizing: border-box;
        padding-right: 30px;
        flex-shrink: 0;
        width: 220px;

        .event-name-wrap {
          margin-bottom: 10px;

          .event-name {
            // border: 1px solid;
            border-radius: 2px;
            line-height: 20px;
            padding: 5px 16px;
            background: var(--color-bg-3);
            display: inline-block;
          }
        }

        .event-time-wrap {
          .event-time {
          }
        }
      }

      .behavior-event-right {
        flex: 1;

        .behavior-event-right_mainkey {
          margin-top: 4px;
          margin-bottom: 16px;
          word-break: break-all;
        }

        .behavior-event-right_event {
          padding-bottom: 10px;
        }

        .behavior-event-right_event {
          width: 100%;
          border-left: 1px solid var(--color-border-2);
          display: flex;
          padding-left: 20px;
          box-sizing: border-box;

          &-label {
            width: 125px;
            flex-shrink: 0;
          }
          &-marginTop {
            margin-top: -6px;
          }

          &-value {
            flex: 1;
            word-break: break-all;

            .value-item {
              line-height: 16px;
              display: inline-block;
              background: var(--color-bg-5);
              padding: 3px 10px;
              border-radius: 2px;
              font-size: 12px;
              margin-top: 8px;
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }
        }

        .behavior-event-right_return {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-left: 1px solid var(--color-border-2);
          padding-left: 20px;
          box-sizing: border-box;

          .behavior-event-right_return-left {
            &-label {
              display: inline-block;
              width: 125px;
              flex-shrink: 0;
            }
          }

          .behavior-event-right_return-right {
            &-repeat {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

[data-theme='light'] {
  .behavior-event-right_event-value {
    .value-item {
      background: #F2F3F5 !important;
      color: #242933 !important;
    }
  }
}
</style>
