<template>
  <div class="sand-tableCollapse" v-if="collapseData.length !== 0 && columnData.length !== 0">
    <div class="sand-tableCollapse__tableHeader" v-if="tableProps.showHeader !== false">
      <div
        class="sand-tableCollapse__tableHeader--item"
        v-for="item in columnData"
        :key="item.prop"
        :style="`
          width: ${item.width ? getWidth(item.width) : '0px'};
          flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
          text-align: ${item.headAlign ? item.headAlign : item.align ? item.align : 'left'};
        `"
      >
        <span class="sand-tableCollapse__tableHeader--text">{{ item.label }}</span>
      </div>
    </div>
    <el-collapse v-model="collapseVal" :accordion="accordion" @change="changeCollapse">
      <el-collapse-item
        :class="{ 'sand-tableCollapse__stripe': tableProps.stripe }"
        v-for="(collapse, index) in collapseData"
        :key="index"
        :name="index"
      >
        <template #title>
          <div class="sand-tableCollapse__header">
            <div
              class="sand-tableCollapse__header--item"
              v-for="item in columnData"
              :key="item.prop"
              :style="`
                width: ${item.width ? getWidth(item.width) : '0px'};
                flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
                text-align: ${item.align ? item.align : 'left'};
              `"
            >
              <div
                v-if="item.type === 'download'"
                :class="[
                  'sand-tableCollapse__header--download',
                  { disable: collapse.download_url }
                ]"
                @click.stop="downloadsample(collapse.download_url)"
              >
                <img src="./../../assets/img/svg/downloadSamples.svg" />
                <!-- 下载样本 -->
              </div>
              <span
                v-else
                class="sand-tableCollapse__header--text"
                v-html="collapse[item.prop]"
              ></span>
            </div>
          </div>
        </template>
        <slot :childrenData="collapse.childrenData"></slot>
      </el-collapse-item>
    </el-collapse>
  </div>
  <sand-no-data v-else></sand-no-data>
</template>
<script>
import sandNoData from './sandNoData'
export default {
  components: {
    sandNoData
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        // collapseData: [{
        //   date: '微软软件',
        //   ddd: 'dddddd',
        //   childrenData: {}
        // }, {
        //   date: '微软软件',
        //   ddd: 'dddddd',
        //   childrenData: {}
        // }],
        // columnData: [{
        //   prop: 'date',
        //   label: 'date',
        //   width: '100px',
        //   col: undefined,
        //   align: 'center'
        // }, {
        //   prop: 'ddd',
        //   label: 'ddd',
        //   width: undefined,
        //   col: undefined,
        //   align: 'center'
        // }, {
        //   prop: 'aaa',
        //   label: 'aaa',
        //   width: undefined,
        //   col: undefined,
        //   align: 'center',
        //   type: 'download'
        // }],
        // tableProps: {
        //   // stripe: true
        //   noHeader: false
        // }
      })
    },
    accordion: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collapseVal: []
    }
  },
  computed: {
    reportState() {
      return this.$store.getters.getReportState
    },
    collapseData() {
      if (this.data) {
        return this.data.collapseData || []
      }
      return []
    },
    columnData() {
      if (this.data) {
        return this.data.columnData || []
      }
      return []
    },
    tableProps() {
      if (this.data) {
        return this.data.tableProps || {}
      }
      return {}
    }
  },
  watch: {
    reportState() {
      this.setReportStatic()
    },
    data() {
      this.collapseVal = []
    }
  },
  mounted() {
    this.setReportStatic()
  },
  methods: {
    changeCollapse(index) {
      this.$emit('change', this.collapseData[index])
    },
    setReportStatic() {
      if (this.reportState) {
        this.open()
      }
    },
    open() {
      this.collapseVal = []
      this.collapseData.forEach((_, index) => {
        this.collapseVal.push(index)
      })
    },
    close() {
      this.collapseVal = []
    },
    downloadsample(url) {
      if (!url) return
      window.location.href = url
    },
    getWidth(data) {
      if (data.indexOf('px') > 0) {
        return data
      }
      return data + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>
.sand-tableCollapse {
  /* 折叠面板项的每一项 */
  & :deep(.el-collapse-item) {
    // border: 1px solid;
    // bd_color(color-collapse-border);

    &.sand-tableCollapse__stripe:nth-child(even) .el-collapse-item__header {
      // bg_color(color-table-collapse-even);

      &:hover {
        // bg_color(color-table-collapse-hover);
      }
    }
  }

  /* 边框都不需要 */
  & :deep(.el-collapse), & :deep(.el-collapse-item__header), & :deep(.el-collapse-item__wrap) {
    border: 0;
    background: transparent;
  }

  /* 右边的小箭头 */
  & :deep(.el-collapse-item__arrow.is-active) {
    // transform: rotate(180deg);
  }

  & :deep(.el-collapse-item__arrow) {
    height: 14px;
    width: 14px;
    // margin-right: 20px;
    &.is-active {
      color: var(--color-text-1);
    }
  }

  /* 折叠面板项 */
  & :deep(.el-collapse-item__header) {
    height: 48px;
    box-sizing: border-box;
    font-weight: normal;
    border-bottom: 1px solid var(--color-border-2);
    // bg_color(color-table-collapse-odd)
    background: transparent;
    color: var(--color-text-2);
    &:hover {
      // bg_color(color-table-collapse-hover)
      & .el-icon-arrow-right:before {
        filter: brightness(5);
      }
    }

    & > .el-icon-arrow-right:before {
      content: url('./../../assets/img/svg/triangle.svg');
    }
    &.is-active {
      color: var(--color-text-1);
    }
  }

  & :deep(.el-collapse-item__content) {
    padding: 5px 0;
    margin: 0 20px;
    // bd_color(color-collapse-content-bg);
    // border-top: 1px solid red;
  }

  /* 折叠面板项 */
  & .sand-tableCollapse__header {
    width: 100%;
    display: flex;
    padding: 0 15px;
    height: 40px;

    &--item {
      flex-shrink: 0;
      align-items: center;
      display: flex;
    }

    &--text {
      width: 100%;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--download {
      height: 22px;
      width: 92px;
      background: transparent;
      line-height: 22px;
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      color: var(--color-text-2);
      font-weight: bold;

      img {
        vertical-align: middle;
        padding-bottom: 1px;
      }

      &.disable {
        filter: grayscale(1);
        cursor: not-allowed;
      }
    }
  }

  /* 表头 */
  & .sand-tableCollapse__tableHeader {
    display: flex;
    height: 48px;
    color: var(--color-text-2);
    // bg_color(color-sand-title-bg);
    padding: 0 67px 0 15px;
    background-color: var(--color-bg-3);
    // bd_color(color-sand-border);
    // border-bottom: 0;

    &--item {
      flex-shrink: 0;
      word-break: break-all;
      word-wrap: break-word;
      display: flex;
      align-items: center;
    }

    &--text {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
