<template>
  <div class="imports-table-wrap" ref="bodyRef">
    <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
    <div v-if="tableData.length > 0" v-for="(item, index) in realTableData"
      :key="`${item.name}-${index}`"
      :class="['import-item', {active: currentDll === `${item.name}-${index}`}]"
    >
      <div class="import-table-header">
        <span class="plus-minus"
          @click="handleClick(item, index)"
        >
          <img :src="getIcon(item, index)" alt="">
        </span>
        <span class="dll-title">{{item.name}}</span>
      </div>
      <div class="import-table-content" v-if="currentDll === `${item.name}-${index}`">
          <slot :childrenData="item.entries"></slot>
      </div>
    </div>
    <div
      ref="footerRef"
      v-if="displayLines && displayLines < tableData.length"
      class="footer"
      :class="{
        'sand-newTable__footer--fixed': footerFixed && isOpen,
        'sand-newTable__footer--open': isOpen
        }"
      @click="switchDisplayStatus">
        <svg-icon class="sand-newTable__footer--triangle" name="zhankai"></svg-icon>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, nextTick, computed, toRefs } from 'vue'

const props = defineProps({
  tableData: {
    type: Array,
    default () {
      return []
    }
  },
  displayLines: {
    type: Number,
    default: 3
  }
})
const { tableData, displayLines } = toRefs(props)
const emit = defineEmits(['changeHeight'])
const currentDll = ref('')
const isOpen = ref(false)
const footerFixed = ref(false)
const bodyRef = ref(null)
const footerRef = ref(null)

const getIcon = (item, index) => {
  const type = currentDll.value === `${item.name}-${index}` ? 'minus' : 'plus'
  return require(`@/assets/img/svg/${type}.svg`)
}

const realTableData = computed(() => {
  if (displayLines.value && !isOpen.value) {
    return tableData.value.slice(0, displayLines.value)
  }
  return tableData.value
})

const handleClick = (item, index) => {
  currentDll.value = currentDll.value === `${item.name}-${index}` ? '' : `${item.name}-${index}`
  emit('changeHeight')
}

const switchDisplayStatus = () => {
  isOpen.value = !isOpen.value
  nextTick(handleScroll)
}
const handleScroll = () => {
  const body = bodyRef.value
  const footer = footerRef.value
  // 视口高度
  const clientHeight = document.documentElement.clientHeight
  // body到视口区域的top
  if (!footer || !body || isOpen.value) {
    return
  }
  const bodyCLientTop = body.getBoundingClientRect().top
  const bodyCLientLeft = body.getBoundingClientRect().left
  // 视口的底部一定要在内容上面
  // 内容上边到视口的距离小于视口  &&  内容的底部到视口的距离大于视口
  // 内容都要加上底部的高度
  if (clientHeight >= bodyCLientTop + footer.offsetHeight && clientHeight <= bodyCLientTop + body.offsetHeight + footer.offsetHeight) {
    footer.style.bottom = '25px'
    footer.style.left = `${bodyCLientLeft}px`
    footerFixed.value = true
    footer.style.width = `${body.offsetWidth + 2}px`
  } else if (clientHeight >= bodyCLientTop && clientHeight < bodyCLientTop + footer.offsetHeight) {
    footer.style.bottom = `${clientHeight - bodyCLientTop - footer.offsetHeight}px`
    footer.style.left = `${bodyCLientLeft}px`
    footerFixed.value = true
    footer.style.width = `${body.offsetWidth + 2}px`
  } else {
    footerFixed.value = false
    footer.style.width = '100%'
  }
}
</script>

<style lang="scss" scoped>
  .imports-table-wrap {
    position: relative;
    .import-item {
      &.active {
        .import-table-header {
          .dll-title {
            color: var(--color-text-1);
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .import-table-header {
        display: flex;
        align-items: center;
        .plus-minus {
          display: inline-block;
          width: 18px;
          height: 18px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .dll-title {
          margin-left: 18px;
          color: var(--color-text-2);
          font-size: 16px;
          line-height: 22px;
        }
      }
      .import-table-content {
        background: var(--color-bg-3);
        border-left: 2px solid var(--color-border-2);
        margin-left: 10px;
        margin-top: 10px;
        // padding: 8px 28px;
        line-height: 20px;
        .import-table-item {
          height: 26px;
          line-height: 26px;
          font-size: 14px;
          &-address {
            color: var(--color-text-3);
            margin-right: 16px;
          }
          &-name {
            color: var(--color-text-2);
            cursor: pointer;
          }
        }
      }
    }
    .footer {
      height: 40px;
      line-height: 40px;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px dashed var(--color-text-3);
      color: var(--color-text-3);
      cursor: pointer;
      svg {
        font-size: 20px;
      }
      &:hover {
        // @include ft_color(color-main-white, false);
        // @include bg_color(color-collapse-header-bg-hover, false);
        & .sand-newTable__footer--triangle {
          filter: brightness(--svg-filter2);
        }
      }
      &--fixed {
        position: fixed;
        bottom: 0;
      }
      &--triangle {
        margin-left: 8px;
        transition: transform 0.3s;
        vertical-align: middle;
      }
      &--open {
        & .sand-newTable__footer--triangle {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
