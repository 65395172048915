<template>
  <div>
    <div class="wrap">
      <Loading v-if="loading" :loading="loading" />
      <div v-else>
        <!-- 静态信息 -->
        <StaticInfo id="staticinfo" v-if="staticinfo" :staticinfo="staticinfo" :taskInfo="taskInfo" />
      </div>
    </div>
    <detail-footer/>
  </div>
</template>

<script>
import { getTaskReportStatic, getTaskReportOverview } from 'services/report'
import StaticInfo from '../StaticInfo'
import { resetTransition } from '@/hooks/useDom'

export default {
  name: 'static',
  components: {
    StaticInfo
  },
  props: {
    taskInfo: {
      type: Object
    }
  },
  data() {
    return {
      staticinfo: {},
      loading: true
    }
  },
  computed: {
    taskId() {
      return this.$route.params.taskId || this.$route.query.taskid
    }
  },
  methods: {
    async init() {
      await getTaskReportStatic(this.taskId).then((res) => {
        this.staticinfo.static = res.static
        this.staticinfo.strings = res.strings
        this.staticinfo.static.permission = res.static.permission
      })
      await getTaskReportOverview(this.taskId).then((res) => {
        this.staticinfo.target = res.target.file
        this.staticinfo.taskOS = res.info.machine.os
      })
      this.loading = false
      resetTransition()
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
  .wrap {
    min-height: calc(100% - 100px);
    padding: 0 20px 30px 20px;
  }
</style>
