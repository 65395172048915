<template>
  <sandNoData v-if="data.length === 0" :text="$t('nodata')" />
  <div class="sand-tab" v-else-if="!isOpen">
    <el-tabs v-model="activeName" @tab-click="handleChangeTab">
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="item in data"
        :key="item.name">
        <slot :slotName="item.name" :childrenData="item.childrenData"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
  <div class="sand-tabList" v-else>
    <div
        v-for="item in data"
        :key="item.name">
        <div class="sand-tabList-label">{{item.label}}</div>
        <slot :slotName="item.name" :childrenData="item.childrenData"></slot>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SandNoData from '@/components/report/sandNoData'
export default {
  components: {
    SandNoData
  },
  props: {
    data: {
      type: Array,
      default: () => [
        // {
        //   label: 'Library ntdll.dll',
        //   name: 'fff',
        //   childrenData: {}
        // }, {
        //   label: 'HTTP（2）',
        //   name: 'aaa',
        //   childrenData: {}
        // }, {
        //   label: 'HTTP（3）',
        //   name: 'aa3',
        //   childrenData: {}
        // }
      ]
    },
    defaultName: {
      type: String
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      activeName: '',
      isOpen: false
    }
  },
  computed: {
    reportState () {
      return this.$store.getters.getReportState
    },
    ...mapGetters({
      curTab: 'report/curTab'
    }),
    isNetworkTab() {
      return this.type === 'network' || this.type === 'overview'
    }
  },
  watch: {
    reportState () {
      this.setReportStatic()
    },
    defaultName (newVal) {
      this.activeName = newVal
    },
    data: {
      handler (newVal, oldVal) {
        this.activeName = this.data[0] && this.data[0].name
      },
      immediate: true
    },
    '$route'() {
      if (!this.isNetworkTab) return
      this.activeName = this.curTab
    }
  },
  mounted () {
    this.activeName = this.isNetworkTab ? this.curTab : this.data[0] && this.data[0].name
    if (this.defaultName) {
      this.activeName = this.defaultName
    }
    if (this.data[0] && this.data[0].name && !this.isNetworkTab) {
      this.activeName = this.data[0].name
    }
    this.setReportStatic()
  },
  methods: {
    handleChangeTab() {
      this.$nextTick(() => {
        this.$emit('change', this.activeName)
      })
    },
    setReportStatic () {
      if (this.reportState) {
        this.open()
      }
    },
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sand-tab  {
  & :deep(.el-tabs__content)  {
    overflow: visible;
  }
  & :deep(.el-tabs__header)  {
    margin: 0 0 15px;
    /* 下面的长线条 */
    & .el-tabs__nav-wrap::after  {
      height: 1px;
      opacity: 1;
      // @include bg_color(color-tab2-line);
      border-radius: 1px;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev {
      top: -5px;
    }

    /* 下面的小短线 */
    & .el-tabs__active-bar  {
      // @include bg_color(color-tab2-active-line);
      border-radius: 2px;
      height: 2px;
      transition-property: all;
    }
    & .el-tabs__nav {
      display: block;
      padding-bottom: 10px;
      padding-left: 23px;
      padding-right: 34px;
    }
    & .el-tabs__item  {
      height: 30px;
      line-height: 30px;
      // padding-right: 50px;
      font-weight: 400;
      font-size: 14px;
      // @include ft_color(color-tab2-color);
      &:last-child  {
        padding-right: 0px;
      }

      /* hover和选中的时候的颜色 */
      &:hover  {
        // @include ft_color(color-tab2-active-color);
      }
      &.is-active  {
        // @include ft_color(color-tab2-active-color);
        font-weight: 500;
      }
    }
  }
}

/* 展开模式的label */
.sand-tabList {
  & .sand-tabList-label  {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    margin: 2px 0 13px 0;
  }
}
</style>
