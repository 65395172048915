<template>
  <div class="network-behavior-wrap">
    <Widgest isOpenAll :widgestType="'outer'" :isBig="true">
      <template v-slot:title>{{ $t('other.views.reportPdf.netOverview') }}</template>
      <div v-for="(item, index) in tabData" :key="index">
        <SandTab ref="tab" :data="[item]" class="network-table" @change="_handleTabChange"></SandTab>
        <sand-network :key="item.name" :taskid="taskId" :tab="item.name" :header="columnData[item.name]" :totalCount="networkCount[item.name]"></sand-network>
      </div>
    </Widgest>
  </div>
</template>

<script>
// 分析概览 及 网络分析 的 网络分析模块
import SandTab from '@/components/report/sandTab'
import SandNetwork from '@/components/report/sandNetwork'

export default {
  name: 'StaticInfo',
  components: {
    SandTab,
    SandNetwork
  },
  props: {
    networkCount: {
      type: Object
    },
    taskId: {
      type: String
    },
    type: {
      type: String
    }
  },
  computed: {
    columnData () {
      const overviewColumnData = {
        'traffic.alerts': [
          {
            prop: 'src_ip',
            label: '源IP地址',
            align: 'left'
          },
          {
            prop: 'dst_ip',
            label: '目的IP地址',
            align: 'left'
          },
          {
            prop: 'category',
            label: '类型',
            align: 'left'
          },
          {
            prop: 'severity',
            label: '严重程度',
            align: 'left'
          }
        ],
        hosts: [
          {
            prop: 'ip',
            label: 'IP地址',
            align: 'left'
          },
          {
            prop: 'country',
            label: '国家或地区',
            align: 'left'
          },
          {
            prop: 'city',
            label: '城市',
            align: 'left'
          },
          {
            prop: 'isp',
            label: 'ISP',
            align: 'left'
          }
        ],
        dns: [
          {
            prop: 'area_name',
            label: '域名',
            align: 'left'
          },
          {
            prop: 'analyse',
            label: '解析结果',
            align: 'left'
          }
        ]
      }
      if (this.networkCount['traffic.alerts'] === 0) {
        delete overviewColumnData['traffic.alerts']
      }
      return overviewColumnData
    },
    tabData () {
      const keys = Object.keys(this.columnData)
      const cn = {
        'traffic.alerts': '流量检测',
        hosts: '主机',
        dns: '域名'
      }
      const resultData = keys.map(item => {
        let label = `${item.toUpperCase()}(${this.networkCount[item]})`
        for (const key in cn) {
          if (key === item) {
            label = `${cn[key]}(${this.networkCount[item]})`
          }
        }
        return {
          label,
          name: item
        }
      })
      return resultData
    }
  },
  data () {
    return {
      tab: this.networkCount['traffic.alerts'] !== 0 ? 'traffic.alerts' : 'hosts'
    }
  },
  methods: {
    _handleTabChange (tab) {
      this.tab = tab
    }
  }
}
</script>

<style lang="scss" scoped>
// 点击进入网络分析再返回 网络概要有active状态
.network-behavior-wrap {
  :deep(.sand-tableCollapse__header .font) {
    color: var(--color-text-2);
  }
  :deep(.sand-tableCollapse__header) {
    width: calc(100% - 51px) !important;
  }
  :deep(.sandNetwork-wrap) {
    height: auto !important;
    .el-pagination__sizes, .btn-prev, .el-pager, .btn-next {
      display: none;
    }
    .pagination-wrap {
      margin-right: 0;
    }
  }
}
</style>
