import G6 from '@antv/g6'
import { getFileSuffix } from 'utils/file'
import { getColor } from 'app/style'

const IMG_URL = '/assets/img/dark/graph/'

function getBgSize(data) {
  return 36
}

function getIconSize(data) {
  return 18
}

function getIconImg({ data }) {
  let imgType
  const { taskid, is_public, file_type, ip, domain, url, md5, isRoot } = data
  if (file_type) {
    if (taskid && is_public !== 1 && !isRoot) {
      imgType = 'lock'
    } else {
      imgType = getFileSuffix(file_type)
    }
  } else if (ip) {
    imgType = 'ip'
  } else if (domain) {
    imgType = 'domain'
  } else if (url) {
    imgType = 'url'
  } else if (md5) {
    imgType = 'md5'
  }
  return IMG_URL + imgType + '.svg'
}

G6.registerNode(
  'base-node',
  {
    draw(cfg, group) {
      const bgSize = getBgSize(cfg)
      const iconSize = getIconSize(cfg)
      const iconImg = getIconImg(cfg)
      const keyShape = group.addShape('circle', {
        draggable: true,
        attrs: {
          r: bgSize / 2,
          stroke: getColor('--color-text-4'),
          fill: getColor('--color-bg-3'),
          lineWidth: 1
        }
      })
      group.addShape('image', {
        draggable: true,
        attrs: {
          img: iconImg,
          width: iconSize,
          height: iconSize,
          x: -bgSize / 4,
          y: -bgSize / 4,
          filter: getColor('--svg-filter1')
        }
      })
      // if (cfg.label) {
      //   group.addShape('text', {
      //     attrs: {
      //       x: 0, // 居中
      //       y: 50,
      //       textAlign: 'center',
      //       textBaseline: 'bottom',
      //       text: cfg.label,
      //       fill: '#fff'
      //     }
      //   })
      // }
      return keyShape
    },
    update(cfg, node) {
      const { selected } = cfg
      const group = node.getContainer()
      const keyShape = group.get('children')[0]
      const bgImg = group.get('children')[1]
      const iconImg = group.get('children')[2]
      const opacity = 1
      keyShape.attr('stroke', selected ? '#fff' : 'transparent')
      bgImg.attr('opacity', opacity)
      iconImg.attr('opacity', opacity)
      node.toFront()
    }
  },
  'single-node'
)
