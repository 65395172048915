<template>
  <div>
    <Loading v-if="loading" :loading="loading" />
    <div v-else>
      <!-- 行为分析 -->
      <ProgressAnalyse id="progressanalyse" v-if="progressanalyse" :progressanalyse="progressanalyse" />
    </div>
  </div>
</template>

<script>
import {
  getTaskReportOverview,
  getTaskReportBehaviorProcess,
  getTaskReportBehaviorNetwork,
  getTaskReportDropped
} from 'services/report'
import ProgressAnalyse from '../ProgressAnalyse'
import { resetTransition } from '@/hooks/useDom'
import { t, isZh } from 'app/i18n'

export default {
  name: 'behavior',
  components: {
    ProgressAnalyse
  },
  data () {
    return {
      progressanalyse: {},
      tips: t('other.views.reportPdf.loading'),
      loading: true
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  methods: {
    async init () {
      await getTaskReportOverview(this.taskId).then(res => {
        this.progressanalyse.info = res.info
        this.progressanalyse.target = res.target
      })
      await getTaskReportBehaviorProcess(this.taskId).then(res => {
        this.progressanalyse.process_tree = res.processtree
        this.progressanalyse.processes = res.processes
        this.progressanalyse.events_map = res.events_map
      })
      await getTaskReportBehaviorNetwork(this.taskId, {
        lang: isZh() ? 'zh' : 'en'
      }).then(res => {
        this.progressanalyse.domains = res.domains
        this.progressanalyse.hosts = res.hosts
        this.progressanalyse.https = res.https
        this.progressanalyse.http = res.http
      })
      await getTaskReportDropped(this.taskId).then(res => {
        this.progressanalyse.dropped = res.dropped
      })
      this.loading = false
      resetTransition()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="stylus" scoped>
</style>
