<template>
  <div class="leftWrap" :style="{'width': props.isTaskList ? '400px' : '440px'}">
    <div class="detail" :style="{'padding': props.isTaskList ? '0px' : '0 20px'}">
      <div class="detail-name" v-if="!props.isTaskList">
        <copy-icon
          style="vertical-align: middle;width:20px;color:var(--color-text-3);font-size:16px;"
          :right="8"
          name="name"
          :copyText="computedInfo.fileName"
        />
        <p>{{computedInfo.fileName}}</p>
      </div>
      <div class="detail-overview">
        <dashboard isShowArrow="true" :onlyIncludeStatic="props.onlyIncludeStatic" :process="score" :status="props.taskInfo.status" :combined="info.score_combined" :dynamic_score="info.dynamic_score" :score="props.summary.threat_level"></dashboard>
        <div class="detail-overview__items">
          <div class="detail-overview__top">
            <div class="detail-overview__platform">
              <svg-icon :name="computedInfo.taskOS"></svg-icon>
              <p class="type">{{$t('report.overview.platform')}}</p>
              <p class="name">{{computedInfo.taskOS}}</p>
            </div>
            <div class="detail-overview__type">
              <svg-icon :name="getFileTypeIcon(computedInfo.file_type)"></svg-icon>
              <p class="type">{{category !== 'url' ? $t('report.overview.file_type') : $t('report.overview.task_type')}}</p>
              <p class="name">{{category !== 'url' ? computedInfo.file_type : 'URL'}}</p>
            </div>
          </div>
          <div class="detail-overview__bottom" v-if="areasConfig.count">
            <el-tooltip v-for="(item, key) in specificCount" :key="key" :effect="$isDark() ? 'dark' : 'light'" :content="item.name" placement="left">
              <div class="items" :class="{'red': item.class && props.summary.specificCount && props.summary.specificCount[item.prop]}">
                <svg-icon :name="item.icon"></svg-icon>
                <span>{{props.summary.specificCount && (props.summary.specificCount[item.prop] || props.summary.specificCount[item.prop] === 0) ? props.summary.specificCount[item.prop] : ''}}</span>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <TagList v-if="areasConfig.tag && computedInfo.tags" :data="computedInfo.tags" :showMore="computedInfo.tagsFilter" />
      <div class="detail-overview__operate" v-if="areasConfig.operate.show">
        <div class="analyseSummary-content-btn-item">
          <el-dropdown
            trigger="click"
            placement="bottom-end"
            popper-class="advanced-operate"
            @visible-change="(val) => downloadMenuChange('shareDropdown', val)"
          >
            <span class="el-dropdown-link" :class="{'active': state.shareDropdown}">
              {{$t('report.overview.operate.share.name')}}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                <ShareNetwork
                    network="Weibo"
                    :url="share.url"
                    :title="share.title"
                    :media="share.img"
                  >
                    {{$t('report.overview.operate.share.weibo')}}
                </ShareNetwork>
                </el-dropdown-item>
                <el-dropdown-item class="noPadding">
                <el-dropdown
                :popper-options="{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 8],
                      },
                    },
                  ],
                }"
                placement='right-start'
                trigger="hover"
                popper-class="qrcode"
                >
                    <span class="arrow-item width-114" @click.stop="stopPrevent">
                      {{$t('report.overview.operate.share.wechat')}}
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <div class="aaavvv"></div>
                      <vue-qrcode margin="2" :value="share.url" />
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
                <el-dropdown-item>
                  <ShareNetwork
                    network="Twitter"
                    :url="share.url"
                    :title="share.twitterTitle"
                  >
                    {{$t('report.overview.operate.share.twitter')}}
                  </ShareNetwork>
                </el-dropdown-item>
                <el-dropdown-item>
                  <ShareNetwork
                    network="facebook"
                    :url="share.url"
                    :title="share.enTitle"
                    :description="share.enTitle"
                    hashtags="360SandboxCloud"
                    :media="share.img"
                  >
                    {{$t('report.overview.operate.share.facebook')}}
                  </ShareNetwork>
                  <!-- <ShareNetwork
                    network="facebook"
                    url="https://news.vuejs.org/issues/180"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                    hashtags="vuejs,vite"
                  >
                    Share on Facebook
                </ShareNetwork> -->
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="analyseSummary-content-btn-item" v-if="userInfo && (userInfo.user_id === props.taskInfo.owner_id)">
          <el-dropdown
            trigger="click"
            placement="bottom-end"
            :hide-on-click="false"
            popper-class="advanced-operate"
            @visible-change="(val) => downloadMenuChange('handleDropdown', val)"
          >
            <span class="el-dropdown-link" :class="{'active': state.handleDropdown}">
              {{$t('report.overview.operate.handle.name')}}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="noPadding">
                  <el-dropdown
                  placement='right-start'
                  class="personalization"
                  trigger="hover"
                  popper-class="advanced-operate shareIcon"
                  :popper-options="{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 4],
                        },
                      },
                    ],
                  }"
                  >
                    <span class="arrow-item">
                      {{$t('report.overview.operate.handle.range.title')}}
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-for="(item, index) in isPublicOptions"
                          :key="index"
                          :icon="is_public === item.value ? Select : ''"
                          :class="{'fontRight': is_public !== item.value}"
                          :disabled="item.disable"
                          @click="handlePublic(item.value, item.disable)"
                          >
                            {{item.name}}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
                <el-dropdown-item  class="noPadding">
                  <el-dropdown
                  placement='right-start'
                  class="personalization"
                  popper-class="advanced-operate"
                  :popper-options="{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 4],
                        },
                      },
                    ],
                  }"
                  >
                    <span class="arrow-item">
                      {{$t('report.overview.operate.handle.team')}}
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="teamDropDownMenu" v-if="teamList.length">
                        <el-checkbox-group v-model="_shared_ids">
                          <el-dropdown-item v-for="(item, index) in teamList" :key="index">
                            <el-checkbox @change="(bol) => shareFn(item.team_id, bol)" :label="item.team_id">{{item.team_name}}</el-checkbox>
                          </el-dropdown-item>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                      <div class="no-team-yet" v-else>{{$t('report.overview.operate.handle.non_team')}}</div>
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
                <el-dropdown-item :disabled="areasConfig.operate.disable || gid === 1" @click="deleteTask">{{$t('report.overview.operate.handle.delete')}}</el-dropdown-item>
                <el-dropdown-item v-if="static_huntings.length">
                  <el-dropdown
                    placement='right-start'
                    class="personalization"
                    trigger="hover"
                    popper-class="advanced-operate shareIcon"
                    :popper-options="{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-8, 24],
                          },
                        },
                      ],
                    }"
                  >
                    <span class="arrow-item noPadding">
                      静态狩猎规则
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item class="noPadding" v-for="(staticItem, index) in static_huntings" :key="`${index}-${staticItem.status}`">
                          <el-dropdown
                            placement='right-start'
                            class="personalization"
                            trigger="hover"
                            popper-class="advanced-operate shareIcon"
                            :popper-options="{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-6, 4],
                                  },
                                },
                              ],
                            }"
                          >
                            <span class="arrow-item">
                              {{ staticItem.rule_name }}
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  :icon="staticItem.status === 1 ? Select : ''"
                                  :class="{'fontRight': staticItem.status === 2}"
                                  @click="handleConfirmOrErr(staticItem, 'static', 1)"
                                >
                                  确认
                                </el-dropdown-item>
                                <el-dropdown-item
                                  :icon="staticItem.status === 2 ? Select : ''"
                                  :class="{'fontRight':  staticItem.status === 1}"
                                  @click="handleConfirmOrErr(staticItem, 'static', 2)"
                                >
                                  误报
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
                <el-dropdown-item v-if="threat_huntings.length">
                  <el-dropdown
                    placement='right-start'
                    class="personalization"
                    trigger="hover"
                    popper-class="advanced-operate shareIcon"
                    :popper-options="{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-8, 24],
                          },
                        },
                      ],
                    }"
                  >
                    <span class="arrow-item noPadding">
                      威胁狩猎规则
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="(threatItem, index) in threat_huntings" :key="`${index}-${threatItem.status}`">
                          <el-dropdown
                            placement='right-start'
                            class="personalization"
                            trigger="hover"
                            popper-class="advanced-operate shareIcon"
                            :popper-options="{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-6, 24],
                                  },
                                },
                              ],
                            }"
                          >
                            <span class="arrow-item noPadding">
                              {{ threatItem.rule_name }}
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  :icon="threatItem.status === 1 ? Select : ''"
                                  :class="{'fontRight':  threatItem.status === 2}"
                                  @click="handleConfirmOrErr(threatItem, 'threat', 1)"
                                >
                                  确认
                                </el-dropdown-item>
                                <el-dropdown-item
                                  :icon="threatItem.status === 2 ? Select : ''"
                                  :class="{'fontRight':  threatItem.status === 1}"
                                  @click="handleConfirmOrErr(threatItem, 'threat', 2)"
                                >
                                  误报
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
          class="analyseSummary-content-btn-item"
          :class="{'redetect-disable' : areasConfig.operate.disable || !canDownload.redetect}"
          @click="redetectFn"
        >
          {{$t('report.overview.operate.redetect')}}
        </div>
        <div class="analyseSummary-content-btn-item download">
          <el-dropdown
            trigger="click"
            placement="bottom-end"
            popper-class="advanced-operate"
            @visible-change="(val) => downloadMenuChange('downloadDropdown', val)"
            :hide-on-click="false"
          >
            <span class="el-dropdown-link" :class="{'active': state.downloadDropdown}">
              {{$t('report.overview.operate.download.name')}}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  :title="!canDownload.sample ? $t('config.need_professional') : ''"
                  :disabled="!canDownload.sample || category !== 'file'"
                  @click="handleDownload('sample')"
                >{{$t('report.overview.operate.download.sample')}}</el-dropdown-item>
                <el-dropdown-item
                  :title="!canDownload.pcap ? $t('config.need_professional') : ''"
                  @click="handleDownload('pcap')"
                  :disabled="areasConfig.operate.disable || !canDownload.pcap || props.onlyIncludeStatic || !props.taskInfo.has_pcap"
                >{{$t('report.overview.operate.download.pcap')}}</el-dropdown-item>
                <el-dropdown-item class="noPadding">
                  <el-dropdown
                    placement='right-start'
                    class="personalization"
                    trigger="hover"
                    popper-class="advanced-operate"
                    :popper-options="{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 3],
                          },
                        },
                      ],
                    }"
                  >
                    <span class="arrow-item">
                      {{$t('report.overview.operate.download.report')}}
                      <el-icon class="el-icon--right">
                        <arrow-right />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-item
                        :title="gid < 2 ? $t('report.overview.operate.download.permissions_limited') : ''"
                        @click="handleDownload('pdf')"
                        :disabled="gid < 2 || isRunning"
                      >
                        <el-icon v-if="!isDownloadingPdf"><Download /></el-icon>
                        <el-icon class="is-loading" v-else><Loading /></el-icon>
                        {{$t('report.overview.operate.download.pdf')}}
                      </el-dropdown-item>
                    </template>
                  </el-dropdown>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="detail-center">
        <div class="detail-center__info">
          <div style="display: flex; align-items: center;">
            <span>{{$t('report.overview.detail.environment.label')}}</span>
            <span style="max-width: 280px; overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{computedInfo.environment}}</span>
            <!-- <span class="detail-center__detail">查看</span> -->
            <span
              v-if="!props.onlyIncludeStatic"
              style="margin-left: 4px;"
            >
              <configDetail
                :type="category"
                :fileType="computedInfo.file_type"
                :os="computedInfo.taskOS"
                :data="params"
              />
            </span>
          </div>
          <div>
            <span>{{$t('report.overview.detail.shutdown_on')}}</span>
            <span>{{computedInfo.shutdown_on}}</span>
          </div>
          <div v-if="computedInfo.continued">
            <span>{{$t('report.overview.detail.continued')}}</span>
            <span>{{computedInfo.continued}}</span>
          </div>
          <div v-if="!props.onlyIncludeStatic && computedInfo.duration">
            <span>{{$t('report.overview.detail.duration')}}</span>
            <span>{{computedInfo.duration}}</span>
          </div>
        </div>
        <div class="detail-center__string">
          <div>
            <span>MD5</span>
            <span class="str">{{computedInfo.md5}}</span>
            <copy-icon
              style="padding:0;margin-left:8px;"
              name="md5-info"
              :copyText="computedInfo.md5"
              class="copy-icon"
            />
            <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
              <svg-icon name="sousuoti" @click="toTi(computedInfo.md5)"></svg-icon>
            </el-tooltip>
          </div>
          <div>
            <span>SHA1</span>
            <span class="str">{{computedInfo.sha1}}</span>
            <copy-icon
              style="padding:0;"
              name="sha1-info"
              :copyText="computedInfo.sha1"
            />
            <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
              <svg-icon name="sousuoti" @click="toTi(computedInfo.sha1)"></svg-icon>
            </el-tooltip>
          </div>
          <div>
            <span>SHA256</span>
            <span class="str">{{computedInfo.sha256}}</span>
            <copy-icon
              style="padding:0;"
              name="sha256-info"
              :copyText="computedInfo.sha256"
            />
            <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
              <svg-icon name="sousuoti" @click="toTi(computedInfo.sha256)"></svg-icon>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="detail-bottom" v-if="areasConfig.process && props.processes.length">
        <p>{{$t('report.overview.process')}}</p>
        <div class="detail-bottom__content">
          <div class="detail-bottom__listWrap">
            <div v-for="(item, index) in props.processes" @click="toProcess(item)" :key="index">
              <span>{{item.pid}}</span>
              <svg-icon name="qitajincheng"></svg-icon>
              <span>{{item.process_name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="state.dialogVisible"
    :title="$t('dialog.delete.title')"
    width="30%"
  >
    <div class="deleteContent">
      <svg-icon name="info"></svg-icon>
      <span style="line-height: 21px;">{{$t('dialog.delete.content')}}</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogVisible = false">{{$t('action.cancel')}}</el-button>
        <el-button type="primary" @click="deleteReportFn"
          >{{$t('dialog.delete.confirm')}}</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="state.confirmDialog"
    :title="$t('report.overview.operate.download.confirm')"
    width="30%"
  >
    <div class="deleteContent">
      <svg-icon name="info"></svg-icon>
      <span style="line-height: 21px; flex: 1">{{$t('report.overview.operate.download.warm')}}</span>
    </div>
    <div style="padding-left: 28px;line-height: 21px;">{{$t('report.overview.operate.download.warm1')}}<span style="font-weight: 700">360ata</span></div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.confirmDialog = false">{{$t('dialog.cancel')}}</el-button>
        <el-button type="primary" @click="confirm_download"
          >{{$t('action.confirm')}}</el-button
        >
      </span>
    </template>
  </el-dialog>
  <redetect
    v-if="state.redetect"
    v-model:show="state.redetect"
    :curPlatform="computedInfo.taskOS"
    :params="resubmitParams"
    :is_public="is_public"
    :taskId="props.taskInfo.id"
  ></redetect>
  <UpgradeDialog
    v-model:show="state.isShowUpgrade"
    :type="state.upgradeType"
    :customText="state.customText"
    :showPoint="login && state.showPoint"
    :showNormal="!login || state.showNormal"
  />
  <confirmDialog
    v-if="dialogHuntVisible"
    v-model:show="dialogHuntVisible"
    :readonly="dialogHuntIsReadonly"
    :type="huntActionType"
    :pageType="huntPageType"
    :rule_id="rule_id"
    :task_id="taskInfo.id"
    @freshList="freshHuntStatus"
  />
</template>
<script setup>
import VueQrcode from 'vue-qrcode'
import TagList from '@/components/common/tagList'
import configDetail from '@/components/report/configDetail'
import confirmDialog from './confirmDialog.vue'
import redetect from '@/components/report/redetect'
import { download } from 'utils/tools'
import { ArrowDown, ArrowRight, Select, Download, Loading } from '@element-plus/icons-vue'
import { defineProps, computed, reactive, ref, onMounted, toRefs, watchEffect, watch, defineEmits } from 'vue'
import { useState, useGetters } from '@/hooks/useVuex'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { downloadSample, deleteReport, downloadReportPDF, getResubmitConfig } from 'services/report'
import { postTaskUserShare } from 'services/center'
import { postTeamShareTask } from 'services/team'
import cloneDeep from 'lodash/cloneDeep'
import dashboard from '@/components/echarts/dashboard'
import UpgradeDialog from '@/components/common/upgradeDialog'
import emitter from 'app/emitter'
import { message } from '@/hooks/useUI'
import dayjs from 'dayjs'
import { t, isZh } from 'app/i18n'

const store = useStore()
const router = useRouter()
const emit = defineEmits(['reloadTaskInfo'])
const dialogHuntVisible = ref(false)
const dialogHuntIsReadonly = ref(false)
const huntActionType = ref(0)
const huntPageType = ref('static')
const rule_id = ref(0)
const gid = computed(() => store.getters.gid)
const { scoreInfo, userInfo } = useState('user', ['scoreInfo', 'userInfo'])
const { shared_ids } = useState('team', ['shared_ids'])
const { teamList } = useGetters('team', ['teamList'])
const { tiToken } = useState('user', ['tiToken'])
const isDownloadingPdf = ref(false)

const props = defineProps({
  taskInfo: {
    type: Object,
    default: () => {}
  },
  summary: {
    type: Object
  },
  onlyIncludeStatic: {
    type: Boolean
  },
  progress: {
    type: Number,
    default: 100
  },
  isTaskList: {
    type: Boolean,
    default: false
  },
  isRunning: {
    type: Boolean
  },
  processes: {
    type: Array,
    default: () => []
  },
  behaviorscreenshot: {
    type: Object
  }
})
const { isRunning } = toRefs(props)
const login = ref(store.getters.isLogin)
const is_public = ref(props.summary.params.is_public)
const params = ref(JSON.parse(JSON.stringify(props.summary.params)))
const resubmitParams = ref(JSON.parse(JSON.stringify(props.summary.params)))
// const configParams = ref(JSON.parse(JSON.stringify(props.summary.params)))

// watchEffect(() => {
//   // if (props.summary.params) params = ref(JSON.parse(JSON.stringify(window.originConfig)))
//   if (window.originConfig) params = ref(JSON.parse(JSON.stringify(window.originConfig)))
//   console.log(params)
// })

watch(() => props.summary, () => {
  params.value = JSON.parse(JSON.stringify(props.summary.params))
  resubmitParams.value = JSON.parse(JSON.stringify(props.summary.params))
  // configParams.value = JSON.parse(JSON.stringify(props.summary.params))
}, {
  deep: true
})

const state = reactive({
  popperShow: false,
  isShowUpgrade: false,
  upgradeType: '',
  customText: '',
  dialogVisible: false,
  redetect: false,
  continued: 0,
  initTime: new Date(),
  timer: null,
  downloadDropdown: false,
  handleDropdown: false,
  shareDropdown: false,
  showPoint: false,
  showNormal: false,
  confirmDialog: false
})

const category = computed(() => {
  if (isRunning.value) {
    return props.taskInfo.category !== 'url' ? 'file' : 'url'
  } else {
    return props.summary.info.category !== 'url' ? 'file' : 'url'
  }
})

const static_huntings = computed(() => {
  return props.taskInfo.static_huntings || []
})

const threat_huntings = computed(() => {
  return props.taskInfo.threat_huntings || []
})

const score = computed(() => props.progress)

const areasConfig = computed(() => {
  const score = props.progress
  return {
    count: score > 30,
    tag: score > 30,
    operate: {
      show: score > 30,
      disable: score < 100
    },
    process: score === 100
  }
})

const info = computed(() => props.summary.info)

const _shared_ids = computed({
  get: () => shared_ids.value,
  set: val => {
    store.commit('team/SET_SHARED_IDS', val)
  }
})

onMounted(() => {
  if (store.getters.isLogin) {
    // 获得团队信息
    store.dispatch('team/getTeamMembers')
  }
  if (isRunning.value) {
    state.timer = setInterval(() => {
      state.continued = state.continued + 1
    }, 1000)
  }
  document.title = t('report.title', [computedInfo.value.fileName.length > 10 ? computedInfo.value.fileName.substring(0, 10) + '...' : computedInfo.value.fileName])
})

const isPublicOptions = ref([
  {
    name: t('report.overview.operate.handle.range.public'),
    value: true
  },
  {
    name: t('report.overview.operate.handle.range.private'),
    value: false,
    disable: gid.value === 1
  }
])

let specificCount = [
  {
    name: '威胁指标',
    icon: 'weixiezhibiao',
    prop: 'threaten_quota'
  },
  {
    name: '网络主机',
    icon: 'zhuji',
    prop: 'host'
  },
  {
    name: '病毒名',
    icon: 'loudong',
    prop: 'virus_name',
    class: 'red'
  },
  {
    name: '释放文件',
    icon: 'shifangwenjian',
    prop: 'dropped'
  },
  {
    name: '进程数',
    icon: 'jinchengshu',
    prop: 'process'
  },
  {
    name: '规则指标',
    icon: 'guizezhibiao',
    prop: 'signatures_quota'
  }
]

specificCount = specificCount.map(v => {
  return {
    ...v,
    name: t(`report.overview.specific_count.${v.prop}`)
  }
})

const canDownload = computed(() => {
  return {
    sample: gid.value >= 2 || (scoreInfo.value && scoreInfo.value.download_last > 0),
    pcap: gid.value >= 2 || (scoreInfo.value && scoreInfo.value.download_last > 0),
    redetect: userInfo.value.group && userInfo.value.group !== 'free'
  }
})

const reported_computedInfo = computed(() => {
  const { target, machine } = info.value
  const other =
        category.value === 'file'
          ? {
            crc32: target?.file?.crc32,
            ssdeep: target?.file?.ssdeep,
            name: props.taskInfo ? props.taskInfo.filename : target?.file?.name,
            file_type: props.taskInfo.file_type
          }
          : {
            url: target.url
          }
  const env = machine.os_env || machine.tags || ''
  let environment = (Array.isArray(env) ? env : env.split(',')).join(' ')
  if (props.onlyIncludeStatic) {
    environment = t('report.overview.detail.environment.static')
  }
  if (!isZh() && environment && environment.indexOf('位') > -1) {
    environment = environment.replace('位', '-bit')
  }
  return {
    taskOS: props.summary.taskOS,
    fileName: props.taskInfo.detect_sample,
    md5: target?.file?.md5,
    sha1: target?.file?.sha1,
    sha256: target?.file?.sha256,
    shutdown_on: info.value.added_on || info.value.started,
    continued: t('report.overview.detail.formatter', [info.value.detect_duration || '0']),
    // ended: this.info.ended,
    environment: environment,
    // platform: other?.file_type === 'apk' ? 'Android' : other?.file_type === 'elf' ? 'Linux' : 'Windows',
    duration: info.value.duration ? t('report.overview.detail.formatter', [info.value.duration]) : '',
    tags: info.value.tags,
    tagsFilter: info.value.tagsFilter,
    download_url: info.value.download_urls,
    ...other
  }
})

const running_computedInfo = computed(() => {
  const env = props.taskInfo.os_env
  let environment = (Array.isArray(env) ? env : env.split(',')).join(' ')
  if (props.onlyIncludeStatic) {
    environment = t('report.overview.detail.environment.static')
  } else if (Array.isArray(env) && env.length === 3) {
    environment = t('report.overview.detail.environment.suffix', [environment])
  }
  if (!isZh() && environment && environment.indexOf('位') > -1) {
    environment = environment.replace('位', '-bit')
  }
  return {
    taskOS: props.taskInfo.os,
    file_type: props.taskInfo.file_type,
    fileName: props.taskInfo.detect_sample,
    shutdown_on: props.taskInfo.added_on,
    md5: props.taskInfo.md5,
    sha1: props.taskInfo.sha1,
    sha256: props.taskInfo.sha256,
    environment: environment,
    duration: '',
    continued: props.onlyIncludeStatic ? null : t('report.overview.detail.formatter', [spend.value + state.continued]),
    download_url: info.value.download_urls
  }
})

const spend = computed(() => props.taskInfo.added_on ? dayjs(state.initTime).diff(dayjs(props.taskInfo.added_on), 'seconds') : 0)

// const computedInfo = isRunning.value && score.value < 100 ? running_computedInfo : reported_computedInfo
const computedInfo = computed(() => {
  if (isRunning.value && score.value < 100) {
    return running_computedInfo.value
  } else {
    return reported_computedInfo.value
  }
})

const share = {
  title: t('report.overview.operate.share.title', [computedInfo.value.fileName]),
  enTitle: `Focus on the analysis report of "${computedInfo.value.fileName}" #360SandboxCloud#`,
  twitterTitle: `Focus on the analysis report of "${computedInfo.value.fileName}" #360SandboxCloud`,
  url: window.location.href,
  img: window.location.host + (props.behaviorscreenshot && props.behaviorscreenshot.shots && props.behaviorscreenshot.shots[0]) || ''
}

watchEffect(() => {
  if (!isRunning.value && score.value === 100) clearInterval(state.timer)
})

function stopPrevent () {
  console.log('阻止默认行为')
}

function handlePublic (isPublic, disabled) {
  if (is_public.value === isPublic || disabled) return
  postTaskUserShare({ task_ids: [props.taskInfo.id], is_public: isPublic })
    .then(() => {
      is_public.value = isPublic
      store.commit('app/showMessage', { message: t('action.handle'), duration: 800 })
    })
}

function handleConfirmOrErr (row, type, status) {
  huntActionType.value = status
  huntPageType.value = type
  rule_id.value = row.rule_id
  dialogHuntVisible.value = true
  if (row.status === status) {
    dialogHuntIsReadonly.value = true
  } else {
    dialogHuntIsReadonly.value = false
  }
}

function freshHuntStatus () {
  emit('reloadTaskInfo')
}

function shareFn(id, bol) {
  const message = bol ? t('action.share') : t('action.cancel_share')
  const unshare = teamList.value.map(v => v.team_id).filter(v => !_shared_ids.value.includes(v))
  postTeamShareTask({ task_ids: [props.taskInfo.id], share: [id], unshare }).then(res => {
    store.commit('app/showMessage', { message, duration: 800 })
  })
}

function deleteReportFn() {
  state.dialogVisible = false
  deleteReport(props.taskInfo.id).then(res => {
    if (res.is_success) {
      store.commit('app/showMessage', { message: t('action.delete'), duration: 800 })
      setTimeout(() => {
        router.push({ name: 'Home' })
      }, 800)
    }
  })
}

function toTi(params = '0000efd7a890e1e1a93e481288f5bd2a') {
  window.open(`https://ti.360.net/#/detailpage/searchresult?query=${params}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
  // window.open(`https://10.146.250.137/#/detailpage/searchresult?query=${params}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
}

function toProcess(data) {
  if (isRunning.value) return
  store.commit('report/SET_CURRENT_PID', data.pid)
  emitter.emit('tabChange', 'behavior')
}

function redetectFn() {
  if (areasConfig.value.operate.disable) return
  if (!canDownload.value.redetect) {
    state.showPoint = false
    state.showNormal = true
    state.isShowUpgrade = true
  } else {
    console.log(params)
    // state.redetect = true
    if (gid.value === 2) {
      getResubmitConfig(props.taskInfo.id).then(res => {
        console.log(res)
        // if (res.code === 0) {
        state.redetect = true
        window.originConfig = cloneDeep(res)
        resubmitParams.value = res
        console.log(resubmitParams.value)
      }).catch(() => {
        state.redetect = true
      })
    } else {
      state.redetect = true
    }
  }
}

function deleteTask () {
  if (areasConfig.value.operate.disable) return
  if (gid.value === 1) {
    state.showPoint = false
    state.showNormal = true
    state.isShowUpgrade = true
  } else {
    state.dialogVisible = true
  }
}

function downloadMenuChange(key, val) {
  state[key] = val
}

function getFileTypeIcon(type) {
  let file = ''
  if (category.value === 'file') {
    const list = [
      'html',
      'hwp',
      'js',
      'mhtml',
      'powershell',
      'python',
      'rar',
      'rtf',
      'swf',
      'VBS-icon',
      'email',
      'apk'
    ]
    if (list.includes(type)) {
      file = type
    } else if (type === 'doc' || type === 'docx') {
      file = 'doc'
    } else if (type === 'xls' || type === 'xlsx') {
      file = 'excel'
    } else if (type === 'dll32' || type === 'dll64') {
      file = 'dll'
    } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
      file = 'exe'
    } else if (type === 'ppt' || type === 'pptx') {
      file = 'ppt'
    } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
      file = 'zip'
    } else {
      file = 'unknow'
    }
  } else {
    file = 'IE'
  }
  return file
}

function confirm_download() {
  const url = computedInfo.value.download_url && computedInfo.value.download_url.sample
  downloadSampleFn(url)
  state.confirmDialog = false
}

async function handleDownload(type) {
  if (type === 'pdf') {
    if (gid.value < 2) {
      // 暂无权限
      state.showPoint = false
      state.isShowUpgrade = true
      state.customText = t('upgrade.normal')
      return
    }
    if (isRunning.value) {
      // 报告未完成无法下载
      message(t('report.overview.operate.download.undone'), 'error')
      return
    }
    // 执行pdf下载
    // window.open(`/wndoreport/${props.taskInfo.id}`, '_blank')
    // downloadSampleFn(`/api/v1/task/report/download/pdf/${props.taskInfo.id}`)
    downloadPDF()
  } else {
    state.customText = ''
  }
  if (areasConfig.value.operate.disable && type === 'pcap') return
  const url = computedInfo.value.download_url && computedInfo.value.download_url[type]
  if (!url) {
    state.popperShow = false
    return
  }
  switch (type) {
    case 'sample':
      if (category.value !== 'file') return
      if (canDownload.value.sample) {
        state.confirmDialog = true
      } else {
        state.showPoint = true
        state.isShowUpgrade = true
        state.upgradeType = 'C1'
      }
      break
    case 'pcap':
      if (!props.taskInfo.has_pcap) return
      if (canDownload.value.pcap) {
        downloadSampleFn(url)
      } else {
        state.showPoint = true
        state.isShowUpgrade = true
        state.upgradeType = 'C3'
      }
      break
  }
  state.popperShow = false
}

function downloadPDF() {
  if (isDownloadingPdf.value) {
    message(t('report.overview.operate.download.process'), 'error')
    return
  }
  isDownloadingPdf.value = true
  // message()
  const timeout = setTimeout(() => {
    message(t('report.overview.operate.download.ing'))
  }, 2000)
  // store.commit('SHOWMESSAGE', { type: 'success', message: '报告下载中', duration: 3000 })
  downloadReportPDF(+props.taskInfo.id).then((res) => {
    console.log(res)
    // message('报告下载完成')
    timeout && clearTimeout(timeout)
    // store.commit('SHOWMESSAGE', { type: 'success', message: '报告下载完成', duration: 3000 })
    message(t('report.overview.operate.download.process'))
    isDownloadingPdf.value = false
    download({
      content: res,
      type: 'application/octet-stream',
      filename: `${props.taskInfo.id}.pdf`
    })
  }).catch(() => {
    timeout && clearTimeout(timeout)
    isDownloadingPdf.value = false
  })
}

function downloadSampleFn(url) {
  downloadSample(url.replace('/api/v1', ''))
    .then((res) => {
      const filename = res.headers['content-disposition'].match(/filename=(.+)/)[1]
      download({
        content: res.data,
        type: 'application/zip',
        filename
      })
      console.log(filename)
      store.dispatch('user/getScoreInfo')
    })
    .catch((err) => {
      if (err.message !== 'Request failed with status code 429') {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('download.not_found'),
          duration: 1000
        })
      } else {
        if (userInfo.value.group === 'free') {
          state.isShowUpgrade = true
          state.upgradeType = 'C1'
        }
      }
    })
}

</script>
<style lang="scss">
@import '@/styles/common.scss';
.advanced-operate {
  border: 0 !important;
  // box-shadow:2px 2px 10px black !important;
  box-shadow: var(--shadow) !important;
  .el-popper__arrow {
    display: none;
  }
  .disabled {
    cursor: not-allowed;
    color: #525966;
  }
  .el-dropdown-menu__item {
    // padding: 5px 10px !important;
  }
  .arrow-item {
    width: 100px;
    height: 22px;
    display:flex;
    padding: 5px 20px;
    justify-content:space-between;
    align-items:center;
  }
  .width-114 {
    width: 114px;
  }
  .no-team-yet {
    width: 150px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-3);
    font-size: 14px;
  }
  .el-scrollbar__wrap {
    margin: 0 4px;
  }
}
.teamDropDownMenu {
  max-height: 300px;
}
.shareIcon {
  .fontRight {
    flex-direction: row-reverse;
  }
}
.noPadding {
  padding: 0 !important;
}
.qrcode {
  border: 0 !important;
  .el-popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.leftWrap {
  &::-webkit-scrollbar{width:0px;}
  width: 440px;
  max-height: calc(100vh - 104px);
  overflow-y: auto;
  background-color: var(--detect-config-bg);
}
.detail {
  padding: 0 20px;
  &-name {
    height:48px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 var(--color-border-2);
    svg {
      padding-right: 10px;
      color: var(--color-text-3);
      width: 18px;
      height: 18px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-text-2);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &-overview {
    display: flex;
    margin-bottom: 16px;
    height: 180px;
    &__type {
      overflow: hidden;
      img {
        display:inline-block;
        width: 14px;
        height: 14px;
        // position: relative;
        // left: -80px;
        // filter: drop-shadow(#7c7f8d 80px 0);
      }
      .type {
        // margin-top: 8px;
      }
    }
    svg {
      color: var(--color-text-3);
    }
    &__chart {
      // flex: 1;
    }
    &__items {
      flex: 2;
    }
    &__top {
      height: 78px;
      display: flex;
      padding-top: 16px;
      div {
        width: 50%;
        border-left: 1px solid var(--color-border-2);
        padding-left: 24px;
        font-size: 14px;
        .type {
          font-family: PingFang SC;
          color: var(--color-text-2);
        }
        .name {
          font-family: DIN Alternate;
          color: var(--color-text-2);
        }
      }
    }
    &__bottom {
      display: flex;
      flex-flow: wrap;
      margin-top: 18px;
      .items {
        margin-top: 12px;
        width: 84px;
        span {
          margin-left: 8px;
          font-family: DIN Alternate;
          color: var(--color-text-2);
        }
        &.red {
          svg {
            color: #ff5967
          }
          span {
            color: #ff5967;
          }
        }
      }
    }
    &__operate {
      margin-top: 16px;
      display:flex;
      height: 48px;
      align-items: center;
      flex-direction: row-reverse;
      background: var(--detect-config-bg);
      box-shadow: 0 -1px 0 0 var(--color-border-2);
      .redetect-disable {
        cursor: not-allowed !important;
        color: var(--color-text-3) !important;
        &:hover {
          color: var(--color-text-3) !important;
        }
      }
      .analyseSummary-content-btn-item {
        margin-left: 20px;
        font-family: PingFang SC;
        font-size: 14px;
        color: var(--color-text-2);
        cursor: pointer;
        &:hover {
          color: var(--color-priamry);
        }
        .el-icon {
          position: relative;
          top: 2px;
        }
        .el-dropdown-link {
          line-height: 20px;
          color: var(--color-text-2);
          i {
            transition: transform 0.3s;
          }
          &.active {
            color: #00ab7a;
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &-center {
    font-size: 14px;
    color: var(--color-text-2);
    // padding: 10px 0;
    &__detail {
      cursor: pointer;
      color: #00ab7a;
    }
    span {
      padding-right: 10px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
    }
    &__info {
      padding-top: 10px;
      box-shadow: 0 -1px 0 0 var(--color-border-2);
    }
    &__string {
      margin-top: 10px;
      padding: 8px 0;
      box-shadow: 0 -1px 0 0 var(--color-border-2);
      div {
        display:flex;
        align-items: center;
        &:hover {
          & span:nth-child(3) {
            display: block;
          }
          & :deep(.copy-icon) {
            display: block;
          }
          svg {
            display: block;
          }
        }
        & span:first-child {
          width:54px;
        }
        & span:nth-child(2) {
          padding: 0;
          max-width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & :deep(.copy-icon) {
          color: #00ab7a;
          display: none;
          font-size: 16px;
        }
        svg {
          display: none;
          color: #00ab7a;
          width: 2em;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            filter:brightness(1.2);
          }
        }
      }
    }
  }
  &-bottom {
    padding: 4px 0px 20px;
    font-size: 14px;
    font-family: PingFang SC;
    color: var(--color-text-2);
    box-shadow: 0 -1px 0 0 var(--color-border-2);
    &__content {
      box-sizing: border-box;
      min-height: 180px;
      height: calc(100vh - 748px);
      background: var(--color-bg-3);
      border: 1px solid var(--color-border-2);
      border-radius: 2px;
      padding: 6px 14px;
      overflow-y: auto;
    }
    &__listWrap {
      div {
        height:32px;
        display: flex;
        align-items: center;
        & :nth-child(1) {
          width: 40px;
          text-align: right;
        }
        & :nth-child(3) {
          flex: 1;
          display: inline-block;
          cursor: pointer;
          line-height: 18px;
          max-width: 325px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          width: 14px;
          margin: 0 8px;
        }
      }
    }
  }
}
.deleteContent {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
:deep(.share-network-weibo) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
:deep(.share-network-twitter) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
:deep(.share-network-facebook) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
</style>
