<template>
  <div class="vnc-wrap">
    <Widgest ref="widgest" isOpenAll widgestType="outer" :isBig="true">
      <template v-slot:title>
    <div class="vnc-header">
      <div class="vnc-title__wrap">
        <span style="padding-right: 10px;">{{ $t('other.vnc.prepare') }}</span>
        <span @click="handleFullScreen" v-show="props.status === 'runing'">
          <svg-icon name="quanping"></svg-icon>
        </span>
      </div>
      <div class="vnc-title__action" v-show="props.status === 'runing'">
        <div @click="switchClicked">
          <el-switch
            v-model="onlyMouse"
            @change="changeReadOnly"
            :inactive-text="`${$t('other.vnc.mouseControl')}：`"
          />
        </div>
        <!-- <span :style="{ padding: '3px 10px', cursor: onlyMouse ? 'pointer' : 'not-allowed'}" @click="showCmd">{{ $t('other.vnc.sendKey', ['Ctrl+Alt+Del']) }}</span> -->
        <el-button style="margin-left: 10px;" type="plain" :disabled="!onlyMouse" @click="showCmd">{{ $t('other.vnc.sendKey', ['Ctrl+Alt+Del']) }}</el-button>
        <el-button type="plain" @click="disconnectVnc">{{ $t('other.vnc.closeConnect') }}</el-button>
      </div>
    </div>
    </template>
    <div class="vnc-content">
      <div v-show="props.status === 'runing'" class="vnc" id="vnc-system__wrap" style="display: block;" ref="vnc"></div>
      <div v-show="props.status === 'stop'" class="vnc vnc-stop">
        <img :src="getImg(`report/vnc/stop.png`)" alt="">
        <p>{{ $t('other.vnc.dynamicEnd') }}</p>
      </div>
      <div v-show="props.status === 'close'" class="vnc vnc-stop">
        <img :src="getImg(`report/vnc/fail.png`)" alt="">
        <p>{{ $t('other.vnc.closed') }}</p>
      </div>
      <div v-show="props.status === 'connecting'" class="vnc vnc-connecting">
        <img :src="getImg(`report/vnc/connecting.png`)" alt="">
        <p>{{ $t('other.vnc.connecting') }}</p>
        <!-- <el-button type="plain" @click="cancelConnect">{{ $t('other.vnc.stopConnect') }}</el-button> -->
      </div>
      <div v-show="props.status === 'cancel'" class="vnc vnc-cancel">
        <img :src="getImg(`report/vnc/cancel.png`)" alt="">
        <p>{{ $t('other.vnc.connectCanceled') }}</p>
        <!-- <el-button type="plain" @click="reConnect">{{ $t('other.vnc.reconnect') }}</el-button> -->
      </div>
      <div v-show="props.status === 'fail'" class="vnc vnc-fail">
        <img :src="getImg(`report/vnc/fail.png`)" alt="">
        <p>{{ $t('other.vnc.connectFailed') }}</p>
        <p v-if="isSecond">VNC仅支持连接一次</p>
        <el-button v-else type="plain" @click="reConnect">{{ $t('other.vnc.reconnect') }}</el-button>
      </div>
    </div>
    </Widgest>
  </div>
</template>

<script setup>
import RFB from '@novnc/novnc/core/rfb'
import { ref, defineExpose, onBeforeUnmount, defineProps, defineEmits, watch } from 'vue'
import { getImg } from 'app/style'
import { useState } from '@/hooks/useVuex'
import { getVncToken } from 'services/report'

const props = defineProps({
  status: {
    type: String,
    default: 'runing'
  },
  taskId: {
    type: String,
    default: ''
  }
})

const { userInfo } = useState('user', ['userInfo'])

const emit = defineEmits(['changeVncStatus'])
const rfb = ref(null)
const onlyMouse = ref(!window.originConfig.human)
const vnc = ref()
const isSecond = ref(false)
const widgest = ref()
watch(onlyMouse, (val) => {
  changeReadOnly(val)
})

watch(() => props.status, v => {
  if (v === 'stop') {
    if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
      exitFullscreen()
    }
    setTimeout(() => {
      widgest.value.close()
    }, 3000)
  }
})

const switchClicked = (event) => {
  event.stopPropagation()
}

const handleFullScreen = ev => {
  ev.stopPropagation()
  if (vnc.value.requestFullscreen) {
    vnc.value.requestFullscreen()
  } else if (vnc.value.webkitRequestFullscreen) {
    vnc.value.webkitRequestFullscreen()
  } else if (vnc.value.mozRequestFullscreen) {
    vnc.value.mozRequestFullscreen()
  } else if (vnc.value.msRequestFullscreen) {
    vnc.value.msRequestFullscree()
  }
}
function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen()
  }
}
const changeReadOnly = (value) => {
  rfb.value.viewOnly = !value
}

// document.exitFullscreen().then(() => {
//   console.log('退出全屏模式')
//   document.body.style.cursor = 'auto' // 恢复鼠标可见
// }).catch((err) => {
//   console.error('无法退出全屏模式:', err)
// })
document.addEventListener('fullscreenchange', () => {
  if (!document.fullscreenElement) {
    // 已退出全屏
    // document.querySelector('#noVNC_mouse_capture_elem').click()
    document.querySelector('#noVNC_mouse_capture_elem').style.display = 'none'
  }
})
// const switchFn = ev => {
//   ev.stopPropagation()
// }
const showCmd = (ev) => {
  ev.stopPropagation()
  if (onlyMouse.value) {
    rfb.value && rfb.value.sendCtrlAltDel()
  }
  // rfb.value.sendKey(0, 'ControlLeft', true)
  // rfb.value.sendKey(0, 'ShiftLeft', true)
  // rfb.value.sendKey(0, 'Esc', true)
  // rfb.value.sendKey(0, 'Esc', false)
  // rfb.value.sendKey(0, 'ShiftLeft', false)
  // rfb.value.sendKey(0, 'ControlLeft', false)
}

// vnc连接断开的回调函数
const disconnectedFromServer = (msg) => {
  if (isManualDisconnect) return
  console.log('断开连接', msg)
  // 执行连接操作
  // clean是boolean指示终止是否干净。在发生意外终止或错误时 clean将设置为 false。
  if (msg?.detail?.clean) {
    // 根据 断开信息的msg.detail.clean 来判断是否可以重新连接
    emit('changeVncStatus', 'end')
  } else {
    // 这里做不可重新连接的一些操作
    console.log('连接不可用（可能需要密码）')
    getVncToken(props.taskId).then(res => {
      if (res.code !== 0) {
        if (res.code === 20003) {
          isSecond.value = true
          setTimeout(() => {
            widgest.value.close()
          }, 3000)
        }
        emit('changeVncStatus', 'fail')
      }
    })
  }
  // emit('changeVncStatus', 'disconnect')
  // connectVnc()
}
// 连接成功的回调函数
const connectedToServer = () => {
  emit('changeVncStatus', 'runing')
  console.log('连接成功')
}
const isIntranet = window.location.host === 'ata.360zqaq.net'

// 连接vnc的函数
const connectVnc = (token) => {
  const PASSWORD = 'xen360'
  const _rfb = new RFB(vnc.value, `${isIntranet ? 'wss://ata.360zqaq.net' : process.env.VUE_APP_VNC_API}/websockify/?token=${token}&auth=${userInfo.value.api_token}`, {
    // 向vnc 传递的一些参数，比如说虚拟机的开机密码等
    credentials: { password: PASSWORD }
  })
  _rfb.addEventListener('connect', connectedToServer)
  _rfb.addEventListener('disconnect', disconnectedFromServer)
  _rfb.addEventListener('securityfailure', securityfailureFromServer)
  // scaleViewport指示是否应在本地扩展远程会话以使其适合其容器。禁用时，如果远程会话小于其容器，则它将居中，或者根据clipViewport它是否更大来处理。默认情况下禁用。
  _rfb.scaleViewport = true
  // 是一个boolean指示是否每当容器改变尺寸应被发送到调整远程会话的请求。默认情况下禁用
  _rfb.resizeSession = true
  _rfb.clipViewport = false
  _rfb.viewOnly = window.originConfig.human
  rfb.value = _rfb
}

let isManualDisconnect = false
const disconnectVnc = (ev) => {
  ev && ev.stopPropagation()
  emit('changeVncStatus', 'disconnect')
  isManualDisconnect = true
  rfb.value && rfb.value.disconnect()
  rfb.value = null
}

const securityfailureFromServer = (detail) => {
  console.log('验证失败', detail)
}

const reConnect = () => {
  // connectVnc()
  emit('changeVncStatus', 'reconnect')
}

// const cancelConnect = () => {
//   rfb.value && rfb.value.disconnect()
//   rfb.value = null
//   emit('changeVncStatus', 'cancel')
// }

onBeforeUnmount(() => {
  disconnectVnc()
})

defineExpose({
  connectVnc,
  disconnectVnc
})
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.vnc-wrap {
  .vnc-header {
    line-height: 46px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .vnc-title__action {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-right: 16px;
      color: var(--color-text-2);
    }
  }
  .vnc-content {
    // min-height: 400px;
    .vnc {
      min-height: 400px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid var(--color-border-2);
      ::deep(div) {
        display: block !important;
      }
      &>img {
        width: 90px;
      }
    }
  }
}

</style>
