<template>
  <div class="button-tab-wrap">
    <span
      v-for="item in buttonList"
      :key="item.value"
      :class="['button-item', {active: current === item.value}]"
      @click="handleClick(item.value)"
    >{{item.label}}</span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
const emit = defineEmits(['change'])
defineProps({
  current: {
    type: String,
    default (props) {
      return props.buttonList[0].value
    }
  },
  buttonList: {
    type: Array,
    default () {
      return [
      ]
    }
  }
})

const handleClick = (value) => {
  emit('change', value)
}
</script>

<style lang="scss" scoped>
  .button-tab-wrap {
    display: inline-block;
    user-select: none;
    .button-item {
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid var(--color-border-2);
      width: 100px;
      height: 34px;
      line-height: 32px;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      z-index: 1;
      position: relative;
      // ft_color(color-button-tab);
      &.active {
        border: 1px solid var(--color-priamry);
        color: var(--color-priamry);
        z-index: 2;
      }
      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        left: -1px;
        border-radius: 0 2px 2px 0;
      }
      &:hover:not(.active) {
        color: var(--color-text-1);
      }
      &.disable {
        // ft_color(color-pagination-btn);
        // bd_color(color-button-disable);
        cursor: not-allowed;
      }
    }
  }
</style>
