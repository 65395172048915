import G6 from '@antv/g6'
import { t } from 'app/i18n'

const tooltip = new G6.Tooltip({
  itemTypes: ['node'],
  getContent(e) {
    const { data } = e.item.getModel()
    return getContent(data)
  }
})

const getContent = (data) => {
  const { taskid, md5, url, domain, ip, count, file } = data
  const outDiv = document.createElement('div')
  let contentLabel, contentValue
  if (md5 || file) {
    contentLabel = t('report.graph.file')
    contentValue = md5 || file
  } else if (url) {
    contentLabel = 'URL'
    contentValue = url
  } else if (domain) {
    contentLabel = t('report.graph.domain')
    contentValue = domain
  } else if (ip) {
    contentLabel = 'IP'
    contentValue = ip
  }
  if (!count) {
    outDiv.innerHTML = `<div class="graph-tooltip">
      <div class="graph-tooltip-item">
        <span class="graph-tooltip-label">${t('report.graph.taskid')} </span>
        <span class="graph-tooltip-value">${taskid || ''}</span>
      </div>
      <div class="graph-tooltip-item">
        <span class="graph-tooltip-label">${contentLabel}: </span>
        <span class="graph-tooltip-value">${contentValue}</span>
      </div>
    </div>`
  } else {
    outDiv.innerHTML = `<div class="graph-tooltip">
      <div class="graph-tooltip-item">
        <span class="graph-tooltip-label">${contentLabel}: </span>
        <span class="graph-tooltip-value">${contentValue}</span>
      </div>
      <div class="graph-tooltip-item">
        <span class="graph-tooltip-label">${t('report.graph.task_count')} </span>
        <span class="graph-tooltip-value">${count || ''}</span>
      </div>
    </div>`
  }
  return outDiv
}

export default tooltip
