<template>
  <div class="indicator-wrap">
    <Loading v-if="loading" :loading="loading" />
    <div v-else>
      <div class="sand-tableCollapse" v-if="threats.length !== 0">
        <div class="sand-tableCollapse__tableHeader">
          <div
            class="sand-tableCollapse__tableHeader--item"
            v-for="item in columnData"
            :key="item.prop"
            :style="`
              width: ${item.width ? getWidth(item.width) : '0px'};
              flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
              text-align: ${item.headAlign ? item.headAlign : item.align ? item.align : 'left'};
            `"
          >
            <span class="sand-tableCollapse__tableHeader--text">{{ item.label }}</span>
          </div>
        </div>
        <el-collapse v-model="collapseVal" accordion>
          <el-collapse-item
            v-for="(collapse, index) in threats"
            :key="index"
            :name="index"
          >
            <template #title>
              <div class="sand-tableCollapse__header">
                <div
                  class="sand-tableCollapse__header--item"
                  v-for="item in columnData"
                  :key="item.prop"
                  :style="`
                    width: ${item.width ? getWidth(item.width) : '0px'};
                    flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
                    text-align: ${item.align ? item.align : 'left'};
                  `"
                >
                  <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('other.views.reportPdf.safeBrainSearch')" placement="top">
                    <svg-icon @click.stop="toTi(collapse.ioc)" v-if="item.type === 'ti'" name="sousuoti"></svg-icon>
                  </el-tooltip>
                  <span class="sand-tableCollapse__header--text" v-if="item.prop === 'confidence_quota'">{{transfer(collapse[item.prop])}}</span>
                  <span class="sand-tableCollapse__header--text" v-else-if="item.prop === 'ioc_type'">{{map[collapse[item.prop]]}}</span>
                  <span class="sand-tableCollapse__header--tags" :style="{color:riskStyle(collapse[item.prop]).color,borderColor:riskStyle(collapse[item.prop]).border,background:riskStyle(collapse[item.prop]).bgColor}" v-else-if="item.prop === 'risk_quota'">{{riskStyle(collapse[item.prop]).name}}</span>
                  <span
                    v-else
                    class="sand-tableCollapse__header--text"
                    v-html="collapse[item.prop]"
                    :title="collapse[item.prop]"
                  ></span>
                </div>
              </div>
            </template>
            <div class="content">
              <div class="h2">{{ $t('other.views.reportPdf.threatIndicator') }}</div>
              <div class="detail">
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.intelligenceIndicator') }}</div>
                  <div class="value">
                    {{collapse.ioc}}
                    <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('other.views.reportPdf.safeBrainSearch')" placement="top">
                      <svg-icon name="sousuoti" @click="toTi(collapse.ioc)"></svg-icon>
                    </el-tooltip>
                  </div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.intelligenceType') }}</div>
                  <div class="value">{{map[collapse.ioc_type]}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.threatType') }}</div>
                  <div class="value">{{collapse.threaten_type.join('，')}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.credibilityIndex') }}</div>
                  <div class="value">{{transfer(collapse.confidence_quota)}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.severity') }}</div>
                  <div class="value" :style="{color: riskStyle(collapse.risk_quota).color}">{{riskStyle(collapse.risk_quota).name}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.intelligenceStatus') }}</div>
                  <div class="value">{{collapse.status ? '有效' : '过期'}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.threatOrg') }}</div>
                  <div class="value">{{collapse.threaten_organization.join('，') || '-'}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.threatFamily') }}</div>
                  <div class="value">{{collapse.threaten_family.join('，') || '-'}}</div>
                </div>
                <div class="item">
                  <div class="label">{{ $t('other.views.reportPdf.threatTags') }}</div>
                  <div class="value">
                    <el-tooltip
                      :content="item"
                      :disabled="!isShowTooltip"
                      :effect="$isDark() ? 'dark' : 'light'"
                      v-for="(item, index) in collapse.threaten_tags" :key="index"
                      placement="top-start">
                    <span class="tooltip" @mouseenter="visibilityChange($event)">
                      {{item}}
                    </span>
                  </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <sand-no-data v-else></sand-no-data>
      <div class="pagination-wrap">
      <Pagination
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :totalSize="totalSize"
        :current_page="currentPage"
        :page-size="pageSize"
        customTotal
      />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getTaskReportThreat } from 'services/report'
// import ReleaseFile from '../ReleaseFile'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useState } from '@/hooks/useVuex'
import sandNoData from '@/components/report/sandNoData'
import Pagination from '@/components/common/Pagination'
import { getColor } from 'app/style'
import { t } from 'app/i18n'

const { tiToken } = useState('user', ['tiToken'])
const columnData = [
  {
    prop: 'ioc',
    label: '情报指标',
    width: undefined,
    col: 6,
    align: 'left',
    headAlign: 'left'
  },
  {
    prop: 'ioc_type',
    label: '情报类型',
    width: undefined,
    col: 2,
    align: 'left',
    headAlign: 'left'
  },
  {
    prop: 'threaten_type',
    label: '威胁类型',
    width: undefined,
    col: 2,
    align: 'left',
    headAlign: 'left'
  },
  {
    prop: 'confidence_quota',
    label: '可信指数',
    width: undefined,
    col: 2,
    align: 'left',
    headAlign: 'left'
  },
  {
    prop: 'risk_quota',
    label: '严重程度',
    width: undefined,
    col: 3,
    align: 'left',
    headAlign: 'left'
  }
]

const map = {
  domain: '域名情报',
  ip: 'IP 情报',
  file: '文件信誉'
}

onMounted(() => {
  init()
})

const threats = ref([])
const has_permission = ref(false)
const loading = ref(true)
const collapseVal = ref([])
const totalSize = ref(0)
const currentPage = ref(1)
const pageSize = ref(50)
const isShowTooltip = ref(false)

const route = useRoute()

const taskId = route.params.taskId

function riskStyle(data) {
  switch (data) {
    case 0:
      return { name: t('common.risk.low_risk'), border: '#5a5e6d', color: '#b8bbcc', bgColor: getColor('--color-bg-3') }
    case 10:
      return { name: t('common.risk.middle_risk'), border: '#c2aa6f', color: '#d9b12f', bgColor: '#c2aa6f1a' }
    case 30:
      return { name: t('common.risk.malice'), border: '#eb726c', color: '#ff6a63', bgColor: '#ff76701a' }
    case 20:
      return { name: t('common.risk.high_risk'), border: '#eb726c', color: '#ff6a63', bgColor: '#ff76701a' }
    case 40:
      return { name: t('common.risk.not_detected'), border: '#5a5e6d', color: '#b8bbcc', bgColor: getColor('--color-bg-3') }
    case 50:
      return { name: t('other.views.reportPdf.safe'), border: '#00ab7a', color: '#00ab7a' }
    default:
      return { name: t('common.risk.not_detected'), border: '', color: '#d9b12f' }
  }
}

function transfer(key) {
  const keyMap = {
    0: '30%',
    10: '60%',
    20: '80%',
    40: '40%'
  }
  return keyMap[key]
}

function visibilityChange(event) {
  const ev = event.target
  const ev_weight = ev.scrollWidth
  const content_weight = ev.clientWidth
  if (ev_weight > content_weight) {
    isShowTooltip.value = true
  } else {
    isShowTooltip.value = false
  }
}

function toTi(params) {
  window.open(`https://ti.360.net/#/detailpage/searchresult?query=${params}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
  // window.open(`https://10.146.250.137/#/detailpage/searchresult?query=${params}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
}
async function init() {
  await getTaskReportThreat(taskId, {
    total: true
  }).then((res) => {
    totalSize.value = res.total
  })
  getList()
}

async function getList() {
  await getTaskReportThreat(taskId, {
    page: currentPage.value,
    size: pageSize.value
  }).then((res) => {
    has_permission.value = res.has_permission
    threats.value = res.quotas || []
  })
  loading.value = false
}

function handleSizeChange (size) {
  pageSize.value = size
  getList()
}
function handleCurrentChange (page) {
  currentPage.value = page
  getList()
}
</script>

<style lang="scss" scoped>
.indicator-wrap {
  padding: 24px 0 0 0;
  box-sizing: border-box;
  .indicator {
    min-height: auto;
  }
}
.sand-tableCollapse {
  padding: 0 0 24px;
  .sand-tableCollapse__tableHeader {
    display: flex;
    height: 48px;
    line-height: 48px;
    color: var(--color-text-2);
    background-color: var(--color-bg-3);
    padding: 0 53px 0 15px;
  }
  & :deep(.el-collapse), & :deep(.el-collapse-item__header), & :deep(.el-collapse-item__wrap) {
    border: 0;
    background: transparent;
  }
  & :deep(.el-collapse-item__header) {
    height: 47px;
    font-weight: normal;
    background: var(--detect-config-bg);
    border-bottom: 1px solid var(--color-border-2);
    &:hover {
      & .el-icon-arrow-right:before {
        filter: brightness(--svg-filter2);
      }
    }
    &.is-active {
      .sand-tableCollapse__header .sand-tableCollapse__header--item {
        .sand-tableCollapse__header--text, svg {
          color: var(--color-text-1);
        }
      }
    }
  }
  & .sand-tableCollapse__header {
    width: 100%;
    display: flex;
    padding: 0 15px;
    height: 40px;

    &--item {
      flex-shrink: 0;
      align-items: center;
      display: flex;
    }

    &--text {
      width: 100%;
      line-height: 22px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--color-text-2);
    }
    &--tags {
      border: 1px solid;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      padding: 0 10px;
      line-height: 20px;
    }
    svg {
      font-size: 20px;
      color: var(--color-text-2);
      &:hover {
        color: var(--color-text-1);
      }
    }

    &--download {
      height: 22px;
      background: transparent;
      line-height: 22px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;

      svg {
        font-size: 14px;
      }

      &.disable {
        filter: grayscale(1);
        cursor: not-allowed;
      }
    }
  }
  & :deep(.el-collapse-item__arrow) {
    color: var(--color-text-3);
    &.is-active {
      color: var(--color-text-1);
    }
  }
  :deep(.el-collapse) {
      .el-collapse-item__content {
        padding: 0;
      }
    }
  .content {
    padding: 20px;
    background: var(--color-bg-3);
    .h2 {
      font-size: 16px;
      color: var(--color-text-1);
      height: 48px;
      line-height: 48px;
      box-shadow: 0 1px 0 0 var(--color-border-2);
    }
    .detail {
      margin-top: 20px;
      .item {
        display: flex;
        margin-top: 10px;
        .label {
          font-size: 14px;
          color: var(--color-text-3);
          font-weight: 500;
          margin-right: 40px;
          min-width: 56px;
          line-height: 30px;
        }
        .value {
          font-size: 14px;
          color: var(--color-text-2);
          display: flex;
          align-items: center;
          flex-flow: wrap;
          flex: 1;
          svg {
            margin-left: 6px;
            font-size: 16px;
            color: var(--color-text-3);
            cursor:pointer;
            &:hover {
              color: var(--color-text-1);
            }
          }
          .tooltip {
            background: rgba(90,94,109,.3);
            color: var(--color-text-2);
            border-radius: 2px;
            border: 1px solid var(--color-border-1);
            padding: 2px 10px;
            margin-right: 6px;
            margin-bottom: 6px;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
.pagination-wrap {
  :deep(.el-pagination__sizes) {
    display: none;
  }
  :deep(.btn-prev) {
    display: none;
  }
  :deep(.el-pager) {
    display: none;
  }
  :deep(.btn-next) {
    display: none;
  }
}
</style>
