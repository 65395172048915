<template>
  <div class="home-page">
    <div class="title">
      <img src="@/assets/img/homepage.png" alt="logo" />
    </div>
    <div class="task-report">{{ detail.category === 'url' ? 'URL' : $t('taskhub.history.file') }}{{$t('other.components.report.analysisReport')}}</div>
    <div class="task-desc">
      <div v-for="(item, key) in keyMap" :key="key" class="task-desc-item">
        <span class="label">{{ item.label }}&nbsp;</span>
        <span class="value" v-if="!item.isCode">
          <span>{{ item.value }}</span>
          <span v-if="detail.category === 'url' && item.label.indexOf('URL') > -1" style="color: #ff5967;">{{$t('other.components.report.urlRiskTip')}}</span>
        </span>
        <span class="value" v-else>
          <vue-qrcode margin="0" :value="item.value" />
        </span>
      </div>
    </div>
    <div class="pagenum"></div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import VueQrcode from 'vue-qrcode'
import { computed, defineProps } from 'vue'
import { t } from 'app/i18n'

const props = defineProps({
  detail: {
    default: () => {
      return {

      }
    }
  }
})

const keyMap = computed(() => {
  if (props.detail.category === 'url') {
    return {
      keyword: {
        // label: 'URL名称',
        label: t('other.components.report.urlName'),
        value: props.detail.keyword
      },
      md5: {
        label: 'MD5',
        value: props.detail.md5
      },
      sha1: {
        label: 'SHA1',
        value: props.detail.sha1
      },
      sha256: {
        label: 'SHA256',
        value: props.detail.sha256
      },
      link: {
        // label: '报告链接',
        label: t('other.components.report.reportUrl'),
        value: `https://ata.360.net/report/${props.detail.taskId}`
      },
      qrcode: {
        // label: '扫码查看',
        label: t('other.components.report.qrcodeView'),
        isCode: true,
        value: `https://ata.360.net/report/${props.detail.taskId}`
      },
      time: {
        // label: '下载时间',
        label: t('other.components.report.downloadTime'),
        value: formatDate(new Date().getTime() + 8 * 60 * 60 * 1000)
      }
    }
  }
  return {
    keyword: {
      // label: '文件名称',
      label: t('report.static.info.file_label.name'),
      value: props.detail.keyword
    },
    md5: {
      label: 'MD5',
      value: props.detail.md5
    },
    sha1: {
      label: 'SHA1',
      value: props.detail.sha1
    },
    sha256: {
      label: 'SHA256',
      value: props.detail.sha256
    },
    filetype: {
      // label: '文件类型',
      label: t('report.overview.file_type'),
      value: props.detail.filetype
    },
    link: {
      // label: '报告链接',
      label: t('other.components.report.reportUrl'),
      value: `https://ata.360.net/report/${props.detail.taskId}`
    },
    qrcode: {
      // label: '扫码查看',
      label: t('other.components.report.qrcodeView'),
      isCode: true,
      value: `https://ata.360.net/report/${props.detail.taskId}`
    },
    time: {
      // label: '下载时间',
      label: t('other.components.report.downloadTime'),
      value: formatDate(new Date().getTime() + 8 * 60 * 60 * 1000)
    }
  }
})

const formatDate = (...args) => {
  return dayjs(...args).format('YYYY-MM-DD HH:mm:ss')
}
</script>

<style lang="scss" scoped>
.home-page {
  height: 1105pt;
  padding-top: 350pt;
  padding-left: 100pt;
  box-sizing: border-box;
  .title {
    margin-bottom: 10px;
    width: 300px;
    img {
      width: 100%;
    }
  }
  .task-report {
    font-size: 20pt;
    margin-bottom: 30pt;
    color: var(--color-text-2);
    font-weight: bold;
  }
  .task-desc {
    font-size: 10.5pt;
    .task-desc-item {
      display: flex;
      margin-bottom: 16px;
      .label {
        flex-shrink: 0;
        display: inline-block;
        color: var(--color-text-2);
        width: 90px;
        font-weight: 600;
      }
      .value {
        flex: 1;
        color: var(--color-text-1);
        word-break: break-all;
      }
    }
  }
}
</style>
