<template>
  <div class="static-search-skill-wrap" v-if="show">
    <Widgest isOpenAll widgestType="outer" :isBig="true">
      <template v-slot:title>{{ $t('other.views.reportPdf.staticJudge') }}</template>
      <StaticTable v-if="category !== 'url'" :zserver="staticsearchskill.zserver" />
      <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
      <SandGradeCollapseGroup v-if="convertSignature.length > 0"
        v-for="item in convertSignature"
        :key="item.title"
        :data="item">
        <template v-slot="{ childrenData }">
          <!-- <SandAndroidSignature
            v-if="taskOS === 'android'"
            :data="childrenData.marks.map(item => ({...item, info: JSON.parse(item.ext)}))"
          /> -->
          <SandTable
            class="attck-table"
            :data="handleTableData(childrenData.marks)"
          />
        </template>
      </SandGradeCollapseGroup>
    </Widgest>
  </div>
</template>

<script>
// 分析概览 静态判定 模块
import SandGradeCollapseGroup from '@/components/report/sandGradeCollapseGroup'
import SandTable from '@/components/report/sandTable'
// import SandAndroidSignature from '@/components/report/sandAndroidSignature'
import StaticTable from '@/components/report/staticTable'
import cloneDeep from 'lodash/cloneDeep'
import keyMap from '@/assets/mock/eventdesc.json'
import Widgest from '@/components/common/widgest'
import { t } from 'app/i18n'

export default {
  name: 'StaticSearchSkill',
  components: {
    SandGradeCollapseGroup,
    // SandAndroidSignature,
    SandTable,
    StaticTable,
    Widgest
  },
  props: {
    staticsearchskill: {
      type: Object
    },
    category: {
      type: String
    }
  },
  data () {
    return {
      columnData: [{
        prop: 'engine_name',
        label: t('other.views.reportPdf.engineName'),
        width: '400',
        align: 'left',
        icon: 'flow'
      },
      {
        prop: 'virus_name',
        label: t('other.views.reportPdf.detectionResult'),
        type: 'border',
        align: 'left',
        icon: 'loophole'
      }],
      keyMap
    }
  },
  computed: {
    convertSignature () {
      return this.staticsearchskill.signatures_genre ? this.staticsearchskill.signatures_genre.map(item => {
        return {
          title: item.titlezhcn,
          childrenData: item.data.sort((a, b) => {
            return b.severity - a.severity
          }).map(item2 => {
            return {
              title: `${item2.descripzhcn} (${item2.marks.length}${t('other.views.reportPdf.events')})`,
              type: item2.severity === 1 ? 'low' : (item2.severity === 2 ? 'middle' : 'high'),
              childrenData: {
                marks: item2.marks,
                attackid: item2.attackid
              }
            }
          })
        }
      }).filter(item => item.childrenData.length > 0) : []
    },
    taskOS () {
      return this.staticsearchskill.taskOS
    },
    show() {
      if (this.category === 'url' && !this.convertSignature.length) {
        return false
      }
      return true
    }
  },
  methods: {
    handleTableData (list) {
      if (list.length === 0) {
        return { columnData: [], tableData: [] }
      }
      const copyList = cloneDeep(list)
      let columnData
      let tableData
      const type = copyList[0].type
      if (type === 'call') {
        // 带表头的横向表格
        columnData = [
          {
            prop: 'time',
            label: '事件',
            col: 2
          }, {
            prop: 'arguments',
            label: '参数',
            col: 3
          }, {
            prop: 'status',
            label: '状态'
          }, {
            prop: 'return_value',
            label: '返回值'
          }, {
            prop: 'pid',
            label: '进程'
          }, {
            prop: 'tid',
            label: '线程'
          }, {
            prop: 'repeated',
            label: '重复'
          }
        ]
        tableData = copyList.map(item => ({
          ...item.call,
          time: `<span>${item.call.time}</span><br /><span style="font-weight: bold;">${item.call.api}</span>`,
          arguments: Object.keys(item.call.arguments).map(key => {
            return `<span>${key}: ${item.call.arguments[key]}</span>`
          }).join('</br>'),
          status: item.call.status ? t('common.success') : t('common.failed')
        }))
      } else if (type === 'generic') {
        // 纵向表头
        const result = copyList.map(item => {
          delete item.type
          return item
        })
        columnData = Object.keys(result[0]).map(item => ({
          prop: item,
          label: this.keyMap[item] || item,
          titleWidth: '120'
        }))
        tableData = result
      } else {
        columnData = [{
          prop: 'ioc',
          label: this.keyMap[copyList[0].category] || copyList[0].category,
          titleWidth: '120'
        }]
        tableData = copyList
      }
      return { columnData, tableData, tableProps: { isHorizontal: type !== 'call' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.static-search-skill-wrap  {
  .search-skill-header  {
    display: flex;
    align-items: center;
    .header-wrap__line  {
      display: inline-block;
      height: 16px;
      width: 4px;
      margin-right: 6px;
    }
    .header-wrap__title  {
      font-size: 16px;
      margin-right: 16px;
      font-weight: 500;
    }
    .header-wrap__type  {
      display: inline-block;
      color: var(--color-text-1);
      border-radius: 2px;
      padding: 1px 9px;
      font-size: 14px;
      line-height: 20px;
      background: #7E7F86;
    }
    .header-wrap__type.green  {
      background: #3EAE89;
    }
    .header-wrap__type.orange  {
      background: #FA9E16;
    }
    :deep(.sand-gradeCollapseGroup)  {
      margin-top: 20px;
    }
  }
  .search-skill-header+.search-skill-header  {
    margin-top: 20px;
  }
  .search-skill-table  {
    margin: 16px 0 18px;
    border-collapse: collapse;
    width: 100%;
    tr  {
      th, td  {
        height: 40px;
        box-sizing: border-box;
        padding: 5px 20px;
        font-size: 14px;
        border: 1px solid;
        text-align: left;
        i  {
          margin-right: 10px;
          vertical-align: middle;
        }
        &:first-child  {
          width: 380px;
        }
        &:not(:last-child)  {
          border-right: 0;
        }
      }
      th  {
        font-weight: 500;
      }
      td  {
        font-weight: 400;
      }
    }
  }
  .attck-table  {
    margin: 16px 20px;
  }
}
</style>
