<template>
  <div>
    <div style="padding: 0 20px 30px 20px;">
      <!-- 信息总览 -->
      <!-- <TaskStatus :progress="progress" :taskId="taskId" :progressStatus="taskInfo.status" :onlyIncludeStatic="onlyIncludeStatic"></TaskStatus> -->
      <TaskStatus :key="taskId" v-if="show" :progress="progress" :progressStatus="taskInfo.status" :taskInfo="taskInfo" :taskId="taskId" :onlyIncludeStatic="onlyIncludeStatic"></TaskStatus>
      <template v-if="progress > 30">
        <!-- 机器学习引擎 -->
        <MachineLearning v-if="isNotEmptyEngine(state.machinelearning)" id="machinelearning" :machinelearning="state.machinelearning" />
        <!-- 静态判定 -->
        <StaticSearchSkill
          v-if="isNotEmpty(state.staticsearchskill)"
          id="staticsearchskill"
          :category="state.category"
          :staticsearchskill="state.staticsearchskill"
        />
        <!-- attck 矩阵 -->
        <MitreAttck
          v-if="progress === 100"
          id="mitreattck"
          :behaviorfeature="state.behaviorfeature"
          :onlyIncludeStatic="onlyIncludeStatic"
        />
        <!-- 行为特征 -->
        <BehaviorFeature
          v-if="isNotEmpty(state.behaviorfeature) && state.behaviorfeature.signatures && state.behaviorfeature.signatures.length && progress === 100"
          id="behaviorfeature"
          :behaviorfeature="state.behaviorfeature"
        />
        <template v-if="!onlyIncludeStatic && progress === 100">
          <BehaviorScreenshot
            v-if="state.behaviorscreenshot && state.behaviorscreenshot.shots && state.behaviorscreenshot.shots.length"
            id="behaviorscreenshot"
            :behaviorscreenshot="state.behaviorscreenshot"
          />
          <!-- 网络行为 -->
          <NetworkBehavior
            v-if="state.networkCount"
            id="networkbehavior"
            :taskId="taskId"
            :networkCount="state.networkCount"
            type="overview"
          />
          <!-- 行为总览 -->
          <BehaviorSummary id="behaviorsummary" :taskid="taskId" :progressanalyse="state.progressanalyse" />
        </template>
        <NetworkBehavior
            v-if="state.networkCount && onlyIncludeStatic && state.taskInfo.file_type === 'tcpdump'"
            id="networkbehavior"
            :taskId="taskId"
            :networkCount="state.networkCount"
            type="overview"
          />
      </template>
    </div>
    <detail-footer/>
  </div>
</template>

<script>

import eventDesc from '@/assets/markdown/event_description.json'
import StaticSearchSkill from '../StaticSearchSkill'
import MachineLearning from '../MachineLearning'
import MitreAttck from '../MitreAttck'
import BehaviorFeature from '../BehaviorFeature'
import BehaviorScreenshot from '../BehaviorScreenshot'
import NetworkBehavior from '../NetworkBehavior'
import BehaviorSummary from '../BehaviorSummary'
import TaskStatus from '../taskStatus'
import { resetTransition } from '@/hooks/useDom'

export default {
  name: 'analyse',
  components: {
    StaticSearchSkill,
    MachineLearning,
    MitreAttck,
    BehaviorFeature,
    BehaviorScreenshot,
    NetworkBehavior,
    BehaviorSummary,
    TaskStatus
  },
  props: {
    taskInfo: {
      type: Object
    },
    summary: {
      type: Object
    },
    onlyIncludeStatic: {
      type: Boolean
    },
    progress: {
      type: Number
    },
    taskid: {
      type: Number
    },
    state: {
      type: Object
    },
    showProgress: {
      type: Boolean
    }
  },
  methods: {
    isNotEmpty(obj) {
      return Object.keys(obj).length !== 0
    },
    isNotEmptyEngine(obj) {
      if (this.state.machinelearning instanceof Array) {
        return this.state.machinelearning.length > 0
      } else {
        return this.state.machinelearning && this.state.machinelearning.detect && this.state.machinelearning.detect.length > 0
      }
    }
  },
  mounted() {
    resetTransition()
  },
  data() {
    return {
      staticsearchskill: {},
      machinelearning: {},
      behaviorfeature: {},
      behaviorscreenshot: {},
      networkCount: {},
      progressanalyse: {},
      category: 'file',
      taskOS: '',
      events: eventDesc.category,
      show: this.showProgress
    }
  },
  computed: {
    taskId() {
      return this.$route.params.taskId || this.taskid
    },
    progree_combined() {
      return {
        showProgress: this.showProgress,
        taskid: this.taskid
      }
    }
  },
  watch: {
    // showProgress(n, o) {
    //   const that = this
    //   if (!n) {
    //     setTimeout(() => {
    //       that.show = false
    //     }, 2000)
    //   } else {
    //     that.show = true
    //   }
    // },
    progree_combined(n, o) {
      const that = this
      if (!n.showProgress) {
        if (n.taskid !== o.taskid) {
          this.show = false
        } else {
          setTimeout(() => {
            that.show = false
          }, 2000)
        }
      } else {
        this.show = true
      }
    }
  }
}
</script>
