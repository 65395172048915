<template>
  <div class="behavior-screenshot-wrap">
    <Widgest isOpenAll icon="behaviorscreenshot" widgestType="outer" :isBig="true">
      <template v-slot:title>{{$t('report.analyse.screenshoot')}}</template>
      <SandSwiper class="screen-shot"
        :data="{
        slidesPerView: 6,
        swiperData: shots
        }"
        :isHorizontal="behaviorscreenshot.taskOS !== 'android'"
      />
    </Widgest>
  </div>
</template>

<script>
// 分析概览中运行截图模块
import SandSwiper from '@/components/report/sandSwiper'
export default {
  name: 'BehaviorScreenshot',
  components: {
    SandSwiper
  },
  props: {
    behaviorscreenshot: {
      type: Object
    }
  },
  computed: {
    shots () {
      return this.behaviorscreenshot.shots
    }
  }
}
</script>

<style lang="scss" scoped>
  .behavior-screenshot-wrap {
    .screen-shot {
      width: 100%;
    }
  }
</style>
