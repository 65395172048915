<template>
  <div class="behavior-feature-wrap">
    <Widgest isOpenAll widgestType="outer" :isBig="true">
      <template v-slot:title>{{$t('report.analyse.behavior.title')}}</template>
      <div class="three-behavior">
        <sand-no-data v-if="convertSignature.length === 0" />
        <SandGradeCollapseGroup
          v-else
          v-for="item in convertSignature"
          :data="item"
          :key="item.title">
          <template v-slot="{ childrenData }">
            <div class="attck-id" v-if="attackidSep(childrenData.attackid).length > 0">
              <span>ATT&CK ID：</span>
              <span
                v-for="(id, index) in attackidSep(childrenData.attackid)"
                :key="index"
                @click="handleAttackIdClick(id)"
                class="attck-id-item"
              >{{id}}</span>
            </div>
            <SandTable
              v-if="childrenData.marks[0] && childrenData.marks[0].type !== 'event'"
              class="attck-table"
              :data="handleTableData(childrenData.marks)"
            />
            <div v-else style="padding: 0 20px;">
              <BehaviorEvents :events="childrenData.marks" />
            </div>
          </template>
        </SandGradeCollapseGroup>
      </div>
      <el-dialog
        v-model="mitreShow"
        width="1200px"
        class="attck-dialog-class"
        @close="showDetail = false"
      >
        <template #title>
          <div class="title-wrap">
            <span class="title">{{$t('report.analyse.behavior.result')}}</span>
            <el-checkbox v-if="!showDetail" v-model="showAll" class="check">{{$t('report.analyse.behavior.show_all')}}</el-checkbox>
          </div>
        </template>
        <div v-if="!showDetail" class="attckTable-wrap animate__animated animate__fadeInLeft animate__faster">
          <table class="mitre-table">
            <tr class="mitre-table-header">
              <th v-for="(v, index) in mitre" :key="index" class="mitre-th">{{v.name}}</th>
            </tr>
            <tr class="mitre-table-row" v-for="(i, rowIndex) in mitreMaxLen" :key="rowIndex">
              <td
                :class="['mitre-table-col', {
                  match: item.techiques.length > rowIndex && sigs[item.techiques[rowIndex].id]
                }]"
                v-for="(item, colIndex) in showAttckMitre"
                :key="colIndex"
              >
                <span v-if="item.techiques.length > rowIndex && sigs[item.techiques[rowIndex].id]" @click="attckClick(item.techiques[rowIndex])">
                  {{item.techiques.length > rowIndex ? item.techiques[rowIndex].name : ''}}
                  <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                  <div class="tagWrapper">
                    <template v-for="(c, x) in tagCalc(item.techiques[rowIndex].id)">
                      <span v-if="c !== 0" :key="x" :class="`flag-${x}`">
                        {{ c }}
                      </span>
                    </template>
                  </div>
                </span>
                <span v-else>
                  {{item.techiques.length > rowIndex ? item.techiques[rowIndex].name : ''}}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="attckDetail-wrap">
          <!-- <div class="attckDetail-wrap__back" @click="showDetail = false">
            <span>
              <i class="el-icon-back"></i>
              返回总览
            </span>
          </div> -->
          <div class="detail-mitre">
            <div class="row">
              <div>ATT&CK ID</div>
              <div>{{ attck.id }}</div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.name')}}</div>
              <div>{{ attck.name }}</div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.tactics')}}</div>
              <div>{{ attck.tactic }}</div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.required_permissions')}}</div>
              <div>{{ attck.x_mitre_permissions_required ? attck.x_mitre_permissions_required.join(',') : '' }}</div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.explain')}}</div>
              <div v-html="attck.description" class="description-wrap"></div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.source')}}</div>
              <div><a :href="attck.url" rel="noreferrer" target="_blank">{{ attck.url }}</a></div>
            </div>
            <div class="row">
              <div>{{$t('report.analyse.mitre_att.dialog.target')}}</div>
              <div>
                <ul>
                  <li
                    v-for="item in sigs[attck.id]"
                    :key="item.desc"
                    :style="{
                      color: item.severity === 1 ? '#537EF3' : (item.severity === 2 ? '#E69F32' : '#F06D67')
                    }"
                  >
                    {{item.desc}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </Widgest>
  </div>
</template>

<script>
// 分析概览中行为判定模块
import mitre from 'utils/lib/AttckData'
import mitreEn from 'utils/lib/AttckData.en-US.js'
import cloneDeep from 'lodash/cloneDeep'
import SandGradeCollapseGroup from '@/components/report/sandGradeCollapseGroup'
import SandTable from '@/components/report/sandTable'
import sandNoData from '@/components/report/sandNoData'
import BehaviorEvents from './BehaviorEvents'
// import BehaviorSignature from '@/components/report/behaviorSignature'
import keyMap from '@/assets/mock/eventdesc.json'
import { useI18n } from 'vue-i18n'
import { t, isZh } from 'app/i18n'

export default {
  name: 'BehaviorFeature',
  components: {
    SandGradeCollapseGroup,
    SandTable,
    sandNoData,
    BehaviorEvents
    // BehaviorSignature
  },
  props: {
    behaviorfeature: {
      type: Object
    }
  },
  computed: {
    isZhcn() {
      const i18n = useI18n()
      return i18n.locale.value !== 'en'
    },
    signatures () {
      return this.behaviorfeature.signatures
    },
    signatures_genre () {
      return this.behaviorfeature.signatures_genre
    },
    convertSignature () {
      return this.signatures_genre.map(item => {
        return {
          title: this.isZhcn ? item.titlezhcn : item.titleenus,
          lockData: item.count,
          childrenData: item.data.sort((a, b) => {
            return b.severity - a.severity
          }).map(item2 => {
            return {
              title: `${this.isZhcn ? item2.descripzhcn : item2.description} (${t('report.analyse.indicator.marks', [item2.marks.length])})`,
              type: item2.severity === 1 ? 'low' : (item2.severity === 2 ? 'middle' : 'high'),
              desc: this.isZhcn ? item2.descripzhcn : item2.description,
              severity: item2.severity,
              childrenData: {
                marks: item2.marks,
                attackid: item2.attackid
              }
            }
          })
        }
      })
    },
    sigs () {
      const ret = {}
      if (this.signatures) {
        this.signatures.forEach(ele => {
          if (ele.attackid) {
            ele.attackid.split(',').forEach(id => {
              if (ret[id] === undefined) {
                ret[id] = []
              }
              ret[id].push({
                id: id,
                desc: isZh() ? ele.descripzhcn : ele.description,
                severity: ele.severity
              })
            })
          }
        })
      }
      return ret
    },
    mitreMaxLen () {
      return this.showAttckMitre.map(item => item.techiques.length).reduce((prev, cur) => Math.max(prev, cur), 0)
    },
    showAttckMitre () {
      return this.showAll ? this.mitre : this.attckMitre
    },
    attckMitre () {
      let list = []
      let list2 = []
      for (const i in this.sigs) {
        list = list.concat(this.sigs[i])
      }
      list2 = this.mitre.map(item => {
        return {
          ...item,
          techiques: item.techiques.filter(item2 => list.find(item3 => item3.id === item2.id))
        }
      })
      return list2
    }
  },
  data () {
    return {
      seprateData: [],
      mitreShow: false,
      mitre: isZh() ? mitre : mitreEn,
      showAll: false,
      attck: null,
      showDetail: false,
      sev: {
        blue: 'informative',
        warning: 'suspicious',
        error: 'malicious'
      },
      keyMap
    }
  },
  methods: {
    attackidSep (str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    handleAttackIdClick (id) {
      const target = this.mitre.find(item => item.techiques.find(item2 => item2.id === id)).techiques.find(item => item.id === id)
      this.mitreShow = true
      this.attckClick(target)
    },
    attckClick (t) {
      if (!this.sigs[t.id]) return
      this.attck = t
      this.showDetail = true
    },
    tagCalc (id) {
      var ret = {
        blue: 0,
        warning: 0,
        error: 0
      }
      if (this.sigs && this.sigs[id]) {
        this.sigs[id].forEach(function (ele) {
          if (ele.severity === 1) {
            ret.blue++
          } else if (ele.severity === 2) {
            ret.warning++
          } else if (ele.severity >= 3) {
            ret.error++
          }
        })
      }
      return ret
    },
    handleTableData (list) {
      if (list.length === 0) {
        return { columnData: [], tableData: [] }
      }
      const copyList = cloneDeep(list)
      let columnData
      let tableData
      const type = copyList[0].type
      if (type === 'call') {
        // 带表头的横向表格
        columnData = [
          {
            prop: 'time',
            label: this.isZhcn ? '时间' : 'TIME',
            col: 2
          }, {
            prop: 'arguments',
            label: this.isZhcn ? '参数' : 'ARGUMENTS',
            col: 3
          }, {
            prop: 'status',
            label: this.isZhcn ? '状态' : 'STATUS'
          }, {
            prop: 'return_value',
            label: this.isZhcn ? '返回值' : 'RETURN'
          }, {
            prop: 'pid',
            label: this.isZhcn ? '进程' : 'PROCESS'
          }, {
            prop: 'tid',
            label: this.isZhcn ? '线程' : 'THREAD'
          }, {
            prop: 'repeated',
            label: this.isZhcn ? '重复' : 'REPEATED'
          }
        ]
        tableData = copyList.map(item => ({
          ...item.call,
          time: `<span>${item.call.time}</span><br /><span style="font-weight: bold;">${item.call.api}</span>`,
          arguments: Object.keys(item.call.arguments).map(key => {
            return `<span>${key}: ${item.call.arguments[key]}</span>`
          }).join('<br/>'),
          status: item.call.status ? t('success') : t('failed')
        }))
      } else if (type === 'generic') {
        // 纵向表头
        const result = copyList.map(item => {
          delete item.type
          return item
        })
        columnData = Object.keys(result[0]).map(item => ({
          prop: item,
          label: this.isZhcn ? (this.keyMap[item]?.zhcn || item) : this.keyMap[item]?.enus,
          titleWidth: '120'
        }))
        tableData = result
      } else {
        columnData = [{
          prop: 'ioc',
          label: this.isZhcn ? (this.keyMap[copyList[0].category]?.zhcn || copyList[0].category) : this.keyMap[copyList[0].category]?.enus || copyList[0].category,
          titleWidth: '120'
        }]
        tableData = copyList
      }
      return { columnData, tableData, tableProps: { isHorizontal: type !== 'call' } }
    },
    convertBorder (obj) {
      if (obj.error) {
        return 'red'
      } else if (obj.warning) {
        return 'orange'
      } else if (obj.blue) {
        return 'blue'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[lang="en"] {
  .behavior-feature-wrap {
    .attck-dialog-class {
      .el-dialog__body .attckDetail-wrap .detail-mitre .row div:first-child {
        width: 166px;
      }
    }
  }
}
.behavior-feature-wrap  {
  .three-behavior  {
    .attck-id  {
      font-size: 14px;
      margin: 0px 20px 16px;
      color: var(--color-text-2);
      .attck-id-item  {
        color: #00ab7a;
        cursor: pointer;
        margin-right: 5px;
        &:hover  {
          filter: brightness(1.2);
        }
      }
    }
    .attck-table  {
      margin: 0 20px;
    }
  }
  :deep(.attck-dialog-class)  {
    overflow: hidden;
    min-width: 1200px;
    height: 600px;
    background: var(--detect-config-bg);
    .el-dialog__header  {
      .title-wrap  {
        display: flex;
        justify-content: space-between;
        margin-right: 70px;
        // @include ft_color(color-center-label);
        .title  {
          font-size: 18px;
        }
        .check  {
          .el-checkbox__label  {
            // @include ft_color(color-center-label);
          }
          .el-checkbox__inner  {
            background: transparent;
          }
        }
      }
      .el-dialog__headerbtn  {
        .el-dialog__close  {
          font-size: 20px;
          color: #868EC2;
          // &:hover  {
          //   filter: brightness(3);
          // }
        }
      }
    }
    .el-dialog__body  {
      padding: 10px 24px 30px 0;
      max-height: 70vh;
      .attckTable-wrap  {
        display: flex;
        flex-direction: column;
        .el-checkbox  {
          .el-checkbox__input  {
            .el-checkbox__inner  {
              // @include bg_color(color-button-disable);
            }
          }
          .el-checkbox__label  {
            // @include ft_color(color-center-value);
          }
        }
        .mitre-table  {
          border-collapse: collapse;
          .mitre-table-header  {
            .mitre-th  {
              height: 40px;
              font-size: 12px;
              color: #E0E4EB;
              background: #2c3142;
              border: 1px solid var(--color-border-2);
              font-weight: 400;
            }
          }
          .mitre-table-row  {
            .mitre-table-col  {
              width: calc(100% / 12);
              height: 44px;
              color: var(--color-text-3);
              border: 1px solid var(--color-border-2);
              font-size: 12px;
              vertical-align: top;
              padding: 4px 7px;
              box-sizing: border-box;
              line-height: 16px;
              position: relative;
              &.match  {
                color: var(--color-text-1);
                cursor: pointer;
              }
               .tagWrapper {
                position: absolute;
                bottom: 3px;
                right: 7px;
              }
              .flag-blue, .flag-warning, .flag-error  {
                display: inline-block;
                width: 14px;
                height: 14px;
                font-size: 10px;
                color: var(--color-text-1);
                text-align: center;
                line-height: 14px;
                border-radius: 1px;
                &+[class^="flag-"]  {
                  margin-left: 4px;
                }
              }
              .flag-blue  {
                background: #00ab7a;
              }
              .flag-warning  {
                background: #E69F32;
              }
              .flag-error  {
                background: #ff5967;
              }
            }
          }
        }
      }
      .attckDetail-wrap  {
        span  {
          cursor: pointer;
        }
        &__back  {
          line-height: 20px;
          color:#00ab7a;
          .el-icon-back  {
            vertical-align: middle;
            font-size: 20px;
          }
        }
        .detail-mitre  {
          padding: 20px;
          max-height: 70vh;
          .row  {
            display: flex;
            margin-bottom: 12px;
            div:first-child  {
              width: 116px;
              margin-right: 50px;
              font-weight: 700;
              font-size: 14px;
              color: var(--color-text-1);
              text-align: right;
            }
            div:last-child  {
              flex: 1;
              font-size: 14px;
              // @include ft_color(color-center-value);
              a:link, a:visited  {
                color: #537EF3;
              }
            }
            .description-wrap  {
              height: 252px;
              padding: 4px 20px;
              overflow-y: scroll;
              background: var(--color-bg-3);
              border: 1px solid var(--color-border-2);
              border-radius: 2px;
              p  {
                line-height: 1.5;
              }
            }
            ul  {
              padding-inline-start: 20px;
              margin: 0;
              li  {
                list-style-type: disc;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
