<template>
  <Loading v-if="loading" :loading="loading" />
  <div class="wrapper" v-if="!loading && !showErrPage">
    <home-page :detail="homepageInfo" />
    <template v-if="code === 0">
      <analyse-summary
        :taskInfo="taskInfo"
        :summary="summary"
        :progress="progress"
        :processes="state.progressanalyse.processes"
        :onlyIncludeStatic="onlyIncludeStatic"
        :isRunning="isRunning"
        :behaviorscreenshot="state.behaviorscreenshot"
      />
      <div class="content">
        <div class="module-item">
          <!-- <div class="module-item__title">{{ com.label }}</div> -->
          <analyse
            class="contentWrapper"
            :class="{ noFlex : currentModule === 'static'}"
            :taskInfo="taskInfo"
            :summary="summary"
            :progress="progress"
            :state="state"
            :onlyIncludeStatic="onlyIncludeStatic"
            :showProgress = "showProgress"
            :isRunning="isRunning"
          />
        </div>
        <div class="module-item">
          <div class="module-item__title">{{ $t('other.views.reportPdf.releaseFiles') }}</div>
          <dropped
            class="contentWrapper"
            :class="{ noFlex : currentModule === 'static'}"
            :taskInfo="taskInfo"
            :summary="summary"
            :progress="progress"
            :state="state"
            :onlyIncludeStatic="onlyIncludeStatic"
            :showProgress = "showProgress"
            :isRunning="isRunning"
          />
        </div>
        <div class="module-item">
          <div class="module-item__title">{{ $t('other.views.reportPdf.freeMemory') }}</div>
          <releaseMemory
            class="contentWrapper"
            :class="{ noFlex : currentModule === 'static'}"
            :taskInfo="taskInfo"
            :summary="summary"
            :progress="progress"
            :state="state"
            :onlyIncludeStatic="onlyIncludeStatic"
            :showProgress = "showProgress"
            :isRunning="isRunning"
          />
        </div>
        <div class="module-item">
          <div class="module-item__title">{{ $t('other.views.reportPdf.threatIndicator') }}</div>
          <indicator
            class="contentWrapper"
            :class="{ noFlex : currentModule === 'static'}"
            :taskInfo="taskInfo"
            :summary="summary"
            :progress="progress"
            :state="state"
            :onlyIncludeStatic="onlyIncludeStatic"
            :showProgress = "showProgress"
            :isRunning="isRunning"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="error">
        <div class="img-wrap">
          <img :src="errImg" alt="">
          <p class="error-desc">{{ errText }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import analyseSummary from './components/analyseSummary.vue'
import HomePage from '@/components/common/HomePage'
import dropped from './detail/release'
import releaseMemory from './detail/releaseMemory'
import indicator from './detail/indicator'
import analyse from './detail/analyse'
import { getImg } from 'app/style'

import {
  getTaskUserSingle,
  getTaskReportOverview,
  getTaskReportTags,
  getTaskReportNetwork,
  getTaskReportBehaviorProcess,
  getTaskReportShots
} from 'services/report'
import { t, isZh } from 'app/i18n'

// import {
//   checkLogin
// } from 'services/auth'

import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs, computed, watch, onUnmounted, ref } from 'vue'
import eventDesc from 'assets/markdown/event_description.json'
import cloneDeep from 'lodash/cloneDeep'
import emitter from 'app/emitter'
import { resetTransition } from '@/hooks/useDom'

// const captchDialogVisible = ref(false)
const showErrPage = ref(false)
const route = useRoute()
const router = useRouter()
const store = useStore()
const state = reactive({
  elTableData: [],
  taskInfo: null,
  summary: {
    info: {
      target: {
        url: ''
      },
      machine: {
        os_env: ''
      }
    },
    params: {
      image: ''
    },
    taskOS: '',
    specificCount: {}
  },
  staticsearchskill: {},
  machinelearning: {},
  behaviorfeature: {},
  behaviorscreenshot: {},
  networkCount: {},
  progressanalyse: {},
  tips: t('other.views.reportPdf.loading'),
  loading: true,
  category: 'file',
  taskOS: '',
  events: eventDesc.category,
  currentModule: route.params.type || 'analyse',
  timer: null,
  showProgress: false,
  code: 0
})

const taskId = route.params.taskId

const { taskInfo, summary, loading, currentModule, timer, showProgress, code } = toRefs(state)

const progress = computed(() => percent(taskInfo.value?.status || 'pending'))

const isRunning = computed(() => progress.value < 100)
const completed = computed(() => progress.value === 100)

const homepageInfo = computed(() => {
  return {
    category: state.category,
    gid: 4,
    keyword: state.taskInfo.detect_sample,
    sha256: state.summary.info.target?.file?.sha256,
    sha1: state.summary.info.target?.file?.sha1,
    md5: state.summary.info.target?.file?.md5,
    filetype: state.taskInfo.file_type,
    taskId: taskId
  }
})

const errImg = computed(() => {
  let url = ''
  switch (state.code) {
    case 2: url = 'permission-deny'
      break
    case 9: url = 'not-found-report'
      break
    default: url = 'not-found-report'
      break
  }
  // return require(`@/assets/img/report/${url}.png`)
  return getImg(`report/${url}.png`)
})

const errText = computed(() => {
  if (state.code === 2) {
    return t('other.views.reportPdf.nopermission')
  }
  return t('other.views.reportPdf.noTask')
})

watch(progress, (n, o) => {
  if ((isRunning.value && progress.value > 30) || completed.value) {
    if (onlyIncludeStatic.value) return
    start()
  }
})

const onlyIncludeStatic = computed(() => {
  if (state.strategy === 1) {
    return true
  } else if (state.strategy === 0 && state.score >= 7.5) {
    return true
  } else if (state.error && state.score.file_type_error) {
    return true
  } else {
    return false
  }
})

// const elTableData = computed(() => {
//   const data = [
//     {
//       name: 'analyse',
//       label: '分析概览'
//     },
//     {
//       name: 'static',
//       label: '静态分析'
//     },
//     {
//       name: 'behavior',
//       label: '行为分析'
//     },
//     {
//       name: 'network',
//       label: '网络分析'
//     },
//     {
//       name: 'dropped',
//       label: '释放文件'
//     },
//     {
//       name: 'memdump',
//       label: '释放内存'
//     },
//     {
//       name: 'indicator',
//       label: '威胁指标'
//     },
//     {
//       name: 'graph',
//       label: '关联分析'
//     }
//   ]
//   if (progress.value <= 30) {
//     data.splice(1, 7)
//   } else if (progress.value <= 100 && isRunning.value) {
//     data.splice(2, 6)
//   } else if (completed.value) {
//     data.splice(2, 4)
//   }
//   // if (state.category === 'url') {
//   //   data.splice(1, 1)
//   // }
//   if (onlyIncludeStatic.value) {
//     data.splice(2, 4)
//     if (state.taskInfo.file_type === 'tcpdump') {
//       data.splice(2, 0, {
//         name: 'network',
//         label: '网络分析'
//       })
//     }
//   }
//   return data
// })

// function showCaptchHandle() {
//   captchDialogVisible.value = true
// }

function percent(status) {
  switch (status) {
    case 'waiting':
    case 'pending':
      return 20
    case 'scanning':
    case 'static_dispatch_waiting':
      return 30
    case 'policy_running':
    case 'dynamic_pending':
    case 'scanning_completed':
      return 40
    case 'dynamic_dispatch_waiting':
    case 'running':
      return 60
    case 'processing':
      return 70
    case 'completed':
      return 80
    case 'reported':
      return 100
    default:
      return 100
  }
}

function handleTabChange(tab) {
  state.currentModule = tab
  if (tab !== 'behavior') { // 重置pid
    store.commit('report/SET_CURRENT_PID', 0)
  }
  if (route.params.type !== tab) {
    if (tab === 'analyse') tab = ''
    router.push({ params: { type: tab } })
  }
}

async function start(needloading, token) {
  if (needloading) state.loading = true
  if (onlyIncludeStatic.value) {
    await getTaskReportOverview(taskId, token).then((res) => {
      if (res) {
        state.staticsearchskill.zserver = res.zserver
        state.staticsearchskill.signatures_genre = res.static_signatures_genre || []
        state.summary.threat_level = res.zserver?.threat_level || null
        state.machinelearning = res.aiengine
      }
      state.summary.params = res.params
      state.summary.params.image = res.info.machine.image
      if (res.info) {
        state.taskOS = res.info.machine.os
        state.summary.info = res.info
        state.summary.info.download_urls = res.download_urls
        state.summary.info.target = res.target
        window.originConfig = cloneDeep(res.params)
        state.summary.taskOS = state.taskOS
        state.summary.specificCount = res?.specific_quota_count || {}
        store.commit('team/SET_SHARED_IDS', res.share_team_ids)
        state.staticsearchskill.taskOS = state.taskOS
        state.category = res.info.category
        state.machinelearning = res.aiengine
        state.behaviorfeature.signatures = res.behavior_signatures || []
        state.behaviorfeature.signatures_genre = res.behavior_signatures_genre || []
      }
    })
    // await getTaskReportZserver(taskId).then((res) => {
    //   if (res) {
    //     state.staticsearchskill.zserver = res.zserver
    //     state.staticsearchskill.signatures_genre = res.static_signatures_genre || []
    //     state.summary.threat_level = res.zserver?.threat_level || null
    //     state.machinelearning = res.aiengine
    //   }
    // })
    // await getTaskReportZserverInfo(taskId).then((res) => {
    //   if (res.info) {
    //     state.taskOS = res.info.machine.os
    //     state.summary.info = res.info
    //     state.summary.info.download_urls = res.download_urls
    //     state.summary.info.target = res.target
    //     state.summary.params = res.params
    //     window.originConfig = cloneDeep(res.params)
    //     state.summary.params.image = res.info.machine.image
    //     state.summary.taskOS = state.taskOS
    //     state.summary.specificCount = res?.specific_quota_count || {}
    //     store.commit('team/SET_SHARED_IDS', res.share_team_ids)
    //     state.staticsearchskill.taskOS = state.taskOS
    //   }
    // })
    await getTaskReportNetwork(taskId, { total: true, lang: isZh() ? 'zh' : 'en' }).then((res) => {
      state.networkCount = res.network
    })
    await getTaskReportTags(taskId).then((res) => {
      state.summary.info.tags = res.tags
      state.summary.info.tagsFilter = res.filter
    })
  } else {
    await getTaskReportOverviewFn(token)
    await getTaskReportTags(taskId).then((res) => {
      state.summary.info.tags = res.tags
      state.summary.info.tagsFilter = res.filter
    })
    await getTaskReportNetwork(taskId, { total: true, lang: isZh() ? 'zh' : 'en' }).then((res) => {
      state.networkCount = res.network
    })
    if (!isRunning.value) {
      await getTaskReportBehaviorProcess(taskId).then((res) => {
        const eventsMap = {}
        for (const item of state.events) {
          eventsMap[item.name] = item.zhcn
        }
        const keyMap =
          Object.keys(res.events_map).length > 0 ? res.events_map : eventsMap
        state.progressanalyse.process_tree = res.processtree
        state.progressanalyse.processes = res.processes
        state.progressanalyse.tab_data =
          Object.keys(res.events_overview).length > 0 &&
          res.events_overview.map((item) => ({ ...item, name: keyMap[item.key] }))
        state.progressanalyse.taskOS = state.taskOS
      })
      await getTaskReportShots(taskId).then((res) => {
        state.behaviorscreenshot.shots = res.shots
        state.behaviorscreenshot.taskOS = state.taskOS
      })
    }
  }
  state.loading = false
  resetTransition()
}
async function getTaskReportOverviewFn(token) {
  await getTaskReportOverview(taskId, token).then((res) => {
    // res = {}
    state.summary.params = res.params
    state.summary.params.image = res.info.machine.image
    if (res.info) {
      state.taskOS = res.info.machine.os
      state.summary.info = res.info
      state.summary.info.download_urls = res.download_urls
      state.summary.info.target = res.target
      window.originConfig = cloneDeep(res.params)
      state.summary.threat_level = res.zserver?.threat_level || null
      state.summary.taskOS = state.taskOS
      state.summary.specificCount = res.specific_quota_count
      store.commit('team/SET_SHARED_IDS', res.share_team_ids)
      state.staticsearchskill.zserver = res.zserver
      state.staticsearchskill.signatures_genre = res.static_signatures_genre
      state.staticsearchskill.taskOS = state.taskOS
      state.category = res.info.category
      state.machinelearning = res.aiengine
      state.behaviorfeature.signatures = res.behavior_signatures || []
      state.behaviorfeature.signatures_genre =
      res.behavior_signatures_genre || []
    }
  })
}

// async function getLoginStatus() {
//   await checkLogin().then((res) => {
//     if (!res.is_login) {
//       showCaptchHandle()
//       state.loading = false
//       showErrPage.value = true
//     }
//   })
// }

async function getTaskStatus(token) {
  await getTaskUserSingle({ task_id: taskId, _token: token || undefined }).then((res) => {
    if (res.code && res.code !== 0) {
      state.code = res.code
      let msg = ''
      switch (res.code) {
        case 2 : msg = t('other.views.reportPdf.privatePermission')
          break
        case 9 : msg = t('other.views.reportPdf.noTask')
          break
        default: msg = t('other.views.reportPdf.systemError')
      }
      store.commit('app/showMessage', {
        type: 'error',
        message: msg,
        duration: 1500
      })
    } else {
      state.taskInfo = res.task
      state.category = res.task.category
      state.elTableData = res.task.tabs
      state.strategy = res.task.strategy
      state.score = res.task.score
      state.error = res.task.error
      showProgress.value = res.task.status !== 'reported'
      const isFinish = res.task.status === 'reported' || res.task.status.includes('failed')
      if (!isFinish) {
        const t = onlyIncludeStatic.value ? 2 : 15
        timer.value = setTimeout(() => { getTaskStatus(token) }, 1000 * t)
      } else if (isFinish && timer.value) {
        start()
        // getTaskReportOverviewFn() // reported之前获取到的分数是0 结束了请求详情
        clearTimeout(timer.value)
      }
      if (res.task.status.includes('failed')) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('other.views.reportPdf.taskFailed'),
          duration: 1500
        })
        setTimeout(() => {
          router.push({ name: 'history' })
        }, 800)
      }
    }
  })
  state.loading = false
}

const mountHandle = async () => {
  await getTaskStatus()
  if (state.code !== 0) return
  start(true)
  emitter.on('tabChange', (tab) => {
    handleTabChange(tab)
  })
}

onUnmounted(() => {
  clearInterval(timer.value)
})

onMounted(async () => {
  store.commit('theme/setTheme', 'light')
  window.document.documentElement.setAttribute('data-theme', 'light')
  mountHandle()
  // await getLoginStatus()
  // if (!showErrPage.value) {
  //   mountHandle()
  // } else {
  //   emitter.on('reloadResult', () => {
  //     showErrPage.value = false
  //     state.loading = true
  //     mountHandle()
  //   })
  // }
})
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.wrapper {
  background-image: url('~@/assets/img/homepage-bg.png');
  background-size: 50%;
  font-family: PdfPingFang;
  :deep(code) {
    font-family: PdfPingFang;
  }
  .header-title {
    font-size: 18px;
    line-height: 49px;
    font-weight: 500;
    opacity: 0;
    // border-bottom: 1px solid var(--color-border-2);
  }
  margin: 0 auto;
  -webkit-text-size-adjust:none;
  // width: 11in;
  // width: 745pt;
  width: 750pt;
  // transform: scale(1.5);
  // display: flex;
  // height: 100%;
  box-sizing: border-box;
  .error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    .img-wrap {
      width: 300px;
      position: relative;
      img {
        width: 300px;
      }
      .error-desc {
        position: absolute;
        margin: 0;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
        color: var(--color-text-2);
        line-height: 22px;
      }
    }
  }
  .content {
    flex: 1;
    // background-color: var(--detect-config-bg);
    // margin-left: 16px;
    overflow: hidden;
    .module-item__title {
      line-height: 50px;
      border-bottom: 1px solid var(--color-border-2);
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
    }
    :deep(.el-collapse-item__wrap) {
      background-color: transparent !important;
    }
    :deep(.el-collapse-item__header) {
      background-color: transparent !important;
    }
  }
  .contentWrapper {
    // height: calc(100vh - 144px);
    // max-height: calc(100vh - 166px);
    // padding: 0 20px;
    // @include scroll_bar();
    width: 100%;
    // overflow-y: scroll;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
  }
  .noFlex {
    display: block !important;
  }
}
@media print {
  @page {
    size: A4;
    margin: 30pt;
  }
}
//     // margin: 10pt;
//     // @top-left {
//     //   content: 'first: ' string(heading, first);
//     // }
//     // @top-center {
//     //   content: 'center' string(heading, start);
//     // }
//     // @top-right {
//     //   content: 'right' string(heading, last);
//     // }
//   }
// }
</style>
