export function getFileSuffix(type) {
  let file = ''
  const list = [
    'html',
    'hwp',
    'js',
    'mhtml',
    'powershell',
    'python',
    'rar',
    'rtf',
    'swf',
    'VBS-icon',
    'email'
  ]
  if (list.includes(type)) {
    file = type
  } else if (type === 'doc' || type === 'docx') {
    file = 'doc'
  } else if (type === 'xls' || type === 'xlsx') {
    file = 'excel'
  } else if (type === 'dll32' || type === 'dll64') {
    file = 'dll'
  } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
    file = 'exe'
  } else if (type === 'ppt' || type === 'pptx') {
    file = 'ppt'
  } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
    file = 'zip'
  } else {
    file = 'unknow'
  }
  return file
}
