<template>
  <div class="wrapper">
    <el-dialog
      v-model="_show"
      :title="title"
      width="660px"
      custom-class="redetect-main"
      top="5vh"
      @close="close"
    >
      <div class="header" v-if="isRedect">
        <el-checkbox v-model="useOriginConfigure">{{$t('dialog.detect.use_origin_param')}}</el-checkbox>
        <p>{{useOriginConfigure ? $t('dialog.detect.origin_param_info') : $t('dialog.detect.new_param_info')}}</p>
      </div>
      <div class="url-header" v-if="props.redetectType === 'url' || props.redetectType === 'dropped'">
        <span>{{props.url || props.dropped.name}}</span>
        <span>{{state.item.fileType || ''}}</span>
        <span v-if="state.item.size">{{getSize(state.item.size)}}</span>
        <span>{{getStatus(state.item)}}</span>
      </div>
      <templates v-if="showConfig">
        <div class="configWrapper">
          <div v-show="useOriginConfigure" class="redetect-mask"></div>
          <configBox
            v-if="state.initShow"
            @inputError="inputErrorChange"
            ref="configBoxRef"
            :configData="useConfig"
            @update:configData="updateconfigData"
            @delete:configData="deleteconfigData"
            :activeIndex="activeIndex"
            :type="type"
            @change="changeConfig"
            :taskType="curPlatform"
            :configUserPromise="configUserPromise"
            :curFileType="(props.redetectType === 'url' || props.redetectType === 'dropped' ? state.item.fileType : props.params.qex_type) || ''"
          ></configBox>
        </div>
        <div class="bottom">
          <div class="private">
            <div class="uploadConfig-left__radio" @click="showUpgrade">
              <span class="uploadConfig-left__radio--label" @click.stop>{{$t('detection.permissions.label')}}</span>
              <el-switch
                :disabled="!userInfo.group || userInfo.group === 'free'"
                v-model="_is_public"
                :active-value='true'
                :inactive-value='false'
                active-color="#FA8C16"
                inactive-color="#00ab7a"
                size="small"
                :active-text="$t('detection.permissions.active')"
                :inactive-text="$t('detection.permissions.inactive')">
              </el-switch>
            </div>
            <div v-if="loginState && teamList.length > 0" class="uploadConfig-left__select">
              <span class="uploadConfig-left__select--label" @click.stop>{{$t('detection.share_team.label')}}</span>
              <el-select
                multiple
                collapse-tags
                :suffix-icon="arrow"
                v-model="selectTeam"
                :placeholder="$t('detection.share_team.placeholder')"
                popper-class="detect-operate"
              >
                <el-option
                  v-for="item in teamList"
                  :key="item.value"
                  :label="item.team_name"
                  :value="item.team_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <el-checkbox class="uploadConfig-left__checkbox" v-model="state.isChecked"
            >
            <i18n-t keypath="detection.agreement" tag="span">
              <template #user>
                <router-link to="/help/docs/terms">{{$t('detection.user')}}</router-link>
              </template>
              <template #privacy>
                <router-link to="/help/docs/privacy">{{$t('detection.privacy')}}</router-link>
              </template>
            </i18n-t>
          </el-checkbox>
        </div>
      </templates>
      <div class="errMsg" v-if="state.item.status === 'wrong'">
        <img src="@/assets/img/detection/checkStatus_failed.png" alt="">
        <span>{{$t('dialog.detect.failed')}}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="!showConfig" type="primary" @click="submit"
            >{{$t('detection.submit')}}</el-button
          >
          <el-button @click="cancel">{{$t('dialog.cancel')}}</el-button>
        </span>
      </template>
    </el-dialog>
    <upgrade-dialog v-model:show="isShowUpgrade" :showPoint="true" :showNormal="true"></upgrade-dialog>
    <publishDialog
      v-if="isShowPublish"
      v-model:show="isShowPublish"
      @ok="submit(true)"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, toRefs, defineEmits, reactive, onMounted, computed, unref, watch, watchEffect, nextTick } from 'vue'
import { useState, useGetters } from '@/hooks/useVuex'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { reSubmit, postUploadSample, checkUploadSample, postTaskSubmitSingle } from 'services/detection'
import UpgradeDialog from '@/components/common/upgradeDialog'
import publishDialog from '@/components/common/publishDialog'
import { t } from 'app/i18n'

import qs from 'qs'
import cloneDeep from 'lodash/cloneDeep'
import configBox from '../../views/detection/components/configBox'
const store = useStore()
const route = useRoute()
const emits = defineEmits(['update:show'])

const props = defineProps({
  show: {
    default: false,
    type: Boolean
  },
  curPlatform: {
    default: 'windows',
    type: String
  },
  is_public: {
    default: false,
    type: Boolean
  },
  taskId: {
    type: Number
  },
  redetectType: {
    type: String,
    default: 'redetect'
  },
  params: {
    default: () => {
      return {
        strategy: 2,
        useBase: true,
        urlObjectConfig: 'browser',
        env_var: [],
        timeout: 120,
        internet: true,
        human: true,
        useNetCheckbox: true,
        netRadio: 'tor'
      }
    },
    type: Object
  },
  url: {
    type: String
  },
  dropped: {
    type: Object
  }
})

const { userInfo } = useState('user', ['userInfo'])

const state = reactive({
  useOriginConfigure: true,
  inputError: '',
  useConfig: {
    ...props.params,
    browser: props.params.browser || ''
  },
  activeIndex: 0,
  type: 'file',
  configUserPromise: {},
  isShowUpgrade: false,
  isShowPublish: false,
  _is_public: !userInfo.value.group || userInfo.value.group === 'free' ? true : unref(props.is_public),
  isChecked: false,
  timer: null,
  item: {},
  initShow: true
})

const { shared_ids } = useState('team', ['shared_ids'])

const Linux = ['elf', 'shell']

const selectTeam = computed({
  get: () => shared_ids.value,
  set: (val) => {
    store.commit('team/SET_SHARED_IDS', val)
  }
})

const loginState = ref(store.getters.isLogin)

const { teamList } = useGetters('team', ['teamList'])

const { useOriginConfigure, inputError, useConfig, activeIndex, type, configUserPromise, isShowUpgrade, _is_public, isShowPublish } = toRefs(state)

const title = computed(() => props.redetectType === 'redetect' ? t('dialog.detect.redetect') : props.redetectType === 'url' ? t('dialog.detect.url') : t('dialog.detect.dropped'))

const isRedect = computed(() => props.redetectType === 'redetect')

const curPlatform = computed(() => {
  if (isRedect.value) {
    return titleCase(props.curPlatform)
  } else {
    return state.item.platform
  }
})

const showConfig = computed(() => {
  if (isRedect.value) {
    return true
  } else if ((props.redetectType === 'url' || props.redetectType === 'dropped') && (state.item.status !== 'wrong' && state.item.progress === '100')) {
    return true
  }
  return false
})

// const { show } = toRefs(props)

const configBoxRef = ref(null)

watchEffect(() => {
  nextTick(() => {
    if (!document.querySelector('.redetect-mask')) return
    document.querySelector('.redetect-mask').style.height = document.querySelector('.configBox').scrollHeight + 50 + 'px'
  })
})

watch(useOriginConfigure, (data) => {
  if (data) {
    useConfig.value = cloneDeep(window.originConfig)
    state.initShow = false
    nextTick(() => {
      state.initShow = true
    })
  }
})

watch(props.params, v => {
  console.log(v)
})

const _show = ref(props.show)
const isSubmit = ref(false)

onMounted(() => {
  store.commit('team/SET_SHARED_IDS', [])
  if (loginState.value) {
    state.isChecked = true
    store.dispatch('team/getTeamMembers')
  }
  if (props.url) {
    state.useOriginConfigure = false
    addFile(props.url)
  }
  if (props.dropped) {
    state.useOriginConfigure = false
    addDropped()
  }
  if (window.originConfig) useConfig.value = cloneDeep(window.originConfig)
})

const computed_type = computed(() => {
  return props.redetectType === 'dropped' ? 'file' : 'url'
})

const getStatus = computed(() => {
  return (item) => {
    const status = item.status
    const progress = item?.progress || 0
    if (item.unlegal) {
      return t('detection.type_error')
    } else if (item.checkResult) {
      return t(`detection.check_enums.${item.checkResult}`) || ''
    } else {
      if (status === 'success') return t(`detection.file_upload_status.${computed_type.value}.success`)
      if (status === 'wrong') return t(`detection.file_upload_status.${computed_type.value}.failed`)
    }
    return `${t(`detection.running_map.${computed_type.value}`)} ${progress}%`
  }
})

function getSize(size) {
  if (size < 1024) {
    return `${size}${t('byte')}`
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}K`
  }
  return `${(size / 1024 / 1024).toFixed(1)}M`
}

function titleCase(str) {
  str = str.slice(0, 1).toUpperCase() + str.slice(1)
  return str
}

function inputErrorChange(isErr) {
  inputError.value = isErr
}

function updateconfigData(key, val) {
  useConfig.value[key] = val
}

function deleteconfigData(key) {
  delete useConfig.value[key]
}

function close() {
  emits('update:show', false)
  clearTimeout(state.timer)
  state.timer = null
}

function changeConfig() {
}

function cancel() {
  clearTimeout(state.timer)
  state.timer = null
  _show.value = false
}

function submit(isPublish) {
  if (isSubmit.value) return
  isSubmit.value = true
  if (inputError.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_config_item'),
      duration: 1000,
      showClose: true
    })
    isSubmit.value = false
    return
  }
  if (!state.isChecked) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_agreement'),
      duration: 1500,
      showClose: false
    })
    isSubmit.value = false
    return
  }
  if (isPublish === true) {
    if (isRedect.value) {
      reSubmitFn()
    } else if (props.redetectType === 'url') {
      urlSubmit()
    } else {
      dropSubmit()
    }
  } else {
    if (_is_public.value) {
      isShowPublish.value = true
      isSubmit.value = false
    } else {
      if (isRedect.value) {
        reSubmitFn()
      } else if (props.redetectType === 'url') {
        urlSubmit()
      } else {
        dropSubmit()
      }
    }
  }
}

function reSubmitFn() {
  console.log(_is_public.value)
  const submitData = resetData(cloneDeep(useConfig.value))
  const formData = {}
  const config = {}
  if (props.curPlatform === 'windows') {
    config[props.taskId] = {
      ...submitData,
      is_public: _is_public.value
    }
    // !config[props.taskId].pwd_to_mso && delete config[props.taskId].pwd_to_mso
    // !config[props.taskId].pwd_to_zip && delete config[props.taskId].pwd_to_zip
    // !config[props.taskId].office && delete config[props.taskId].office
    !config[props.taskId].cmd && delete config[props.taskId].cmd
    !config[props.taskId].function && delete config[props.taskId].function
    !config[props.taskId].arguments && delete config[props.taskId].arguments
    !config[props.taskId].office && delete config[props.taskId].office
    !config[props.taskId].emailer && delete config[props.taskId].emailer
    !config[props.taskId].browser && delete config[props.taskId].browser
    if (!config[props.taskId].in_zip) {
      delete config[props.taskId].type_in_zip
      delete config[props.taskId].md5_in_zip
      delete config[props.taskId].file_in_zip
      delete config[props.taskId].in_zip
    }
    if (config[props.taskId].type_in_zip === 'type_in_zip' && config[props.taskId].in_zip) {
      config[props.taskId].type_in_zip = config[props.taskId].in_zip
      delete config[props.taskId].md5_in_zip
      delete config[props.taskId].file_in_zip
      delete config[props.taskId].in_zip
    }
    if (config[props.taskId].type_in_zip === 'md5_in_zip' && config[props.taskId].in_zip) {
      config[props.taskId].md5_in_zip = config[props.taskId].in_zip
      delete config[props.taskId].type_in_zip
      delete config[props.taskId].file_in_zip
      delete config[props.taskId].in_zip
    }
    if (config[props.taskId].type_in_zip === 'file_in_zip' && config[props.taskId].in_zip) {
      config[props.taskId].file_in_zip = config[props.taskId].in_zip
      delete config[props.taskId].type_in_zip
      delete config[props.taskId].md5_in_zip
      delete config[props.taskId].in_zip
    }
  } else if (props.curPlatform === 'android') {
    formData.os = 'android'
    config[props.taskId] = {
      ...submitData,
      is_public: _is_public.value
    }
    delete config[props.taskId].human_popbox
    delete config[props.taskId].human_scroll
    delete config[props.taskId].human_curpos
    delete config[props.taskId].human_autoin
    delete config[props.taskId].fake_reboot
    delete config[props.taskId].fast_detect
    delete config[props.taskId].human_clipbd
    delete config[props.taskId].pwd_forced
    delete config[props.taskId].type_in_zip
    delete config[props.taskId].in_zip
    delete config[props.taskId].pwd_to_mso
    delete config[props.taskId].pwd_to_zip
  } else if (props.curPlatform === 'linux') {
    formData.os = 'linux'
    config[props.taskId] = {
      is_public: _is_public.value,
      team_id: submitData.team_id,
      machine: submitData.machine,
      strategy: submitData.strategy
    }
    delete config[props.taskId].decrypt_https
    delete config[props.taskId].human
    delete config[props.taskId].human_popbox
    delete config[props.taskId].human_scroll
    delete config[props.taskId].human_curpos
    delete config[props.taskId].human_autoin
    delete config[props.taskId].fake_reboot
    delete config[props.taskId].fast_detect
    delete config[props.taskId].human_clipbd
    delete config[props.taskId].pwd_forced
    delete config[props.taskId].type_in_zip
    delete config[props.taskId].in_zip
    delete config[props.taskId].pwd_to_mso
    delete config[props.taskId].pwd_to_zip
  }
  if (selectTeam.value) {
    config[props.taskId].team_id = selectTeam.value
  }
  formData.submit_type = 'web_submit'
  console.log(config)
  formData.config_map = JSON.stringify(config)
  formData.task_id = props.taskId
  reSubmit(qs.stringify(formData))
    .then((res) => {
      _show.value = false
      if (res.code !== undefined && res.code !== 0) {
        store.commit('app/showMessage', {
          type: 'error',
          message: res.message.join(', '),
          duration: 1000
        })
      } else if (res.task_id) {
        store.commit('app/showMessage', { message: t('dialog.detect.submitted'), duration: 1000 })
        window.open(`/report/${res.task_id}`, '_blank')
      } else {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
    .catch((err) => {
      console.log(err)
      if (err.response?.data?.url) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.url_submit_error'),
          duration: 1000
        })
      } else if (err.response.status !== 406 && err.response.status !== 429) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
}

function dropSubmit() {
  const configData = resetData(cloneDeep(useConfig.value))
  const formData = new FormData()
  const config = {}
  const sample_param = {
    filename: state.item.filename,
    file_type: state.item.fileType,
    file_size: state.item.size,
    md5: state.item.md5,
    sha1: state.item.sha1,
    sha256: state.item.sha256
  }
  formData.append('file_sample', JSON.stringify(sample_param))
  config[state.item.filename] = {
    is_public: _is_public.value,
    ...configData
  }

  !config[state.item.filename].cmd && delete config[state.item.filename].cmd
  !config[state.item.filename].function && delete config[state.item.filename].function
  !config[state.item.filename].arguments && delete config[state.item.filename].arguments
  !config[state.item.filename].office && delete config[state.item.filename].office
  !config[state.item.filename].emailer && delete config[state.item.filename].emailer
  !config[state.item.filename].browser && delete config[state.item.filename].browser

  if (selectTeam.value) {
    config[state.item.filename].team_id = selectTeam.value
  }
  formData.append(
    'category',
    'file'
  )
  formData.append('submit_type', 'web_submit')
  formData.append('config_map', JSON.stringify(config))
  postTaskSubmitSingle(formData)
    .then((res) => {
      _show.value = false
      res.task_ids = Object.values(res) || []
      if (res.code !== undefined && res.code !== 0) {
        store.commit('app/showMessage', {
          type: 'error',
          message: res.message.join(', '),
          duration: 1000
        })
      } else if (res.task_ids) {
        store.commit('app/showMessage', { message: t('detection.submit_success'), duration: 1000 })
        window.open(`/report/${res.task_ids[0]}`, '_blank')
      } else {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
    .catch((err) => {
      console.log(err)
      _show.value = false
      if (err.response?.data?.url) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.url_submit_error'),
          duration: 1000
        })
      } else if (err.response.status !== 406 && err.response.status !== 429) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
}

function urlSubmit() {
  const configData = resetData(cloneDeep(useConfig.value))
  const formData = new FormData()
  const config = {}
  formData.append('url', props.url)
  config[props.url] = {
    is_public: _is_public.value,
    ...configData
  }
  console.log(config[props.url])
  delete config[props.url].type_in_zip
  delete config[props.url].in_zip
  delete config[props.url].office
  if (selectTeam.value) {
    config[props.url].team_id = selectTeam.value
  }
  formData.append('submit_type', 'web_submit')
  formData.append('config_map', JSON.stringify(config))
  formData.append(
    'category',
    'url'
  )
  postTaskSubmitSingle(formData)
    .then((res) => {
      _show.value = false
      res.task_ids = Object.values(res) || []
      if (res.code !== undefined && res.code !== 0) {
        store.commit('app/showMessage', {
          type: 'error',
          message: res.message.join(', '),
          duration: 1000
        })
      } else if (res.task_ids) {
        store.commit('app/showMessage', { message: t('detection.submit_success'), duration: 1000 })
        window.open(`/report/${res.task_ids[0]}`, '_blank')
      } else {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
    .catch((err) => {
      console.log(err)
      _show.value = false
      if (err.response?.data?.url) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      } else if (err.response.status !== 406 && err.response.status !== 429) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000
        })
      }
      isSubmit.value = false
    })
}

function resetData(configs) {
  return reset(configs)
  function reset(data) {
    if (!data.useNetCheckbox) {
      delete data.netRadio
      delete data.tor
      delete data.vpn
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    }
    if (data.netRadio === 'tor') {
      delete data.vpn
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    } else if (data.netRadio === 'vpn') {
      delete data.tor
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    } else if (data.netRadio === 'proxy') {
      delete data.tor
      delete data.vpn
    }
    if (!data.userRequiredCheckbox) {
      delete data.username
      delete data.password
    }
    if (data.urlObjectConfig === 'browser') delete data.user_agent
    // else delete data.browser
    !data.browser && delete data.browser
    delete data.useNetCheckbox
    delete data.userRequiredCheckbox
    delete data.netRadio
    delete data.urlObjectConfig
    const envVarList = []
    if (data.env_var) {
      data.env_var.forEach((i) => {
        if (i.key && i.value) {
          envVarList.push(`${i.key}=${i.value}`)
        }
      })
    }
    data.env_var = envVarList
    delete data.useBase
    if (!data.custom_detect_type) {
      delete data.custom_detect_type
    }
    if (!data.runpath) {
      delete data.runpath
    }
    if (!data.timezone) {
      delete data.timezone
    }
    if (!data.geo) {
      delete data.geo
    }
    if (data.custom_date) {
      data.datetime = new Date(`${data.custom_date} ${data.custom_time}`)
    }
    return data
  }
}

function addFile(url) {
  const formData = new FormData()
  formData.append('url', url)
  formData.append('category', 'url')
  postUploadSample(formData).then(res => {
    const data = {
      category: 'url',
      url
    }
    checkUploadSampleFn(data)
  }).catch(() => {
    state.item = {
      checkResult: 'verify_failed',
      progress: '100',
      fileType: 'URL',
      status: 'wrong'
    }
  })
}

function addDropped(url) {
  const formData = new FormData()
  const { md5, sha256, name } = props.dropped
  const config = {
    task_id: route.params.taskId,
    md5,
    sha256,
    filename: name
  }
  formData.append('dropped_file', JSON.stringify(config))
  formData.append('category', 'dropped')
  postUploadSample(formData).then(res => {
    const result = res[0][name]
    const bol = result.status
    let errMsg = null
    state.item.status = bol !== 200 ? 'wrong' : 'success'
    if (bol !== 200) {
      errMsg = {
        code: bol,
        msg: t('download.failed')
      }
    }
    state.item.errMsg = errMsg
    state.item.fileType = result.file_type
    state.item.platform = state.item.fileType === 'apk' ? 'Android' : Linux.includes(state.item.fileType) ? 'Linux' : 'Windows'
    state.item.filename = result.filename
    state.item.size = result.file_size
    state.item.md5 = result.md5
    state.item.sha1 = result.sha1
    state.item.sha256 = result.sha256
    state.item.progress = '100'
  })
}

const checkStatusMap = {
  verify_failed: t('detection.check_enums.verify_failed'),
  download_failed: t('detection.check_enums.download_failed'),
  verify_failed_type_not_found: t('detection.check_enums.verify_failed_type_not_found'),
  content_to_large: t('detection.check_enums.content_to_large')
}

function checkUploadSampleFn(data) {
  checkUploadSample(data).then(res => {
    const result = res[0][props.url]
    if (result.status === 'downloading' || result.status === 'verifying') {
      if (result.size && result.total_size) {
        const progress = Number(result.total_size) ? ((Number(result.size) / Number(result.total_size)) * 100).toFixed(0) : 0
        state.item.progress = progress
      }
      state.timer = setTimeout(() => { checkUploadSampleFn(data) }, 1000)
      return
    } else {
      clearTimeout(state.timer)
      state.timer = null
    }
    const successArr = ['downloaded', 'verified']
    state.item.progress = '100'
    state.item.checkResult = result.status
    state.item.size = Number(result.total_size) || 0
    const urlStatus = ['verified', 'verify_failed', 'verify_failed_type_not_found', 'verified']
    state.item.fileType = urlStatus.includes(result.status) ? 'URL' : result.file_type

    state.item.platform = state.item.fileType === 'apk' ? 'Android' : Linux.includes(state.item.fileType) ? 'Linux' : 'Windows'
    let errMsg = null
    const bol = successArr.includes(result.status)
    state.item.status = bol ? 'success' : 'wrong'
    for (const key in checkStatusMap) {
      if (result.status === key) {
        errMsg = {
          code: bol,
          msg: checkStatusMap[key]
        }
      }
    }
    if (/^\d+$/.test(result.status)) {
      errMsg = {
        code: result.status,
        msg: t('download.failed')
      }
    }
    state.item.errMsg = errMsg
  }).catch(() => {
    state.item = {
      checkResult: 'verify_failed',
      progress: '100',
      fileType: 'URL',
      status: 'wrong'
    }
  })
}

function showUpgrade() {
  if (!userInfo.value.group || userInfo.value.group === 'free') {
    isShowUpgrade.value = true
  }
}

</script>
<style lang="scss" scoped>
.header {
  :deep(.el-checkbox__label) {
    color: var(--color-text-2);
  }
  p {
    color: var(--color-text-3);
    padding-left: 24px;
    word-break: break-word;
    line-height: 20px;
    margin: 12px 0;
  }
}
.url-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  span {
    font-size: 14px;
    color: var(--color-text-2);
    line-height: 20px;
    &:nth-child(1) {
      display: inline-block;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.wrapper {
  :deep(.redetect-main) {
    .el-dialog__body{
      max-height: 72vh;
      overflow: hidden;
    }
  }
}
.configWrapper {
  max-height: 540px;
  max-height: calc(72vh - 186px);
  overflow-y: auto;
  // overflow-y: overlay;
  padding: 0 20px 0;
  box-sizing: border-box;
  border: 1px solid var(--color-border-2);
  border-radius: 4px;
  position: relative;
  .redetect-mask {
    height: 1000px;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(32,35,48,0.6);
    z-index: 1100;
  }
  // &--disable {
  //   overflow-y: scroll;
  //   &::after {
  //     content: ' ';
  //     position: absolute;
  //     left: 0;
  //     top: 0px;
  //     height: 100%;
  //     width: 100%;
  //     background-color: rgba(32,35,48,0.6);
  //     z-index: 2000;
  //   }
  // }
}
.errMsg {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  color: var(--color-text-2);
  position: relative;
  top: -15px;
  img {
    width: 100px;
    margin-bottom: 10px;
  }
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
.private {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  & :deep(.el-switch) {
    // flex-direction: row-reverse;
    .el-switch__label--left {
      margin:0 10px 0 0;
      font-size: 14px;
    }
    .el-switch__label--right {
      font-size: 14px;
      margin:0 0 0 10px;
    }
  }
  .uploadConfig-left__radio {
    display:flex;
    align-items: center;
  }
}
.uploadConfig-left__checkbox {
  :deep(.el-checkbox__label) {
    white-space: normal;
    // color: var(--color-text-2);
    position: relative;
    top: 10px;
    line-height: 20px;
    word-break: break-word;
    & a {
      color: var(--color-priamry);
      text-decoration: none;
      &:focus {
        outline: none;
      }
    }
  }
}
.uploadConfig-left__select {
  :deep(.el-input__wrapper) {
    width: 150px;
  }
}
.dialog-footer {
  button {
    width: 88px;
  }
  .el-button+ .el-button {
    margin-left: 8px;
  }
}

[data-theme='light'] {
  .configWrapper {
    .redetect-mask {
      background-color: rgba(242,244,245,0.6);
    }
  }
}
</style>
