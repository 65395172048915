<template>
  <div class="process-content" v-if="!!processtree">
    <ul class="tree">
      <template v-for="process in processtree" :key="process.pid">
        <tree-process :process="process"></tree-process>
      </template>
    </ul>
  </div>
  <div v-else>{{$t('nodata')}}</div>
</template>

<script setup>
import { defineProps, onMounted } from 'vue'
import emitter from '@/app/emitter.js'
import TreeProcess from './tree_process'

const props = defineProps({
  processtree: {
    type: Array,
    default: () => {
      return []
    }
  }
})

onMounted(() => {
  if (props.processtree.length > 0) {
    emitter.emit('handleTreeNodeClick', props.processtree[0].pid)
  }
})
</script>

<style lang="scss">
ul.tree {
  padding: 10px;
}

ul.tree li, ul.tree li ul {
  list-style-type: none;
  background: url('~assets/img/vline.svg') repeat-y;
  margin: 0;
  padding: 0;
}

ul.tree li ul {
  margin-left: 10px;
}

ul.tree li {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 20px;
  background: url('~assets/img/node1.png') no-repeat;
  // filter: brightness(0);
  font-size: 14px;
}

ul.tree ul li:last-child {

}
.process-content {
  padding-left: 20px;
  margin-bottom: 10px;

  strong {
    font-size: 0.8rem;
  }
}
</style>
