<template>
  <div class="release-file-wrap">
    <SandTableCollapse :data="collapseData" :isPdfPage="true" upgradeType="C4" class="releasefile-table">
      <template v-slot="{ childrenData }">
        <div class="static-search-skill-wrap" v-if="childrenData.zserver">
          <StaticTable :showLine="false" :zserver="childrenData.zserver" />
        </div>
        <div class="json-table-wrap">
          <div class="json-table-title">
            <!-- <span class="json-table-title__line"></span> -->
            <span class="json-table-title__title">{{ $t('other.views.reportPdf.fileInfo') }}</span>
          </div>
          <SandJsonTable :data="handleTableData(childrenData)" :noborder="true" />
        </div>
        <div class="json-table-wrap" v-if="childrenData.contexts">
          <div class="json-table-title">
            <!-- <span class="json-table-title__line"></span> -->
            <span class="json-table-title__title">{{ $t('other.views.reportPdf.contextInfo') }}</span>
          </div>
          <SandTable :data="handleContextsData(childrenData)" />
        </div>
        <div class="file-content-wrap">
          <div class="file-content-title">
            <!-- <span class="file-content-title__line"></span> -->
            <span class="file-content-title__title">{{ $t('other.views.reportPdf.fileConent') }}</span>
          </div>
            <SandFileContent :data="childrenData.hex_to_array" />
        </div>
      </template>
    </SandTableCollapse>
  </div>
</template>

<script>
// 报告页 释放文件 tab 页
import SandTableCollapse from '@/components/report/sandTableCollapse'
import SandJsonTable from '@/components/report/sandJsonTable'
import StaticTable from '@/components/report/staticTable'
import SandFileContent from '@/components/report/sandFileContent'
import SandTable from '@/components/report/sandTable'
import { mapGetters } from 'vuex'
import { getColor } from 'app/style'
import { t } from 'app/i18n'

const keyMap = {
  size: {
    name: t('other.views.reportPdf.fileSize'),
    formatter: (value) => {
      return `${value} ${t('other.views.reportPdf.bity')}`
    }
  },
  qex_type: { name: t('other.views.reportPdf.fileType') },
  type: { name: t('other.views.reportPdf.fileFormat') },
  md5: { name: 'MD5', copy: true, toTI: true },
  sha1: { name: 'SHA1', copy: true, toTI: true },
  sha256: { name: 'SHA256', copy: true, toTI: true },
  crc32: { name: 'CRC32' },
  ssdeep: { name: 'SSDeep' }
}
export default {
  name: 'StaticInfo',
  components: {
    SandTableCollapse,
    SandJsonTable,
    StaticTable,
    SandFileContent,
    SandTable
  },
  props: {
    releasefile: {
      type: Object
    }
  },
  data() {
    return {
      keyMap
    }
  },
  computed: {
    ...mapGetters(['gid']),
    dropped() {
      return this.releasefile.dropped || []
    },
    collapseData() {
      const arr = []
      function scoreType(zserver) {
        const score = zserver && zserver.threat_level
        let obj = {}
        switch (score) {
          case 70:
            obj = { name: t('other.views.report.malice'), type: 'red', color: '#F34E4E', bgColor: '#ff76701a' }
            break
          case 50:
            obj = { name: t('other.views.reportPdf.suspicious'), type: 'orange', color: 'orange', bgColor: '#c2aa6f1a' }
            break
          case 40:
            obj = { name: t('other.views.reportPdf.notDetected'), type: '', color: '#7E7F86', bgColor: getColor('--color-bg-3') }
            break
          case 10:
            obj = { name: t('other.views.reportPdf.noRisk'), type: 'green', color: '#1fbe64' }
            break
          default:
            obj = { name: t('other.views.reportPdf.notDetected'), type: '', color: '#7E7F86' }
            break
        }
        return `<span
          style="
            border: 1px solid ${obj.color};
            background-color: ${obj.bgColor};
            display: inline-block;
            width: 45px;
            font-size: 12px;
            text-align: center;
            border-radius: 2px;
            color: ${obj.color};
            line-height: 20px;
        ">${obj.name}</span>`
      }
      this.dropped.forEach(function (item) {
        arr.push({
          type: item.qex_type || '-',
          name: item.name,
          md5: item.md5,
          filesize: `${item.size} ${t('other.views.reportPdf.bity')}`,
          download_url: item.download_url,
          sha256: item.sha256,
          engineJudge: scoreType(item.zserver),
          childrenData: item
        })
      })
      const collapseData = this.unique(arr, 'md5', 'name')
      return {
        collapseData,
        columnData: [
          {
            prop: 'md5',
            label: 'MD5',
            width: undefined,
            col: 6,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'filesize',
            label: t('other.views.reportPdf.fileSize'),
            width: undefined,
            col: 2,
            align: 'left',
            headAlign: 'left'
          },
          {
            prop: 'type',
            label: t('other.views.reportPdf.fileType'),
            width: undefined,
            col: 2,
            align: 'left',
            headAlign: 'left'
          },
          // {
          //   prop: 'md5',
          //   label: 'MD5',
          //   width: undefined,
          //   col: 5,
          //   align: 'left',
          //   headAlign: 'left'
          // },
          // {
          //   prop: 'operation',
          //   label: '操作',
          //   width: '200px',
          //   col: undefined,
          //   align: 'center',
          //   type: 'download'
          // }
          {
            prop: 'engineJudge',
            label: t('other.views.reportPdf.staticEngineJudgment'),
            width: undefined,
            col: 3,
            align: 'left',
            headAlign: 'left'
          }
        ],
        tableProps: {
          stripe: false
        }
      }
    }
  },
  methods: {
    isEmpty(target) {
      const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
      switch (type) {
        case 'object':
          return Object.keys(target).length === 0
        case 'array':
          return target.length === 0
        case 'string':
          return target === ''
        case 'undefined':
        case 'null':
          return true
        default:
          return false
      }
    },
    handleTableData(list) {
      const columnData = Object.keys(this.keyMap).map((item) => ({
        prop: item,
        label: this.keyMap[item].name,
        copy: this.keyMap[item].copy,
        toTI: this.keyMap[item].toTI
      }))
      const tableData = Object.assign({}, list, { size: `${list.size} ${t('other.views.reportPdf.bity')}` })
      return { columnData, tableData }
    },
    handleContextsData(list) {
      const columnData = [
        { prop: 'context_pid', label: t('other.views.reportPdf.contextProcess') },
        { prop: 'target_pid', label: t('other.views.reportPdf.targetProcess') },
        { prop: 'address', label: t('other.views.reportPdf.memoryAddress') },
        { prop: 'size', label: t('other.views.reportPdf.memorySize') },
        { prop: 'protection', label: t('other.views.reportPdf.memoryPrepery') }
      ]
      const tableData = list.contexts
      return { columnData, tableData }
    },
    unique(list, ...rest) {
      if (!Array.isArray(list) || list.length === 0) return []
      const result = {}
      for (const item of list) {
        const key = rest.map((key) => item[key]).join('|')
        result[key] = item
      }
      return Object.values(result)
    }
  }
}
</script>

<style lang="scss" scoped>
.release-file-wrap {
  padding: 24px 0;
  .releasefile-table {
    :deep(.el-collapse) {
      .el-collapse-item {
        // margin-bottom: 6px;
        .el-collapse-item__header {
          background: var(--detect-config-bg);
          border-bottom: 1px solid var(--color-border-2);
        }
        .el-collapse-item__wrap {
          border-bottom: 1px solid var(--color-border-2);

          .el-collapse-item__content {
            background: var(--color-bg-3);
            padding-bottom: 0;
          }
        }
      }
    }
    :deep(.el-collapse) {
      .el-collapse-item {
        margin-bottom: 6px;

        .el-collapse-item__wrap {
          border-bottom: 1px solid var(--color-border-2);
          .el-collapse-item__content {
            padding: 0;
          }
        }
      }
    }

    :deep(.static-search-skill-wrap) {
      padding: 15px 20px 0;
    }

    :deep(.json-table-wrap) {
      padding: 20px;

      .json-table-title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        // border-bottom: 1px solid var(--color-border-2);
        box-shadow: 0 1px 0 0 var(--color-border-2);
        &__line {
          display: inline-block;
          height: 12px;
          width: 3px;
          background-color: var(--color-priamry);
          margin-right: 6px;
        }

        &__title {
          font-size: 16px;
          color: var(--color-text-1);
          margin-right: 16px;
          font-weight: 500;
        }
      }
    }

    :deep(.file-content-wrap) {
      margin-top: -20px;
      padding: 20px;

      .file-content-title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        box-shadow: 0 1px 0 0 var(--color-border-2);
        &__line {
          display: inline-block;
          height: 12px;
          width: 3px;
          background-color: var(--color-priamry);
          margin-right: 6px;
        }

        &__title {
          font-size: 16px;
          color: var(--color-text-1);
          margin-right: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
