<template>
  <div>
    <div class="module-item__title">{{ $t('other.views.reportPdf.runScreenshot') }}</div>
    <div class="behaviorshot-wrap">
      <div
        v-for="(item, index) in behaviorscreenshot.shots"
        :key="index"
        class="behaviorshot-wrap__item"
      >
        <img :src="getImgSrc(item)" :alt="`${$t('other.views.reportPdf.screenShot')}${index}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BehaviorScreenshot',
  components: {},
  props: {
    behaviorscreenshot: {
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    getImgSrc(src) {
      return `${location.origin}${src}`
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.module-item__title {
  line-height: 50px;
  border-bottom: 1px solid var(--color-border-2);
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}
.behaviorshot-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  &__item {
    margin-bottom: 5px;
    width: calc((100% - 6pt) / 2);
    flex-shrink: 0;
    page-break-inside: avoid;
    img {
      width: 100%;
      object-fit: contain;
    }
    &:nth-child(2n+1) {
      margin-right: 6pt;
    }
  }
}
</style>
