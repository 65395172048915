import request from 'app/http'

// 获取规则列表
const getStaticList = () => {
  return request.get('/hunting/static/rules')
}

const getThreatList = () => {
  return request.get('/hunting/threat/rules')
}

// 获取对应规则的详情
const getStaticOverview = (id) => {
  return request.get('/hunting/static/rule/' + id)
}

const getThreatOverview = (id) => {
  return request.get('/hunting/threat/rule/' + id)
}

// 获取趋势图数据
const getStaticChart = (id, params) => {
  return request.get('/hunting/static/rule/' + id + '/chart', { params })
}

const getThreatChart = (id, params) => {
  return request.get('/hunting/threat/rule/' + id + '/chart', { params })
}

// 根据规则id获取对应报告列表
const getStaticTaskList = (id, params) => {
  return request.get(`/hunting/static/${id}/tasks`, { params })
}

const getThreatTaskList = (id, params) => {
  return request.get(`/hunting/threat/${id}/tasks`, { params })
}

// 添加规则
const addStaticRule = (params) => {
  return request.post('/hunting/static/rules', params)
}

const addThreatRule = (params) => {
  return request.post('/hunting/threat/rules', params)
}

// 更新规则
const updateStaticRule = (id, params) => {
  return request.patch('/hunting/static/rule/' + id, params)
}

const updateThreatRule = (id, params) => {
  return request.patch('/hunting/threat/rule/' + id, params)
}

// 删除规则
const deleteStaticRule = (id) => {
  return request.delete(`/hunting/static/rule/${id}`)
}

const deleteThreatRule = (id) => {
  return request.delete(`/hunting/threat/rule/${id}`)
}

// 操作确认或误报
const confirmStatic = (rule_id, task_id, params) => {
  return request({
    method: 'post',
    url: `/hunting/static/task/${rule_id}_${task_id}`,
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
}

const confirmThreat = (rule_id, task_id, params) => {
  // return request.post(`/hunting/threat/task/${rule_id}_${task_id}`, { params })
  return request({
    method: 'post',
    url: `/hunting/threat/task/${rule_id}_${task_id}`,
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
}

// 校验静态规则
const verifyStaticRule = (params) => {
  return request.post('/hunting/static/rule/verify', params)
}

const getFilterConfig = (id) => {
  return request.get('/hunting/filter/config')
}

const getStaticListFilter = (id) => {
  return request.get(`/hunting/static/rule/${id}/filter`)
}

const getThreatListFilter = (id) => {
  return request.get(`/hunting/threat/rule/${id}/filter`)
}

// 获取图片url
const getStaticImgUrl = (id) => {
  return request.get('/hunting/static/task/' + id)
}

const getThreatImgUrl = (id) => {
  return request.get('/hunting/threat/task/' + id)
}
export {
  getStaticList,
  getThreatList,
  getStaticOverview,
  getThreatOverview,
  getStaticChart,
  getThreatChart,
  getStaticTaskList,
  getThreatTaskList,
  addStaticRule,
  addThreatRule,
  updateStaticRule,
  updateThreatRule,
  deleteStaticRule,
  deleteThreatRule,
  confirmStatic,
  confirmThreat,
  verifyStaticRule,
  getFilterConfig,
  getStaticListFilter,
  getThreatListFilter,
  getStaticImgUrl,
  getThreatImgUrl
}
