<template>
  <div class="sand-newTable" v-if="tableData.length !== 0 && columnData.length !== 0">
    <div class="sand-newTable__table">
      <div class="sand-newTable__header" v-show="showHeader" ref="header">
        <div
          class="sand-newTable__header--item"
          v-for="item in columnData"
          :key="item.prop"
          :style="`
            width: ${item.width ? getWidth(item.width) : '0px'};
            flex-grow: ${item.width ? '0' : (item.col ? item.col : '1')};
            text-align: ${item.align ? item.align : 'left'};
          `">
          <span class="sand-newTable__header--text">
            <i v-if="item.icon"><img :src="getIcon(item.icon)"></i>
            <span>{{item.label}}</span>
          </span>
        </div>
      </div>
      <div class="sand-newTable__body" ref="body">
        <div class="sand-newTable__body--tr" v-for="(tr, index) in realTableData" :key="index">
          <!-- 如果是水平的话就是循环tr就可以了 -->
          <div
            class="sand-newTable__body--td"
            :class="{
              'sand-newTable__body--horizontalTd' : tableProps.isHorizontal
            }"
            v-for="(td, tdIndex) in tableProps.isHorizontal ? tr : columnData"
            :key="tdIndex"
            :style="`
              width: ${td.width ? getWidth(td.width) : '0px'};
              flex-grow: ${td.width ? '0' : (td.col ? td.col : '1')};
              text-align: ${td.align ? td.align : 'left'};
            `">
            <!-- 水平的数据在每一项的val里 -->
            <span
              v-if="td.type === 'border' && !td.isTitle"
              class="sand-newTable__body--border"
              :style="`
                border-color: ${tableProps.isHorizontal ? td.val.color : tr[td.prop].color};
                color: ${tableProps.isHorizontal ? td.val.color : tr[td.prop].color};
              `">{{tableProps.isHorizontal ? td.val.value : tr[td.prop].value}}</span>
            <!-- <span v-else class="sand-newTable__body--text" v-html="tableProps.isHorizontal ? td.val : tr[td.prop]"></span> -->
            <span v-else-if="td.html" class="sand-newTable__body--text" v-html="tableProps.isHorizontal ? td.val : tr[td.prop]"></span>
            <span v-else class="sand-newTable__body--text">{{tableProps.isHorizontal ? td.val : tr[td.prop]}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底下的展开和收起 -->
    <div
      ref="footer"
      v-if="tableProps.displayLines && tableProps.displayLines < tableData.length"
      class="sand-newTable__footer"
      :class="{
        'sand-newTable__footer--fixed': footerFixed && isOpen,
        'sand-newTable__footer--open': isOpen
        }"
      @click="switchDisplayStatus">
        <svg-icon class="sand-newTable__footer--triangle" name="zhankai"></svg-icon>
    </div>
  </div>
  <sand-no-data v-else></sand-no-data>
</template>

<script>
import sandNoData from './sandNoData'
export default {
  components: {
    sandNoData
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        // columnData: [{
        //   prop: 'date',
        //   label: '111',
        //   type: 'tag',
        //   titleWidth: '50px',
        //   // width: '100px',
        //   align: 'center',
        //   icon: 'flow'
        // }, {
        //   prop: 'name',
        //   label: '222222',
        //   titleWidth: '40px',
        //   align: 'left',
        //   icon: 'flow'
        //   // col: 2
        // }, {
        //   prop: 'address',
        //   label: '333',
        //   type: 'border',
        //   titleWidth: '50px',
        //   align: 'right',
        //   icon: 'flow'
        // }],
        // tableData: [{
        //   date: '2016-05-02',
        //   name: '<span style="font-weight: 800;">dddd</span>\n<span>dddd</span>',
        //   address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //   date: '2',
        //   name: 'dfad\nfas',
        //   address: 0
        // }],
        // tableProps: {
        //   // 是否横向
        //   isHorizontal: true,
        //   // 是否显示头
        //   // showHeader: true,
        //   // 显示行数,有的话会有展开和收起的按键
        //   displayLines: 1
        // }
      })
    }
  },
  data () {
    return {
      isOpen: false,
      footerFixed: false
    }
  },
  watch: {
    reportState () {
      this.setReportStatic()
    }
  },
  mounted () {
    this.setReportStatic()
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.handleScroll, true)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  computed: {
    reportState () {
      return this.$store.getters.getReportState
    },
    columnData () {
      return this.data.columnData || []
    },
    tableProps () {
      return this.data.tableProps || []
    },
    showHeader () {
      if (this.tableData.length === 0 || this.columnData.length === 0) {
        return false
      }
      if (this.tableProps.isHorizontal) {
        return false
      }
      if (this.tableProps.showHeader === undefined) {
        return true
      }
      return this.tableProps.showHeader
    },
    tableData () {
      if (this.data.tableData) {
        if (this.tableProps.isHorizontal) {
          const columnTableData = []
          // 把数据和结构合并一下,变成一行中表头和内容间隔的数据格式
          // 在渲染的时候就只需要循环这个数组就可以渲染整个数据了
          // 因为采用普通的两个数组循环就得一次循环得到一个表头一个内容
          // 然后采用flex布局的话一个表头一个内容在一个循环的框里,布局会有问题
          this.data.tableData.forEach((item, index) => {
            columnTableData.push([])
            this.columnData.forEach(column => {
              // 如果是表头的话就会标识一下,如果内容为空会给他一个空,防止undefined后面调用的时候报错
              columnTableData[index].push(
                {
                  ...column,
                  isTitle: true,
                  width: column.titleWidth,
                  col: column.titleCol,
                  align: column.titleAlign || column.align,
                  val: column.label
                },
                {
                  ...column,
                  val: item[column.prop] === undefined ? '' : item[column.prop]
                }
              )
            })
          })
          return columnTableData
        }
        return this.data.tableData
      }
      return []
    },
    // 真实展示的表格数据,因为需要存在折叠的情况,所以数据不会直接展示
    realTableData () {
      if (this.tableProps.displayLines && !this.isOpen) {
        return this.tableData.slice(0, this.tableProps.displayLines)
      }
      return this.tableData
    }
  },
  methods: {
    getIcon (name) {
      return require(`@/assets/img/svg/${name}.svg`)
    },
    setReportStatic () {
      if (this.reportState) {
        this.open()
      }
    },
    open () {
      this.isOpen = true
    },
    getWidth (data) {
      if (data.indexOf('px') > 0) {
        return data
      }
      return data + 'px'
    },
    switchDisplayStatus () {
      this.isOpen = !this.isOpen
      this.$nextTick(this.handleScroll)
    },
    handleScroll () {
      const body = this.$refs.body
      const footer = this.$refs.footer
      // 视口高度
      const clientHeight = document.documentElement.clientHeight
      // body到视口区域的top
      if (!footer || !body || !this.isOpen) {
        return
      }
      const bodyCLientTop = body.getBoundingClientRect().top
      const bodyCLientLeft = body.getBoundingClientRect().left
      // 视口的底部一定要在内容上面
      // 内容上边到视口的距离小于视口  &&  内容的底部到视口的距离大于视口
      // 内容都要加上底部的高度
      if (clientHeight >= bodyCLientTop + footer.offsetHeight && clientHeight <= bodyCLientTop + body.offsetHeight + footer.offsetHeight) {
        footer.style.bottom = '25px'
        footer.style.left = `${bodyCLientLeft}px`
        this.footerFixed = true
        footer.style.width = `${body.offsetWidth + 2}px`
      } else if (clientHeight >= bodyCLientTop && clientHeight < bodyCLientTop + footer.offsetHeight) {
        footer.style.bottom = `${clientHeight - bodyCLientTop - footer.offsetHeight}px`
        footer.style.left = `${bodyCLientLeft}px`
        this.footerFixed = true
        footer.style.width = `${body.offsetWidth + 2}px`
      } else {
        this.footerFixed = false
        footer.style.width = '100%'
      }
    }
  }
}
</script>

<style lang="scss">
.apk-table {
  margin-top: 10px;
  .sand-newTable__body--tr {
    background-color: var(--color-bg-3);
  }
}
.sand-newTable {
  &__table {
    // @include bd_color(color-sand-border);
    // border: 1px solid;
    color:var(--color-text-2);
  }
  // todo
  &__header {
    width: 100%;
    display: flex;
    min-height: 40px;
    // border-bottom: 1px solid var(--color-border-2);
    &--item {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      background-color: var(--color-bg-3);
      // @include bd_color(color-sand-border);
      // border: 1px solid;
      border-right: none;
      border-top: none;
      &:first-child  {
        border-left: none;
      }
    }
    &--text {
      width: 100%;
      white-space: pre-wrap;
      word-break: break-all;
      line-height: 14px;
      font-size: 14px;
      padding: 10px 15px;
      font-weight: 500;
      & > i {
        vertical-align: middle;
        margin-right: 8px;
      }
      & > span {
        vertical-align: middle;
      }
    }
  }
  &__body {
    // width:calc(100vw - 706px);
    // overflow-x: scroll;
    &--tr {
      display: flex;
      min-height: 48px;
      background: transparent;
      border-top: 1px solid var(--color-border-2);
      box-sizing: border-box;
      overflow-x: auto;
      overflow-x: overlay;
      // &::-webkit-scrollbar{height:0px;}
      &:last-child {
        border-bottom: 1px solid var(--color-border-2);
      }
      // todo
      &:first-child {
        // border-top: 0;
      }
    }
    &--td {
      flex-shrink: 0;
      display: grid;
      align-items: center;
      // border: 1px solid var(--color-border-2);
      color: var(--color-text-2);
      &:first-child {
        border-left: none;
      }
    }
    &--horizontalTd {
      min-width: 100px;
      max-height: 200px;
      overflow-y: auto;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 10;
      // overflow: hidden;
      &:nth-child(odd) {
        background: var(--color-bg-3);
        border-left: 1px solid var(--color-border-2);
        border-right: 1px solid var(--color-border-2);
        color: var(--color-text-2);
        font-weight: 400;
      }
      &:last-child{
        border-right: 1px solid var(--color-border-2);
      }
    }
    &--text {
      width: 100%;
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 14px;
      padding: 10px 15px;
      box-sizing: border-box;
      line-height: 1.3;
      font-weight: 400;
    }
    &--border {
      word-break: break-all;
      word-wrap: break-word;
      font-size: 14px;
      // @include bd_color(color-sand-border);
      border: 1px solid;
      border-radius: 4px;
      // @include ft_color(color-main-font);
      display: inline-block;
      padding: 2px 10px;
      margin: 0 20px;
      font-weight: 400;
      text-align: center;
    }
  }
  &__footer {
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px dashed var(--color-text-3);
    color: var(--color-text-3);
    cursor: pointer;
    svg {
      font-size: 20px;
    }
    &:hover {
      // @include ft_color(color-main-white, false);
      // @include bg_color(color-collapse-header-bg-hover, false);
      & .sand-newTable__footer--triangle {
        filter: var(--svg-filter2);
      }
    }
    &--fixed {
      position: fixed;
      bottom: 0;
    }
    &--triangle {
      margin-left: 8px;
      transition: transform 0.3s;
      vertical-align: middle;
    }
    &--open {
      & .sand-newTable__footer--triangle {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
