<template>
  <div>
    <Loading v-if="loading" :loading="loading" />
    <div style="padding:20px;" v-else>
      <!-- 网络行为 -->
      <NetworkAnalyse id="NetworkAnalyse" :taskId="taskId" v-if="networkCount" :networkCount="networkCount" type="network" />
    </div>
    <detail-footer/>
  </div>
</template>

<script>
import { getTaskReportNetwork } from 'services/report'
import NetworkAnalyse from '../NetworkAnalyse'
import { isZh } from 'app/i18n'
export default {
  name: 'network',
  components: {
    NetworkAnalyse
  },
  data () {
    return {
      networkCount: {},
      loading: true,
      networkShowList: {}
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  methods: {
    async init () {
      await getTaskReportNetwork(this.taskId, { total: true, lang: isZh() ? 'zh' : 'en' }).then(res => {
        this.networkCount = res.network
      })
      this.loading = false
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="stylus" scoped>
</style>
